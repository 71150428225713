import React, {
  useImperativeHandle,
  useCallback,
  useState,
  useEffect,
} from 'react'
import { compose, withFormik, withHooks } from '@enhancers'
import { Field, Select, SearchInput, DatePicker } from '@components'
import { isEqual } from 'lodash'
import { withState } from 'recompose'

const Filter = (props) => (
  <form onSubmit={props.callTriggerFilter} className="form form-label-right ">
    <div className="form-group row mb-3">
      {props.filters.map((filter, index) => (
        <div
          key={filter.dataField}
          className={`mb-3 col-lg-${filter.size || 3} col-md-${
            filter.size || 3
          } ${index === 0 ? '' : 'pl-lg-0 pl-md-0'} ${filter.className}`}>
          {filter.type === 'select' && (
            <Field
              label={filter.label}
              component={Select}
              name={filter.dataField}
              options={filter.options || []}
              onChange={props.callTriggerFilter}
              placeholder={filter.placeholder}
              helperText={filter.description}
              multiple={filter.multiple}
              selectProps={filter.selectProps}
            />
          )}
          {filter.type === 'search' && (
            <Field
              label={filter.label}
              component={SearchInput}
              name={filter.dataField}
              onSearch={props.callTriggerFilter}
              searchDelay={filter.delay}
              placeholder={filter.placeholder}
              helperText={filter.description}
            />
          )}
          {filter.type === 'number' && (
            <Field
              label={filter.label}
              component={SearchInput}
              name={filter.dataField}
              onSearch={props.callTriggerFilter}
              searchDelay={filter.delay}
              placeholder={filter.placeholder}
              helperText={filter.description}
              type="number"
            />
          )}
          {filter.type === 'date' && (
            <Field
              label={filter.label}
              component={DatePicker}
              name={filter.dataField}
              onChange={props.callTriggerFilter}
              placeholder={filter.placeholder}
              helperText={filter.description}
            />
          )}
        </div>
      ))}
    </div>
  </form>
)

const enhancer = compose(
  withState('initialValues', 'setInitialValues', {}),
  withFormik({
    mapPropsToValues: (props) => props.initialValues,
  }),
  withHooks((props) => {
    const { $ref, filters, values, setInitialValues, onFilter } = props

    const [triggerFilter, setTriggerFilter] = useState(false)

    const handleSubmit = useCallback(() => {
      if (onFilter) {
        onFilter(values)
      }
    }, [values, onFilter])

    useImperativeHandle($ref, () => ({
      setFilter: (filters) => {
        filters = { ...values, ...filters }
        if (!isEqual(values, filters)) {
          setInitialValues(filters, handleSubmit)
        }
      },
    }))

    useEffect(() => {
      setTriggerFilter(false)
      if (triggerFilter) {
        handleSubmit()
      }
    }, [triggerFilter, handleSubmit, setTriggerFilter])

    const callTriggerFilter = useCallback(() => {
      setTriggerFilter(true)
    }, [setTriggerFilter])

    return {
      filters,
      callTriggerFilter,
    }
  }),
)

export default enhancer(Filter)
