/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import PanoramaIcon from '@material-ui/icons/Panorama'
import ImageLightbox from '../../ImageLightbox'

const avatarStyle = {
  width: 30,
  height: 30,
  cursor: 'pointer',
}

const ImageColumnFormatter = (cellContent) => (
  <Avatar
    variant="square"
    src={cellContent}
    style={avatarStyle}
    className="mx-auto"
    onClick={() => {
      cellContent && ImageLightbox.open({ image: cellContent })
    }}>
    <PanoramaIcon />
  </Avatar>
)

export default ImageColumnFormatter
