import { safe } from '@common/helper'
import { getIn } from 'formik'
import { useCallback } from 'react'

const useFormikField = (
  { form, field, name, value = null, error, onChange, onBlur, ...rest },
  { getOnChangeValue } = {},
) => {
  const isFormik = form && field

  if (isFormik) {
    const touched = getIn(form.touched, field.name)
    error = getIn(form.errors, field.name)
    name = field.name
    value = field.value
    error = touched && error
  }

  const setFieldValue = form && form.setFieldValue
  const changeWithFormik = useCallback(
    (value) => {
      setFieldValue && setFieldValue(name, value)
    },
    [setFieldValue, name],
  )

  const customOnChange = useCallback(
    (e, ...args) => {
      const newValue = safe(() => {
        if (getOnChangeValue) {
          return getOnChangeValue(e, ...args)
        } else {
          return e.target.value
        }
      }, e)

      changeWithFormik && changeWithFormik(newValue)
      onChange && onChange(newValue)
    },
    [changeWithFormik, onChange, getOnChangeValue],
  )

  const blurWithFormik = field && field.onBlur
  const customOnBlur = useCallback(
    (e) => {
      blurWithFormik && blurWithFormik(e)
      onBlur && onBlur(value)
    },
    [blurWithFormik, onBlur, value],
  )

  return {
    ...rest,
    name,
    value,
    error,
    onChange: customOnChange,
    onBlur: customOnBlur,
  }
}

export default useFormikField
