import ActiveStore from './ActiveStore'

class AttendeeStore extends ActiveStore {
  constructor() {
    super('attendee')
  }

  addSocialCoin = async (id, params, options) => {
    await this.server.post(`/${id}/add_social_coin`, params, options)
  }
}

export default new AttendeeStore()
