import React from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_helpers'

export function Brand() {
  return (
    <>
      <div className="aside-brand d-flex flex-column align-items-center flex-column-auto py-5 pt-lg-12 pb-lg-6">
        <Link to="" className="brand-logo">
          <img
            alt="logo"
            src={toAbsoluteUrl('/media/ewc/ewc-logo.png')}
            className="max-h-50px"
            style={{ borderRadius: '50%' }}
          />
        </Link>
      </div>
    </>
  )
}
