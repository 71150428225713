import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import { compose, withHooks } from '@enhancers'
import { sortCaret } from '_metronic/_helpers'
import { isEqual, isFunction } from 'lodash'
import { call } from '@common/helper'
import classnames from 'classnames'
import humps from 'humps'
import { v4 as uuidv4 } from 'uuid'

import {
  ActionsColumnFormatter,
  DateColumnFormatter,
  DateTimeColumnFormatter,
  ImageColumnFormatter,
  LinkColumnFormatter,
} from './column-formatters/index'

import Filter from './Filter'
import Grouping from './Grouping'
import Table from './Table'
import AvatarColumnFormatter from './column-formatters/AvatarColumnFormatter'

const CustomTable = (props) => (
  <>
    {props.filters && (
      <Filter
        $ref={props.$filter}
        filters={props.filters}
        onFilter={props.setFilterParams}
      />
    )}
    {props.groupActions && (
      <Grouping
        selectedItems={props.selectedItems}
        actions={props.groupActions}
        setSelectedIds={props.setSelectedIds}
      />
    )}
    <Table
      key={props.tableKey}
      setQueryParams={props.setQueryParams}
      selectedIds={props.selectedIds}
      setSelectedIds={props.setSelectedIds}
      loading={props.loading}
      columns={props.columns}
      entities={props.entities}
      page={props.page}
      sizePerPage={props.sizePerPage}
      sizePerPageList={props.sizePerPageList}
      totalSize={props.totalSize}
      defaultSorted={props.defaultSorted}
      selectable={props.selectable}
    />
  </>
)

const DEFAULT_SORTED = [{ dataField: 'created_at', order: 'desc' }]
const DEFAULT_PAGING = {
  page: 1,
  perPage: 25,
  totalCount: 0,
  sizePerPageList: [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ],
}

const enhancer = compose(
  withHooks(
    ({
      filters,
      groupActions,
      columns,
      entities,
      paging = {},
      onQuery,
      selectable,
      defaultSorted = DEFAULT_SORTED,
      $ref,
    }) => {
      const page            = paging.page            || DEFAULT_PAGING.page // prettier-ignore
      const sizePerPage     = paging.perPage         || DEFAULT_PAGING.perPage // prettier-ignore
      const sizePerPageList = paging.sizePerPageList || DEFAULT_PAGING.sizePerPageList // prettier-ignore
      const totalSize       = paging.totalCount      || DEFAULT_PAGING.totalCount // prettier-ignore

      const [selectedIds, setSelectedIds] = useState([])
      const [loading, setLoading] = useState(false)
      const [queryParams, setQueryParamsBase] = useState({
        filter: {},
        sortField: defaultSorted[0].dataField,
        sortOrder: defaultSorted[0].order,
        pageNumber: page,
        pageSize: sizePerPage,
      })

      const setQueryParams = useCallback(
        (nextQueryParams) => {
          setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
              nextQueryParams = nextQueryParams(prevQueryParams)
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
              return prevQueryParams
            }

            return nextQueryParams
          })
        },
        [setQueryParamsBase],
      )

      const setFilterParams = useCallback(
        (nextFilterParams) => {
          setQueryParamsBase((prevQueryParams) => {
            if (isEqual(prevQueryParams.filter, nextFilterParams)) {
              return prevQueryParams
            }

            return { ...prevQueryParams, filter: nextFilterParams }
          })
        },
        [setQueryParamsBase],
      )

      useEffect(() => {
        if (onQuery) {
          call(async () => {
            setLoading(true)
            await onQuery({
              ...queryParams.filter,
              sortBy: humps.decamelize(queryParams.sortField),
              orderBy: queryParams.sortOrder,
              page: queryParams.pageNumber,
              perPage: queryParams.pageSize,
            })
            setLoading(false)
          })
        }
      }, [onQuery, queryParams])

      const customColumns = useMemo(() => {
        return columns.map((column) => {
          const temp = {
            dataField: column.dataField,
            text: column.title,
            sort: column.sort,
          }

          if (temp.sort) {
            temp.sortCaret = sortCaret
          }

          switch (column.type) {
            case 'actions':
              temp.dataField = column.dataField || 'action'
              temp.formatter = ActionsColumnFormatter
              temp.formatExtraData = { actions: column.actions }
              temp.fit = true
              temp.classes = 'text-nowrap'
              break
            case 'date':
              temp.formatter = DateColumnFormatter
              break
            case 'datetime':
              temp.formatter = DateTimeColumnFormatter
              break
            case 'image':
              temp.formatter = ImageColumnFormatter
              temp.classes = 'p-0'
              break
            case 'avatar':
              temp.formatter = AvatarColumnFormatter
              temp.classes = 'p-0'
              temp.formatExtraData = {
                nameKey: column.nameKey || 'name',
                onClick: column.onClick,
              }
              break
            case 'link':
              temp.formatter = LinkColumnFormatter
              temp.formatExtraData = { onClick: column.onClick }
              break
            default:
              break
          }

          temp.classes = classnames(
            temp.classes,
            `text-${column.align || temp.align || 'center'}`,
            column.className,
            { fit: column.fit || temp.fit },
          )
          temp.headerClasses = classnames(
            temp.headerClasses,
            `text-${
              column.headerAlign || column.align || temp.align || 'center'
            }`,
            column.headerClassName,
            { fit: column.fit || temp.fit },
          )

          if (column.hide) {
            temp.classes = 'd-none'
            temp.headerClasses = 'd-none'
          }
          return temp
        })
      }, [columns])

      const selectedItems = useMemo(() => {
        return selectedIds.map((selectedId) =>
          entities.find((entity) => entity.id === selectedId),
        )
      }, [selectedIds, entities])

      const $filter = useRef()
      const [tableKey, setTableKey] = useState(uuidv4())

      useImperativeHandle($ref, () => ({
        reload: async () => {
          setLoading(true)
          await onQuery({
            ...queryParams.filter,
            sortBy: humps.decamelize(queryParams.sortField),
            orderBy: queryParams.sortOrder,
            page: queryParams.pageNumber,
            perPage: queryParams.pageSize,
          })
          setLoading(false)
        },
        getQueryParams: () => {
          return {
            ...queryParams.filter,
            sortBy: humps.decamelize(queryParams.sortField),
            orderBy: queryParams.sortOrder,
            page: queryParams.pageNumber,
            perPage: queryParams.pageSize,
          }
        },
        setFilter: (filter) => {
          $filter.current.setFilter(filter)
        },
        clearSelectedIds: () => {
          setSelectedIds([])
          // NOTE: Hot Fix For Selected Ids Bug.
          setTableKey(uuidv4())
        },
      }))

      return {
        $filter,
        tableKey,
        filters,
        groupActions,
        setFilterParams,
        setQueryParams,
        setSelectedIds,
        selectedIds,
        selectedItems,
        loading,
        columns: customColumns,
        entities,
        page,
        sizePerPage,
        sizePerPageList,
        totalSize,
        defaultSorted,
        selectable,
      }
    },
  ),
)

export default enhancer(CustomTable)
