/* eslint-disable */
// For more infos, see: https://github.com/jquense/yup/blob/master/src/locale.js
// import printValue from './node_modules/yup/util/printValue.js'
import { setLocale } from 'yup'

var toString = Object.prototype.toString
var errorToString = Error.prototype.toString
var regExpToString = RegExp.prototype.toString
var symbolToString =
  typeof Symbol !== 'undefined'
    ? Symbol.prototype.toString
    : function () {
        return ''
      }
var SYMBOL_REGEXP = /^Symbol\((.*)\)(.*)$/

function printNumber(val) {
  if (val != +val) return 'NaN'
  var isNegativeZero = val === 0 && 1 / val < 0
  return isNegativeZero ? '-0' : '' + val
}

function printSimpleValue(val, quoteStrings) {
  if (quoteStrings === void 0) {
    quoteStrings = false
  }

  if (val == null || val === true || val === false) return '' + val
  var typeOf = typeof val
  if (typeOf === 'number') return printNumber(val)
  if (typeOf === 'string') return quoteStrings ? '"' + val + '"' : val
  if (typeOf === 'function')
    return '[Function ' + (val.name || 'anonymous') + ']'
  if (typeOf === 'symbol')
    return symbolToString.call(val).replace(SYMBOL_REGEXP, 'Symbol($1)')
  var tag = toString.call(val).slice(8, -1)
  if (tag === 'Date')
    return isNaN(val.getTime()) ? '' + val : val.toISOString(val)
  if (tag === 'Error' || val instanceof Error)
    return '[' + errorToString.call(val) + ']'
  if (tag === 'RegExp') return regExpToString.call(val)
  return null
}

function printValue(value, quoteStrings) {
  var result = printSimpleValue(value, quoteStrings)
  if (result !== null) return result
  return JSON.stringify(
    value,
    function (key, value) {
      var result = printSimpleValue(this[key], quoteStrings)
      if (result !== null) return result
      return value
    },
    2,
  )
}

const translation = {
  mixed: {
    default: '${path} ไม่ถูกต้อง',
    required: '${path} ต้องไม่เว้นว่าง',
    oneOf: '${path} must be one of the following values: ${values}',
    notOneOf: '${path} must not be one of the following values: ${values}',
    notType: ({ path, type, value, originalValue }) => {
      let isCast = originalValue != null && originalValue !== value
      let msg =
        `${path} must be a \`${type}\` type, ` +
        `but the final value was: \`${printValue(value, true)}\`` +
        (isCast
          ? ` (cast from the value \`${printValue(originalValue, true)}\`).`
          : '.')

      if (value === null) {
        msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``
      }

      return msg
    },
    defined: '${path} must be defined',
  },
  string: {
    length: '${path} must be exactly ${length} characters',
    min: '${path} ต้องไม่น้อยกว่า ${min} ตัวอักษร',
    max: '${path} ต้องไม่มากกว่า ${max} ตัวอักษร',
    matches: '${path} must match the following: "${regex}"',
    email: '${path} ต้องเป็นรูปแบบอีเมลที่ถูกต้อง',
    url: '${path} must be a valid URL',
    uuid: '${path} must be a valid UUID',
    trim: '${path} must be a trimmed string',
    lowercase: '${path} ต้องเป็นตัวเล็กทั้งหมด',
    uppercase: '${path} ต้องเป็นตัวใหญ่ทั้งหมด',
  },
  number: {
    min: '${path} must be greater than or equal to ${min}',
    max: '${path} must be less than or equal to ${max}',
    lessThan: '${path} must be less than ${less}',
    moreThan: '${path} must be greater than ${more}',
    positive: '${path} must be a positive number',
    negative: '${path} must be a negative number',
    integer: '${path} must be an integer',
  },
  date: {
    min: '${path} field must be later than ${min}',
    max: '${path} field must be at earlier than ${max}',
  },
  object: {
    isValue: '${path} field must be ${value}',
  },
  array: {
    noUnknown: '${path} field has unspecified keys: ${unknown}',
  },
  boolean: {
    min: '${path} field must have at least ${min} items',
    max: '${path} field must have less than or equal to ${max} items',
    length: '${path} must be have ${length} items',
  },
}

setLocale(translation)
