import React, { useCallback, useEffect, useState } from 'react'
import { compose, withHooks } from '@enhancers'
import Avatar from '@material-ui/core/Avatar'
import { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { useFormikField } from '@hooks'
import styled from '@styled'
import Icon from './Icon'
const Layout = styled.div`
  cursor: pointer;
  :hover {
    .hover-icon {
      opacity: 2;
    }
  }
  :focus,
  .MuiAvatar-root:focus {
    outline: none;
  }
  .MuiAvatar-root {
    background-color: #b5b5c3;
  }

  ${(props) => props.readonly && `pointer-events: none;`}
`

const HoverLayout = styled.div`
  opacity: 0;
  background-color: #55555555;
  transition: all 0.4s;

  i {
    color: white;
  }
`

const CustomAvatar = ({
  readonly,
  getRootProps,
  getInputProps,
  onClick,
  PlaceholderIcon,
  ...avatarProps
}) => (
  <Layout
    className="position-relative"
    onClick={onClick}
    {...(readonly ? undefined : getRootProps())}>
    {readonly ? null : <input autoComplete="off" {...getInputProps()} />}
    <Avatar {...avatarProps}>{PlaceholderIcon && <PlaceholderIcon />}</Avatar>
    {readonly ? null : (
      <HoverLayout
        className={`${
          avatarProps.variant === 'rounded' ? 'rounded' : 'rounded-circle'
        } hover-icon position-absolute top-0 bottom-0 left-0 right-0 d-flex align-items-center justify-content-enter`}>
        <Icon className="mx-auto" name="image" size="20" />
      </HoverLayout>
    )}
  </Layout>
)

const DEFAULT_PROPS = {
  size: 100,
}

const SIZE_RATIO = {
  '16:9': 16 / 9,
}

const enhancer = compose(
  withHooks((props) => {
    const {
      size,
      ratio,
      onChange,
      src,
      error,
      helperText,
      readonly,
      onClick,
      PlaceholderIcon,
      disabled,
      ...rest
    } = useFormikField({
      ...DEFAULT_PROPS,
      ...props,
    })

    const style = useMemo(() => {
      const width = size
      const height = width / (SIZE_RATIO[ratio] || 1)

      return {
        width,
        height,
        minWidth: width,
        minHeight: height,
      }
    }, [size, ratio])

    const [loading, setLoading] = useState(false)
    const [browsedSrc, setBrowsedSrc] = useState(null)

    const onDrop = useCallback(
      async (files) => {
        files.forEach((file) => {
          file.preview = URL.createObjectURL(file)
        })
        const file = files[0]
        setBrowsedSrc(file.preview)
        if (onChange) {
          setLoading(true)
          await onChange(file)
          setLoading(false)
        }
      },
      [setBrowsedSrc, onChange],
    )

    const { getRootProps, getInputProps } = useDropzone({
      accept: 'image/*',
      multiple: false,
      onDrop,
    })

    const isEmptySrc = !props.field?.value
    useEffect(() => {
      if (isEmptySrc) {
        setBrowsedSrc(null)
      }
    }, [isEmptySrc, setBrowsedSrc])

    const customSrc = browsedSrc || src
    const customReadonly = disabled !== undefined ? disabled : readonly

    return {
      readonly: customReadonly,
      getRootProps,
      getInputProps,
      loading: undefined && loading,
      style,
      src: customSrc,
      onClick,
      PlaceholderIcon,
      ...rest,
    }
  }),
)

export default enhancer(CustomAvatar)
