import { BaseStore } from './index'
import server from '@common/api'
import { filter, first } from 'lodash'
import { singular, plural } from 'pluralize'
import { camelize, decamelize } from 'humps'

class ActiveStore extends BaseStore {
  constructor(name, configs = {}) {
    const { initialValues } = configs

    const resourceName = camelize(singular(name))
    const resourcesName = camelize(plural(name))
    const storeName = camelize(`${resourceName}_store`)
    const currentResourceName = camelize(`current_${resourceName}`)

    super(storeName, {
      configs: {},
      paging: { page: 1, perPage: 25, totalCount: 25 },
      [resourcesName]: [],
      [currentResourceName]: null,
      ...initialValues,
    })

    const apiResourceName = decamelize(plural(name))
    const makeApi = (methodName) => async (path, ...args) => {
      return await server[methodName](`/${apiResourceName}${path}`, ...args)
    }
    this.server = {
      get: makeApi('get'),
      post: makeApi('post'),
      put: makeApi('put'),
      patch: makeApi('patch'),
      delete: makeApi('delete'),
      download: makeApi('download'),
    }

    const fetchConfigs = camelize(`fetch_configs`)
    this[fetchConfigs] = async () => {
      const response = await this.server.get('/configs')
      const { configs = {} } = response.data.data
      this.setState({ configs })
    }

    const createResource = camelize(`create_${resourceName}`)
    this[createResource] = async (params) => {
      const response = await this.server.post('', params)
      return response
    }

    const fetchResources = camelize(`fetch_${resourcesName}`)
    this[fetchResources] = async (params, options = {}) => {
      const response = await this.server.get('', { perPage: 25, ...params })
      const resources = response.data.data[resourcesName]
      const paging = response.data.data.paging

      if (!options.preventSetState) {
        this.setState({
          [resourcesName]: resources,
          paging,
        })
      }

      return resources
    }

    const findResource = camelize(`find_${resourceName}`)
    this[findResource] = async (id) => {
      const resources = await this[fetchResources]({ ids: [id] })
      this.setState({ [currentResourceName]: first(resources) })
    }

    const updateResource = camelize(`update_${resourceName}`)
    this[updateResource] = async (id, params) => {
      const response = await this.server.put(`/${id}`, params)
      const data = response.data.data
      const resource = data[this.resourceName]
      this.setState({ [resourceName]: resource })
    }

    const deleteAllResource = camelize(`delete_all_${resourcesName}`)
    this[deleteAllResource] = async (ids) => {
      await this.server.delete('', { ids })
      this.setState({
        [resourcesName]: filter(
          this.state[resourcesName],
          ({ id }) => !ids.includes(id),
        ),
      })
    }

    const deleteResource = camelize(`delete_${resourceName}`)
    this[deleteResource] = async (id) => {
      await this[deleteAllResource]([id])
    }

    const clearResources = camelize(`clear_${resourcesName}`)
    this[clearResources] = () => {
      this.setState({ [resourcesName]: [] })
    }

    const clearCurrentResource = camelize(`clear_current_${resourceName}`)
    this[clearCurrentResource] = () => {
      this.setState({ [currentResourceName]: null })
    }
  }
}

export default ActiveStore
