import React, { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Field, PageLayout, Input, Notification } from '@components'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import * as yup from 'yup'
import { get } from 'lodash'

export const AttendeeAddSocialCoinPage = props => (
  <PageLayout
    title={props.title}
    actions={[
      {
        label: 'กลับ',
        icon: 'arrow-left',
        onClick: props.onBack,
      },
      {
        label: 'ล้างค่าที่กรอก',
        icon: 'redo',
        onClick: props.onReset,
      },
      {
        label: 'บันทึก',
        icon: 'save',
        color: 'primary',
        onClick: props.onSave,
        type: 'submit',
      },
    ]}>
    <Grid container spacing={3}>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="จำนวนเหรียญ"
          name="amount"
          component={Input}
          placeholder="จำนวนเหรียญ"
          required
          type="number"
          helperText="หากต้องการลดเหรียญให้ใส่ค่าลบ"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="คำอธิบาย"
          name="description"
          component={Input}
          placeholder="คำอธิบาย"
          required
          helperText="โปรดใส่คำอธิบายเฉพาะ"
        />
      </Grid>
    </Grid>
  </PageLayout>
)

const enhancer = compose(
  withStores(stores => ({
    findAttendee: stores.attendeeStore.findAttendee,
    currentAttendee: stores.attendeeStore.currentAttendee,
    addSocialCoin: stores.attendeeStore.addSocialCoin,
  })),
  withFormik({
    validationSchema: yup.object().shape({
      amount: yup.string().required(),
    }),
    handleSubmit: async (values, formikBag) => {
      const { attendeeId, amount, description } = values
      await formikBag.props.addSocialCoin(attendeeId, { amount, description })
      Notification.success()
      paths
        .currentCompanyPath()
        .attendeesPath()
        .push()
    },
  }),
  withHooks(props => {
    const {
      handleReset,
      handleSubmit,
      setValues,
      findAttendee,
      currentAttendee,
    } = props
    const { attendeeId } = useParams()

    useEffect(() => {
      if (attendeeId) {
        findAttendee(attendeeId)
        setValues({ attendeeId })
      }
    }, [attendeeId, setValues, findAttendee])

    const onBack = useCallback(() => {
      paths
        .currentCompanyPath()
        .attendeesPath()
        .push()
    }, [])
    const AttendeeName = get(currentAttendee, 'fullName', '')
    const title = useMemo(() => {
      return `จัดการสะกิดคอยน์ '${AttendeeName}'`
    }, [AttendeeName])

    return {
      onBack,
      onReset: handleReset,
      onSave: handleSubmit,
      title,
    }
  }),
)

export default enhancer(AttendeeAddSocialCoinPage)
