import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  PageLayout,
  Table,
  ExcelGenerator,
  Modal,
  Notification,
} from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import { clone, filter, get, isEqual } from 'lodash'
import { cleanFilter } from '@common/helper'
import { css } from '@styled'
import { format } from 'date-fns'
import AddSocialCoinModal from './AddSocialCoinModal'

const columnClass = css`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AttendeeIndexPage = (props) => (
  <PageLayout
    title="ผู้แข่งขัน"
    actions={[
      {
        label: 'ออกรายงาน',
        icon: 'download',
        color: 'primary',
        onClick: props.onExport,
      },
    ]}>
    <Table
      $ref={props.$table}
      filters={[
        {
          description: 'กรองด้วยฤดูกาลแข่ง',
          dataField: 'seasonId',
          type: 'select',
          options: props.seasonOptions,
        },
        {
          description: 'กรองด้วยรอบแข่งที่',
          dataField: 'cycleId',
          type: 'select',
          options: props.cycleOptions,
        },
        {
          description: 'กรองด้วยทีม',
          dataField: 'teamId',
          type: 'select',
          options: props.teamOptions,
        },
        {
          description: 'กรองด้วยรหัส',
          dataField: 'code',
          type: 'search',
        },
        {
          description: 'กรองด้วยชื่อ',
          dataField: 'name',
          type: 'search',
          className: 'pl-lg-4 pl-md-4',
        },
        {
          description: 'กรองด้วยสะกิดคอยน์มากกว่าหรือเท่ากับ',
          dataField: 'social_coin_min',
          type: 'number',
        },
        {
          description: 'กรองด้วยสะกิดคอยน์น้อยกว่าหรือเท่ากับ',
          dataField: 'social_coin_max',
          type: 'number',
        },
        {
          description: 'กรองด้วยสถานะ',
          dataField: 'state',
          type: 'select',
          options: props.stateOptions,
        },
      ]}
      groupActions={[
        {
          label: 'จัดการสะกิดคอยน์',
          icon: 'coins',
          hoverColor: 'primary',
          onClick: props.onAddSocialCoinList,
        },
      ]}
      selectable
      defaultSorted={[{ dataField: 'code', order: 'asc' }]}
      columns={[
        {
          title: 'รหัส',
          dataField: 'code',
          align: 'left',
          sort: true,
        },
        {
          title: 'ชื่อ-นามสกุล',
          dataField: 'fullName',
          align: 'left',
          sort: true,
          className: columnClass,
        },
        {
          title: 'ฤดูกาลแข่ง',
          dataField: 'season',
          align: 'left',
        },
        {
          title: 'รอบการแข่ง',
          dataField: 'cycle',
          sort: true,
        },
        {
          title: 'ทีม',
          dataField: 'team',
          sort: true,
        },
        {
          title: 'สถานะ',
          dataField: 'state',
        },
        {
          title: 'ระดับน้องสะกิด',
          dataField: 'treeLevel',
          sort: true,
        },
        {
          title: 'Health Score',
          dataField: 'healthScore',
          sort: true,
        },
        {
          title: 'สะกิดคอยน์',
          dataField: 'socialCoin',
          sort: true,
        },
        {
          title: 'เข้าร่วมเมื่อ',
          dataField: 'joinAt',
          sort: true,
          type: 'date',
        },
        {
          title: 'การดำเนินการ',
          type: 'actions',
          actions: [
            {
              label: 'จัดการสะกิดคอยน์',
              icon: 'coins',
              hoverColor: 'primary',
              onClick: props.onAddSocialCoin,
            },
          ],
        },
      ]}
      entities={props.attendees}
      paging={props.paging}
      onQuery={props.onQuery}
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    fetchAttendees: stores.attendeeStore.fetchAttendees,
    attendees: stores.attendeeStore.attendees,
    configs: stores.attendeeStore.configs,
    paging: stores.attendeeStore.paging,
    fetchConfigs: stores.attendeeStore.fetchConfigs,
    addSocialCoin: stores.attendeeStore.addSocialCoin,
  })),
  withHooks((props) => {
    const {
      attendees,
      fetchAttendees,
      paging,
      fetchConfigs,
      configs,
      addSocialCoin,
    } = props

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    const [queryParams, setQueryParams] = useState({})
    const { seasonId, cycleId } = queryParams

    const seasonOptions = useMemo(() => {
      const options = get(configs, 'filters.seasonId.options', [])
      return options
    }, [configs])

    const cycleOptions = useMemo(() => {
      const options = get(configs, 'filters.cycleId.options', [])
      return filter(options, { seasonId })
    }, [configs, seasonId])

    const teamOptions = useMemo(() => {
      const options = get(configs, 'filters.teamId.options', [])
      return filter(options, { cycleId })
    }, [configs, cycleId])

    const stateOptions = get(configs, 'filters.state.options', [])

    const $table = useRef()
    const onQuery = useCallback(
      (params) => {
        const filteredParams = clone(params)

        cleanFilter(filteredParams, 'seasonId', seasonOptions)
        cleanFilter(filteredParams, 'cycleId', cycleOptions)
        cleanFilter(filteredParams, 'teamId', teamOptions)

        if (!isEqual(params, filteredParams)) {
          $table.current.setFilter(filteredParams)
        } else if (!isEqual(filteredParams, queryParams)) {
          setQueryParams(filteredParams)
          fetchAttendees(filteredParams)
        }
      },
      [fetchAttendees, seasonOptions, cycleOptions, teamOptions, queryParams],
    )

    const onAddSocialCoin = useCallback(
      (attendee) => {
        Modal.open({
          title: `ระบุจำนวนเหรียญ และเหตุผลให้การเพิ่ม/ลด`,
          children: (
            <AddSocialCoinModal
              onSubmit={async ({ amount, description }) => {
                await addSocialCoin(attendee.id, { amount, description })
                await fetchAttendees($table.current.getQueryParams())
                Notification.success(`ดำเนินการสำเร็จ`)
                Modal.close()
              }}
            />
          ),
          hideFooter: true,
        })
      },
      [addSocialCoin, fetchAttendees],
    )

    const onAddSocialCoinList = useCallback(
      async (attendees) => {
        Modal.open({
          title: 'ระบุจำนวนเหรียญ และเหตุผลให้การเพิ่ม/ลด',
          children: (
            <AddSocialCoinModal
              onSubmit={async ({ amount, description }) => {
                const counter = { success: 0, failure: 0 }
                for (const attendee of attendees) {
                  try {
                    await addSocialCoin(
                      attendee.id,
                      { amount, description },
                      { notify: false },
                    )
                    counter.success += 1
                  } catch (e) {
                    counter.failure += 1
                  }
                }
                if (counter.success > 0) {
                  await fetchAttendees($table.current.getQueryParams())
                }

                if (counter.failure > 0) {
                  Notification.error(`ดำเนินการไม่สำเร็จ ${counter.failure} คน`)
                }
                if (counter.success > 0) {
                  Notification.success(`ดำเนินการสำเร็จ ${counter.success} คน`)
                }
                Modal.close()
              }}
            />
          ),
          hideFooter: true,
        })
      },
      [addSocialCoin, fetchAttendees],
    )

    const onExport = useCallback(async () => {
      let data = []

      for (let page = 1; true; page++) {
        const _data = await fetchAttendees(
          {
            ...$table.current.getQueryParams(),
            page,
            perPage: 500,
          },
          { preventSetState: true },
        )

        data = [...data, ..._data]

        if (_data.length === 0) {
          break
        }
      }

      await ExcelGenerator.generate({
        fileName: `attendees-${format(new Date(), 'yyyyMMddhhmmss')}`,
        columns: [
          {
            title: 'รหัส',
            field: 'code',
          },
          {
            title: 'ชื่อ-นามสกุล',
            field: 'fullName',
          },
          {
            title: 'ฤดูกาลแข่ง',
            field: 'season',
          },
          {
            title: 'รอบการแข่ง',
            field: 'cycle',
          },
          {
            title: 'ทีม',
            field: 'team',
          },
          {
            title: 'สถานะ',
            field: 'state',
          },
          {
            title: 'ระดับน้องสะกิด',
            field: 'treeLevel',
          },
          {
            title: 'Health Score',
            field: 'healthScore',
          },
          {
            title: 'สะกิดคอยน์',
            field: 'socialCoin',
          },
          {
            title: 'เข้าร่วมเมื่อ',
            field: 'joinAt',
            type: 'date',
          },
        ],
        data,
      })
    }, [fetchAttendees, $table])

    return {
      $table,
      attendees,
      onQuery,
      paging,
      seasonOptions,
      cycleOptions,
      teamOptions,
      stateOptions,
      onAddSocialCoin,
      onAddSocialCoinList,
      onExport,
    }
  }),
)

export default enhancer(AttendeeIndexPage)
