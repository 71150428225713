import { defaultProps } from '@enhancers'
import MissionEditPage from '../edit'
import paths from '@common/paths'

const enhancer = defaultProps({
  disabled: true,
  currentPage: 'show',
  pageActions: [
    {
      label: 'กลับ',
      icon: 'arrow-left',
      onClick: paths.missionsPath().push,
    },
  ],
})

export default enhancer(MissionEditPage)
