import React from 'react'
import * as yup from 'yup'
import paths from '@common/paths'
import { compose, withStores, withFormik, withHooks } from '@enhancers'
import { Field, Input, Button, Grid } from '@components'
import FormLayout from '../sign_in/FormLayout'

const RegisterPage = (props) => (
  <FormLayout
    title="Register New Admin"
    description="Enter your email to invite new admin via email."
    onSubmit={props.handleSubmit}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Field
          label="Email"
          name="email"
          component={Input}
          type="email"
          size="medium"
        />
      </Grid>
      <Grid item xs={12} container justify="space-between" alignItems="center">
        <Button type="submit" icon="paper-plane" size="large" color="primary">
          Send Email
        </Button>
      </Grid>
    </Grid>
  </FormLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    register: stores.authStore.register,
  })),
  withFormik({
    validationSchema: yup.object().shape({
      email: yup.string().email().required(),
    }),
    handleSubmit: async (values, formikBag) => {
      await formikBag.props.register(values)
      paths.signInPath().push()
    },
  }),
  withHooks((props) => {
    const { handleSubmit } = props

    return {
      handleSubmit,
    }
  }),
)

export default enhancer(RegisterPage)
