import { useCallback, useEffect, useMemo } from 'react'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import { useParams } from 'react-router-dom'
import { EmployeeEditPage } from '../edit/index'
import { format } from 'date-fns'

const enhancer = compose(
  withStores((stores) => ({
    findEmployee: stores.employeeStore.findEmployee,
    currentEmployee: stores.employeeStore.currentEmployee,
    updateEmployee: stores.employeeStore.updateEmployee,
    clearCurrentEmployee: stores.employeeStore.clearCurrentEmployee,
  })),
  withFormik({
    mapPropsToValues: (props) => props.currentEmployee || {},
  }),
  withHooks((props) => {
    const { findEmployee, currentEmployee, clearCurrentEmployee } = props
    const { id } = useParams()

    useEffect(() => {
      findEmployee(id)
    }, [findEmployee, id])

    const goBack = useCallback(() => {
      paths.currentCompanyPath().employeesPath().push()
    }, [])

    const dateHistoriesOptions = useMemo(() => {
      console.log(
        'currentEmployee?.dateHistories',
        currentEmployee?.dateHistories,
      )
      return currentEmployee?.dateHistories?.map((item) => {
        console.log('item', item)
        return {
          label: format(item, 'dd-MM-yyyy'),
          value: item,
        }
      })
    }, [currentEmployee])

    useEffect(() => {
      return () => {
        clearCurrentEmployee()
      }
    }, [clearCurrentEmployee])

    return {
      goBack,
      title: `พนักงาน '${currentEmployee?.firstName} ${currentEmployee?.lastName}'`,
      employee: currentEmployee,
      dateHistoriesOptions,
      currentPage: 'show',
      disabled: true,
      codeDisabled: true,
    }
  }),
)

export default enhancer(EmployeeEditPage)
