import { useMemo, useEffect } from 'react'
import { compose, withHooks } from '@enhancers'
import Input from './Input'

const enhancer = compose(
  withHooks(({ onSearch, searchDelay = 800, ...rest }) => {
    const CACHE = useMemo(() => ({}), [])

    const value = rest.field?.value || rest.value
    useEffect(() => {
      if (onSearch) {
        if (CACHE.searchTask) {
          clearTimeout(CACHE.searchTask)
        }
        CACHE.searchTask = setTimeout(() => {
          onSearch(value)
        }, searchDelay)
      }
    }, [value, onSearch, searchDelay, CACHE])

    return {
      ...rest,
    }
  }),
)

export default enhancer(Input)
