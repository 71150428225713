import React from 'react'
import { compose, withHooks } from '@enhancers'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Field,
  Checkbox,
  Grid,
  ExpandMoreIcon,
  FormHelperText,
} from '@components'
import DailyMissionTemplateInfo from './DailyMissionTemplateInfo'

const DailyMissionTemplateCollapse = (props) => (
  <Accordion TransitionProps={{ mountOnEnter: true }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Grid container alignItems="center">
        <Grid item>
          <Field
            name={`${props.name}.checked`}
            component={Checkbox}
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
          />
        </Grid>
        <Grid item>
          {props.title}
          <FormHelperText className="ml-2">{props.helperText}</FormHelperText>
        </Grid>
      </Grid>
    </AccordionSummary>
    <AccordionDetails>
      <DailyMissionTemplateInfo
        name={props.name}
        tagOptions={props.tagOptions}
        answerTypeOptions={props.answerTypeOptions}
        articleTemplateOptions={props.articleTemplateOptions}
        isTemplate
      />
    </AccordionDetails>
  </Accordion>
)

const enhancer = compose(
  withHooks((props) => {
    const { day, tagOptions, answerTypeOptions, articleTemplateOptions } = props
    const name = `dailyMissionTemplates[${day - 1}]`
    const title = `วันที่ ${day}`
    const helperText = ` (โปรดเลือกหากต้องการให้ส่งภารกิจของวันที่ ${day} ให้ผู้แข่งขัน)`

    return {
      name,
      title,
      helperText,
      tagOptions,
      answerTypeOptions,
      articleTemplateOptions,
    }
  }),
)

export default enhancer(DailyMissionTemplateCollapse)
