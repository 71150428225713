import React, { useCallback, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useHtmlClassService } from '../../../_core/MetronicLayout'
import { compose, withHooks, withStores } from '@enhancers'
import { COMPANY_MENU } from '@common/menu'
import { Avatar, Notification } from '@components'
import { css } from '@styled'

const avatarStyle = css`
  border-radius: 8px !important;
`

const AsideMenuComponent = (props) => (
  <div key={props.currentCompanyId} className="tab-pane fade show active">
    <div className="aside-brand d-flex flex-column align-items-center flex-column-auto py-5 pt-lg-12 pb-lg-6">
      <Avatar
        className={avatarStyle}
        size={50}
        src={props.companyLogoUrl}
        onChange={props.onEditAvatar}>
        {props.companyName ? props.companyName[0] : ''}
      </Avatar>
      <h3 className="mt-3">{props.companyName}</h3>
    </div>
    <div className="aside-menu-wrapper flex-column-fluid px-5">
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu ${props.layoutProps.asideClassesFromConfig}`}
        {...props.layoutProps.asideMenuAttr}>
        <ul className="menu-nav py-0">
          {props.menu.map(({ label, path, Icon, icon }) => (
            <li key={label} className="menu-item mb-2">
              <NavLink className="menu-link btn btn-clean px-2" to={path}>
                <span className="svg-icon menu-icon">
                  {Icon && <Icon />}
                  {icon && <i className={`fa fa fa-${icon} mx-auto`}></i>}
                </span>
                <span className="menu-text">{label}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
)

const enhancer = compose(
  withStores((stores) => ({
    currentCompany: stores.companyStore.currentCompany || {},
    updateCompany: stores.companyStore.updateCompany,
  })),
  withHooks((props) => {
    const { currentRole, currentCompany, updateCompany } = props
    const currentCompanyId = currentCompany.id

    const uiService = useHtmlClassService()
    const layoutProps = useMemo(() => {
      return {
        layoutConfig: uiService.config,
        asideMenuAttr: uiService.getAttributes('aside_menu'),
        ulClasses: uiService.getClasses('aside_menu_nav', true),
        asideClassesFromConfig: uiService.getClasses('aside_menu', true),
      }
    }, [uiService])

    const menu = useMemo(() => {
      return COMPANY_MENU(currentCompanyId).filter((m) =>
        m.permittedRoles.includes(currentRole),
      )
    }, [currentRole, currentCompanyId])

    const companyName = useMemo(() => {
      return currentCompany ? currentCompany.name : null
    }, [currentCompany])

    const companyLogoUrl = useMemo(() => {
      return currentCompany ? currentCompany.logoUrl : null
    }, [currentCompany])

    const onEditAvatar = useCallback(
      async (file) => {
        await updateCompany(currentCompanyId, { logo: file })
        Notification.success()
      },
      [currentCompanyId, updateCompany],
    )

    return {
      currentCompanyId,
      layoutProps,
      menu,
      companyName,
      companyLogoUrl,
      onEditAvatar,
    }
  }),
)

export const AsideMenu = enhancer(AsideMenuComponent)
