import ActiveStore from './ActiveStore'

class AdminStore extends ActiveStore {
  constructor() {
    super('admin')
  }

  resendAllConfirmations = async (ids) => {
    await this.server.post(`/resend`, { ids })
  }

  resendConfirmation = async (id) => {
    await this.resendAllConfirmations([id])
  }
}

export default new AdminStore()
