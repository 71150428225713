import React, { useCallback, useEffect, useState } from 'react'
import { Modal, Notification, PageLayout, Table } from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import { get } from 'lodash'
import { css } from '@styled'

const columnClass = css`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const MissionTemplatePage = (props) => (
  <PageLayout
    title="ต้นแบบภารกิจ"
    actions={[
      {
        label: 'เพิ่มต้นแบบภารกิจ',
        icon: 'plus',
        color: 'primary',
        onClick: props.onCreate,
      },
    ]}>
    <Table
      filters={[
        {
          description: 'กรองด้วยชื่อภารกิจ',
          dataField: 'title',
          type: 'search',
        },
      ]}
      columns={[
        {
          title: 'ชื่อภารกิจ',
          dataField: 'title',
          sort: true,
          align: 'left',
        },
        {
          title: 'cover image',
          dataField: 'coverImageUrl',
          type: 'image',
          nameKey: 'coverImage',
          onClick: props.onEdit,
        },
        {
          title: 'รางวัล',
          dataField: 'reward',
          className: columnClass,
        },
        {
          title: 'คำอธิบาย',
          dataField: 'description',
          className: columnClass,
        },
        // {
        //   title: 'Rule',
        //   dataField: 'rules',
        //   className: columnClass,
        // },
        // {
        //   title: 'Condition',
        //   dataField: 'condition',
        //   className: columnClass,
        // },
        {
          title: 'tag',
          dataField: 'tags',
        },
        {
          title: 'การดำเนินการ',
          type: 'actions',
          actions: [
            {
              label: 'เปิด',
              icon: 'toggle-off',
              hoverColor: 'primary',
              onClick: props.onPublish,
              hidden: (missionTemplate) => !missionTemplate.canPublish,
            },
            {
              label: 'ปิด',
              icon: 'toggle-on',
              hoverColor: 'danger',
              onClick: props.onUnpublished,
              hidden: (missionTemplate) => !missionTemplate.canUnpublish,
            },
            {
              label: 'แก้ไข',
              icon: 'pen',
              hoverColor: 'primary',
              onClick: props.onEdit,
            },
            {
              label: 'ลบ',
              icon: 'trash',
              hoverColor: 'danger',
              onClick: props.onDelete,
              visible: (template) => template.canDelete,
            },
          ],
        },
      ]}
      entities={props.missionTemplates}
      paging={props.paging}
      onQuery={props.onQuery}
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    configs: stores.missionTemplateStore.configs,
    paging: stores.missionTemplateStore.paging,
    fetchConfigs: stores.missionTemplateStore.fetchConfigs,
    missionTemplates: stores.missionTemplateStore.missionTemplates,
    fetchMissionTemplates: stores.missionTemplateStore.fetchMissionTemplates,
    deleteMissionTemplate: stores.missionTemplateStore.deleteMissionTemplate,
    publishMissionTemplate: stores.missionTemplateStore.publishMissionTemplate,
    unpublishedMissionTemplate:
      stores.missionTemplateStore.unpublishedMissionTemplate,
  })),
  withHooks((props) => {
    const {
      configs,
      missionTemplates,
      paging,
      fetchMissionTemplates,
      deleteMissionTemplate,
      publishMissionTemplate,
      unpublishedMissionTemplate,
      fetchConfigs,
    } = props

    const categoryOptions = get(configs, 'attributes.category.options', [])
    const [customMissionTemplates, setCustomMissionTemplates] = useState([])

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    const onQuery = fetchMissionTemplates

    const onCreate = useCallback(() => {
      paths.missionTemplateNewPath().push()
    }, [])

    const onEdit = useCallback((template) => {
      paths.missionTemplateEditPath(template.id).newTab()
    }, [])

    const onDelete = useCallback(
      async (template) => {
        Modal.confirm({
          children: 'Permanent delete this Template.',
          onOk: async (modal) => {
            await deleteMissionTemplate(template.id)
            Notification.success()
            modal.close()
          },
        })
      },
      [deleteMissionTemplate],
    )

    const onPublish = useCallback(
      async (missionTemplate) => {
        await publishMissionTemplate(missionTemplate.id)
      },
      [publishMissionTemplate],
    )

    const onUnpublished = useCallback(
      async (missionTemplate) => {
        await unpublishedMissionTemplate(missionTemplate.id)
      },
      [unpublishedMissionTemplate],
    )

    useEffect(() => {
      const customMissionTemplates = missionTemplates.map((missionTemplate) => {
        const tags = missionTemplate.tags || []
        return { ...missionTemplate, tags: tags.join(', ') }
      })
      setCustomMissionTemplates(customMissionTemplates)
    }, [missionTemplates])

    return {
      onCreate,
      onDelete,
      onEdit,
      onPublish,
      onUnpublished,
      paging,
      onQuery,
      categoryOptions,
      missionTemplates: customMissionTemplates,
    }
  }),
)

export default enhancer(MissionTemplatePage)
