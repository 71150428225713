import React, { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  Field,
  Input,
  PageLayout,
  Grid,
  Notification,
  Avatar,
  Select,
} from '@components'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import * as yup from 'yup'
import { get } from 'lodash' //, filter, isEmpty

const CoachEditPage = (props) => (
  <PageLayout
    title={props.title}
    actions={[
      {
        label: 'กลับ',
        icon: 'arrow-left',
        onClick: props.onBack,
      },
      {
        label: 'ล้างค่าที่กรอก',
        icon: 'redo',
        onClick: props.onReset,
      },
      {
        label: 'บันทึก',
        icon: 'save',
        color: 'primary',
        onClick: props.onSave,
        type: 'submit',
      },
    ]}>
    <Grid container spacing={1} style={{ marginBottom: '20px' }}>
      <Field name="avatar" src={props.avatarUrl} component={Avatar} />
    </Grid>
    <Grid container spacing={3}>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ชื่อเล่นโค้ช"
          name="name"
          component={Input}
          placeholder="ชื่อเล่นโค้ช"
          required
          helperText="โปรดระบุชื่อเล่น"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ชื่อโค้ช"
          name="fullName"
          component={Input}
          placeholder="ชื่อโค้ช"
          required
          helperText="โปรดระบุชื่อ"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="อีเมล"
          name="email"
          component={Input}
          placeholder="อีเมล"
          required
          helperText="โปรดระบุอีเมล"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="Tag"
          name="tags"
          component={Select}
          options={props.tagOptions}
          multiple
          placeholder="Tag"
          clearOnBlur
          freeSolo
          helperText="โปรดเลือก Tag"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ความเชี่ยวชาญ"
          name="expertise"
          component={Input}
          placeholder="ความเชี่ยวชาญ"
          multiple
          type="text"
          helperText="โปรดระบุความเชี่ยวชาญ"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="คติประจำใจ"
          name="motto"
          component={Input}
          placeholder="คติประจำใจ"
          helperText="โปรดระบุคติประจำใจ"
          multiple
          type="text"
        />
      </Grid>
    </Grid>
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    updateCoach: stores.coachStore.updateCoach,
    fetchConfigs: stores.coachStore.fetchConfigs,
    configs: stores.coachStore.configs,
    findCoach: stores.coachStore.findCoach,
    coach: stores.coachStore.currentCoach,
  })),
  withFormik({
    mapPropsToValues: (props) => props.coach || {},
    validationSchema: yup.object().shape({
      email: yup.string().email().required(),
      name: yup.string().required(),
    }),
    handleSubmit: async (values, formikBag) => {
      const { id, ...params } = values
      await formikBag.props.updateCoach(id, params)
      Notification.success('Update coach success.')
      paths.coachesPath().push()
    },
  }),
  withHooks((props) => {
    const {
      handleReset,
      handleSubmit,
      configs,
      findCoach,
      coach,
      fetchConfigs,
    } = props
    const { coachId } = useParams()

    useEffect(() => {
      findCoach(coachId)
    }, [findCoach, coachId])

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    const tagOptions = get(configs, 'attributes.tag.options', [])

    const onBack = useCallback(() => {
      paths.coachesPath().push()
    }, [])

    const coachName = coach ? coach.name : ''
    const title = useMemo(() => {
      return `แก้ไขโค้ช '${coachName}'`
    }, [coachName])

    const avatarUrl = useMemo(() => {
      return get(coach, 'avatarUrl')
    }, [coach])

    return {
      onBack,
      onReset: handleReset,
      onSave: handleSubmit,
      tagOptions,
      title,
      avatarUrl,
    }
  }),
)

export default enhancer(CoachEditPage)
