import ActiveStore from './ActiveStore'

class HumanResourceStore extends ActiveStore {
  constructor() {
    super('humanResource')
  }

  resendVerifyHrEmail = async hrId => {
    await this.server.post(`/${hrId}/resend`)
  }
}

export default new HumanResourceStore()
