const EWC_DEFAULT_COLOR = '#1BC5BD'

export const theme = {
  // fontFamily: 'Inter, sans-serif',
  fontFamily: ['Poppins', 'Prompt'].join(', '),
  fontSize: '14px',
  primaryColor: '#39f',
  color: {
    primary: '#141414',
    secondary: '#cccccc',
    darkSecondary: '#eaeaea',
    grey: 'rgba(20,20,20,0.04)',
    helpText: 'rgba(20, 20, 20, 0.6)',
    blue: '#39f',
    white: '#fff',
    green: EWC_DEFAULT_COLOR,
    red: '#ff3b30',
  },
  borderColor: {
    primary: '#eaeaea',
    secondary: '#cccccc',
    darkSecondary: '#eaeaea',
    white: '#fff',
    green: EWC_DEFAULT_COLOR,
    blue: '#39f',
  },
  backgroundColor: {
    white: '#fff',
    primary: '#E6E6E6',
    secondary: '#f6f6f6',
    blue: '#39f',
    green: EWC_DEFAULT_COLOR,
    red: '#ff3b30',
    grey: 'rgba(20,20,20,0.04)',
    lightGrey: 'rgba(20, 20, 20, 0.08)',
    darkGrey: 'rgba(20,20,20,0.9)',
    callScreenGrey: '#444444',
    silver: '#c4c4c4',
  },
  breakPoints: ['(min-width: 320px) and (max-width: 767px)'],
}
