export const listItem = (props) => {
  const { conversation } = props
  let selectedState =
    props.selectedConversation &&
    props.selectedConversation.conversationId ===
      props.conversation.conversationId
      ? {
          backgroundColor: `#F3F6F9`,
        }
      : {}

  if (conversation.unreadMessageCount > 0) {
    selectedState = {
      backgroundColor: '#e0e0e0',
    }
  }

  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    padding: '8px 16px',
    ...selectedState,
    '&:hover': {
      backgroundColor: `#F3F6F9`,
    },
  }
}

export const itemThumbnailStyle = () => {
  return {
    display: 'inline-block',
    width: '36px',
    height: '36px',
    flexShrink: '0',
  }
}

export const itemDetailStyle = () => {
  return {
    width: 'calc(100% - 45px)',
    flexGrow: '1',
    paddingLeft: '16px',
    '&[dir=rtl]': {
      paddingRight: '16px',
      paddingLeft: '0',
    },
  }
}

export const itemRowStyle = () => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  }
}

export const itemNameStyle = () => {
  return {
    fontSize: '15px',
    fontWeight: '600',
    display: 'block',
    width: 'calc(100% - 60px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }
}

export const itemLastMsgStyle = (props) => {
  return {
    margin: '0',
    fontSize: '13px',
    maxWidth: '80px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '20px',
    color: `${props.theme.color.helpText}`,
  }
}

export const itemLastMsgTimeStyle = (props) => {
  return {
    fontSize: '11px',
    textTransform: 'uppercase',
    color: `${props.theme.color.helpText}`,
  }
}

export const itemSeasonStyle = (props) => {
  return {
    fontSize: '10px',
    display: 'block',
    width: '80px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: `${props.theme.color.helpText}`,
  }
}
