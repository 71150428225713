import React, { useContext, useEffect, useState } from 'react'
import { compose, withHooks, withStores } from '@enhancers'
import { call, clearToken, getToken } from '@common/helper'
import { MetronicSplashScreenContext } from '_metronic/layout'

import AuthPage from './auth/index'
import MainPage from './MainPage'

const Pages = (props) =>
  props.initialized ? props.isAuthorized ? <MainPage /> : <AuthPage /> : null

const enhancer = compose(
  withStores((stores) => ({
    appInitialized: stores.appStore.initialized,
    getApplicationInfo: stores.appStore.getInfo,
    authenticate: stores.authStore.authenticate,
    isAuthorized: stores.authStore.isAuthorized,
    initChat: stores.chatStore.initChat,
  })),
  withHooks((props) => {
    const {
      appInitialized,
      getApplicationInfo,
      authenticate,
      isAuthorized,
    } = props

    const [initialized, setInitialized] = useState(false)
    const setCount = useContext(MetronicSplashScreenContext)

    useEffect(() => {
      if (appInitialized) {
        call(async () => {
          const safeAuthenticate = async () => {
            if (getToken()) {
              try {
                await authenticate()
              } catch (e) {
                clearToken()
                console.info('unauthorize!', e)
              }
            }
          }

          await Promise.all([
            getApplicationInfo(),
            safeAuthenticate({ notify: true }),
          ])
          setInitialized(true)
          setCount(0)
        })
      }
    }, [appInitialized, getApplicationInfo, authenticate, setCount])

    return {
      initialized,
      isAuthorized,
    }
  }),
)

export default enhancer(Pages)
