import ActiveStore from './ActiveStore'
import { filter } from 'lodash'

class EmployeeStore extends ActiveStore {
  constructor() {
    super('employee', {
      currentEmployeeHistory: {},
    })
  }

  importEmployees = async (excelFile) => {
    await this.server.post(`/import`, { excelFile })
  }

  clearPinEmployee = async (employeeId) => {
    await this.server.delete(`/${employeeId}/clear_app_user_token_and_pin_code`)
  }

  deleteEmployee = async (employeeId) => {
    await this.server.delete(``, { employeeIds: [employeeId] })
    this.setState({
      employees: filter(
        this.state.employees,
        ({ id }) => ![employeeId].includes(id),
      ),
    })
  }

  terminateEmployee = async (employeeId) => {
    await this.server.delete(`/${employeeId}/terminate`)
  }

  unterminateEmployee = async (employeeId) => {
    await this.server.post(`/${employeeId}/unterminate`)
  }

  fetchEmployeeHistory = async (employeeId, params) => {
    const response = await this.server.get(`/${employeeId}/history`, params)
    this.setState({
      currentEmployeeHistory: {
        ...response.data.data.history,
        date: params.date,
        id: employeeId,
      },
    })
  }

  updateEmployeeHistory = async (employeeId, params) => {
    const response = await this.server.post(`/${employeeId}/history`, params)
    this.setState({
      currentEmployeeHistory: {
        ...response.data.data.history,
        date: params.date,
        id: employeeId,
      },
    })
  }

  deleteEmployeeHistory = async (employeeId, params) => {
    const response = await this.server.delete(`/${employeeId}/history`, params)
    this.setState({
      currentEmployeeHistory: {
        ...response.data.data.history,
        date: null,
        id: employeeId,
      },
    })
  }

  clearEmployeeHistory = () => {
    this.setState({
      currentEmployeeHistory: null,
    })
  }

  updateBasicInfo = async (employeeId, params) => {
    const response = await this.server.put(`/${employeeId}/basic_info`, params)
    this.setState({
      currentEmployee: response.data.data.employee,
    })
  }
}

export default new EmployeeStore()
