import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { withStores, withHooks, compose, withFormik } from '@enhancers'
import { useEffectWithDelay } from '@hooks'
import './style.scss'
import { filter, isEmpty, isEqual } from 'lodash'
import classNames from 'classnames'
import CometChatConversationList from '../CometChat/components/CometChatConversationList'
import CometChatMessageListScreen from '../CometChat/components/CometChatMessageListScreen'
import AttendeeInfo from './AttendeeInfo/index'
import { AttendeeProvider } from './AttendeeStoreContext'
import {
  PageLayout,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Select,
  Field,
  Grid,
  SearchInput,
  Link,
  Paper,
} from '@components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { cleanFilter } from '@common/helper'
import styled, { css } from '@styled'
import { SvgAvatar } from '../CometChat/util/svgavatar'
import { AttendeeAvatar } from '@pages/chats/CometChat/components/ConversationView/index'

const ResponsivePageLayout = styled(PageLayout)`
  .MuiPaper-root.MuiExpansionPanel-root {
    margin-bottom: 26px;
  }

  .chat_list {
    height: inherit;
    padding: 0 10px 10px 10px;
    flex-direction: column;
    overflow-y: auto;
  }

  .chat_info {
    flex: 1;
    padding: 0 10px;
    height: 100%;
    overflow-y: auto;

    .fa-chevron-left {
      margin-left: 0px;
    }
  }

  .chats {
    width: 100%;

    div {
      overflow-y: unset;
    }
  }

  @media screen and (max-width: 960px) {
    .card-header {
      display: none !important;
    }

    .MuiPaper-root.MuiExpansionPanel-root {
      margin: 16px 0;
    }

    .chat_list {
      width: 100%;
    }

    ${(props) =>
      props.currentPage === 'box'
        ? `
            .chat_list, .chat_info {
              display: none;
            }
          `
        : props.currentPage === 'info'
        ? `
            .chat_list, .chat_box {
              display: none;
            }
          `
        : `
            .chat_info, .chat_box {
              display: none;
            }
          `}
  }

  @media screen and (max-width: 767px) {
    .card-body {
      padding: 0 !important;
    }
  }
`
const ResponsFilterPanel = styled(ExpansionPanel)`
  @media screen and (max-width: 960px) {
    display: none;

    ${(props) =>
      props.currentpage === 'list' &&
      `
      display: flex;
      flex-direction: column;
    `}
  }
`
const WrapLink = styled.div`
  align-self: center;
  > * {
    font-size: 16px;
  }
  i {
    color: #1bc5bd;
    margin-left: 10px;
  }
`
const HeaderChats = styled.div`
  display: none;

  @media screen and (max-width: 960px) {
    ${(props) =>
      props.currentPage === 'box' &&
      `
        display: flex;
        height: 50px;
        align-self: center;
      `}
  }
`
const avatarStyled = css`
  align-self: center;
  margin: 0 10px;
  width: 40px;
`
const textWrapper = css`
  display: flex;
  flex-direction: column;
  align-self: center;
`
const statusWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const AttendeeInfoLayout = styled.div`
  .ccl-dtls-panel-section {
    div {
      font-size: 1rem;
    }

    h4 {
      font-size: 1.1rem;
    }
  }

  .ccl-right-panel-head-ttl {
    margin-bottom: 1rem;
    padding-left: 10px;
  }

  @media screen and (max-width: 960px) {
    display: block;
    width: 100%;
    margin-top: 20px !important;
  }
`
const NotfoundList = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 80px;
  padding: 10px;
  border-radius: 16px;
  background-color: #e0dddc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (min-width: 320px) and (max-width: 960px) {
    margin: 0px 20px;
  }
`
const notFoundText = css`
  font-size: 14px;
`
const AttendeeBackButton = styled(WrapLink)`
  display: none;

  @media screen and (max-width: 960px) {
    ${(props) =>
      props.currentPage === 'info' &&
      `
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `}
  }
`

const bodyDesktopStyle = css`
  @media screen and (min-width: 960px) {
    padding-top: 20px;
  }
`
const listContainer = css`
  height: calc(100vh - 55px);

  @media screen and (min-width: 960px) {
    height: calc(100vh - 302px);
  }
`

const ChatPage = (props) => (
  <ResponsivePageLayout title="แชท" currentPage={props.currentPage}>
    <Paper className={bodyDesktopStyle}>
      <Grid container className={listContainer}>
        <Grid item className="chat_list" lg={3} md={3} sm={12} xs={12}>
          <ResponsFilterPanel currentpage={props.currentPage}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header">
              {props.queryParams}
              <Typography>ตัวกรองแชท</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={3}>
                {props.currentRole === 'admin' && (
                  <Grid item xs={12}>
                    <Field
                      label="กรองด้วยโค้ช"
                      component={Select}
                      name="coach"
                      options={props.coachOptions}
                      placeholder="กรองด้วยโค้ช"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Field
                    label="กรองด้วยสถานะของรอบ"
                    name="cycleState"
                    component={Select}
                    options={props.cycleStateOptions}
                    placeholder="กรองด้วยสถานะของรอบ"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="กรองด้วยองค์กร"
                    component={Select}
                    name="companyId"
                    options={props.companyOptions}
                    placeholder="กรองด้วยองค์กร"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="กรองด้วยฤดูกาลแข่ง"
                    name="seasonId"
                    component={Select}
                    options={props.seasonOptions}
                    placeholder="กรองด้วยฤดูกาลแข่ง"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="กรองด้วยรอบแข่งที่"
                    name="cycleId"
                    component={Select}
                    options={props.cycleOptions}
                    placeholder="กรองด้วยรอบแข่งที่"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="กรองด้วยทีม"
                    name="teamId"
                    component={Select}
                    options={props.teamOptions}
                    placeholder="กรองด้วยทีม"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="กรองด้วยผู้แข่งขัน"
                    name="attendeeName"
                    component={SearchInput}
                    placeholder="กรองด้วยผู้แข่งขัน"
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ResponsFilterPanel>
          <NotfoundList show={props.loading}>
            <span className={notFoundText}>
              กำลังเรียกข้อมูลโปรดรอซักครู่อาจใช้เวลานานหากมีจำนวนมาก
            </span>
          </NotfoundList>
          <NotfoundList
            show={
              !props.loading &&
              props.attendeeContextStore.attendees.length === 0
            }>
            <span className={notFoundText}>
              ไม่พบข้อมูลแชทที่ตรงกับตัวกรองปัจจุบัน
              กรุณาปรับข้อมูลในตัวกรองแชทอีกครั้ง
            </span>
          </NotfoundList>
          <AttendeeProvider value={props.attendeeContextStore}>
            {props.user && (
              <CometChatConversationList
                item={props.state.item}
                onItemClick={props.onItemClicked}
                type={'group'}
                user={props.user}
                actionGenerated={props.actionHandler}
              />
            )}
          </AttendeeProvider>
        </Grid>

        <HeaderChats currentPage={props.currentPage}>
          <WrapLink>
            <Link onClick={props.backToList}>
              <i className="fas fa-chevron-left" />
            </Link>
          </WrapLink>
          <div className={avatarStyled}>
            <AttendeeAvatar name={props.state.item.attendeeName} />
          </div>
          <div className={textWrapper}>
            <span>{props.state.item.attendeeName}</span>
            <div className={statusWrapper}>
              {/* <div>
                <i
                  className="fas fa-circle"
                  style={{
                    fontSize: 10,
                    marginRight: 4,
                    color:
                      props.state.item.status === 'online' ? 'green' : 'red',
                  }}
                />
                <span>{props.state.item.status}</span>
              </div> */}
              <Link onClick={props.toggleMoreDetail} style={{ marginLeft: 0 }}>
                more
              </Link>
            </div>
          </div>
        </HeaderChats>

        <Grid item className="chat_box" lg={6} md={6} sm={12} xs={12}>
          {Object.keys(props.state.item).length > 0 && (
            <CometChatMessageListScreen
              articleTemplateOptions={props.articleTemplateOptions}
              item={props.state.item}
              tab={props.state.tab}
              type="group"
              composedthreadmessage={props.state.composedthreadmessage}
              actionGenerated={props.actionHandler}
            />
          )}
        </Grid>

        <AttendeeInfoLayout
          className="chat_info"
          currentPage={props.currentPage}>
          <AttendeeBackButton currentPage={props.currentPage}>
            <Link onClick={props.toggleMoreDetail}>
              <i className="fas fa-chevron-left" />
            </Link>
          </AttendeeBackButton>
          <AttendeeInfo
            item={props.state.item}
            type="user"
            actionGenerated={props.actionHandler}
          />
        </AttendeeInfoLayout>
      </Grid>
    </Paper>
  </ResponsivePageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    loginComet: stores.chatStore.loginComet,
    initChat: stores.chatStore.initChat,
    chatUid: stores.authStore.chatUid,
    currentRole: stores.authStore.currentRole,
    configs: stores.chatStore.configs,
    fetchConfigs: stores.chatStore.fetchConfigs,
    clearAttendees: stores.chatStore.clearAttendees,
    getAttendees: stores.chatStore.getAttendees,
    getAttendee: stores.chatStore.getAttendee,
    attendees: stores.chatStore.attendees,
    user: stores.chatStore.user,
    logoutComet: stores.chatStore.logoutComet,
  })),
  withFormik({
    mapPropsToValues: (props) => ({
      cycleState: 'active',
    }),
  }),
  withHooks((props) => {
    const {
      loginComet,
      fetchConfigs,
      clearAttendees,
      getAttendees,
      getAttendee,
      configs,
      attendees,
      user,
      currentRole,
      logoutComet,
      values,
      setValues,
    } = props

    const { coach, companyId, seasonId, cycleId } = values || {}

    const [loading, setLoading] = useState(false)

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    useEffect(() => {
      if (currentRole === 'admin') {
        return () => logoutComet()
      }
    }, [currentRole, logoutComet])

    useEffect(() => {
      if (currentRole === 'admin') {
        if (coach) {
          loginComet(coach)
        } else {
          logoutComet(coach)
        }
      }
    }, [currentRole, coach, loginComet, logoutComet])

    useEffectWithDelay(
      async () => {
        if (currentRole === 'admin' && !coach) {
          clearAttendees()
          return
        }

        if (values) {
          clearAttendees()
          setLoading(true)
          console.log('setLoading(true)')
          await getAttendees(values)
          console.log('setLoading(false)')
          setLoading(false)
        }
      },
      200,
      [clearAttendees, getAttendees, values],
    )

    const [isShowAttendee, setShowAttendee] = useState(false)
    const toggleMoreDetail = useCallback(() => {
      setShowAttendee(!isShowAttendee)
    }, [isShowAttendee])

    const articleTemplateOptions =
      configs?.filters?.articleTemplate?.options || []

    const coachOptions = configs?.filters?.coach?.options || []
    const cycleStateOptions = configs?.filters?.cycleState?.options || []
    const companyOptions = configs?.filters?.companyId?.options || []

    const seasonOptions = useMemo(() => {
      return filter(configs?.filters?.seasonId?.options, { companyId })
    }, [configs, companyId])

    const cycleOptions = useMemo(() => {
      return filter(configs?.filters?.cycleId?.options, { seasonId })
    }, [configs, seasonId])

    const teamOptions = useMemo(() => {
      return filter(configs?.filters?.teamId?.options, { cycleId })
    }, [configs, cycleId])

    useEffect(() => {
      const filterValues = { ...values }

      cleanFilter(filterValues, 'companyId', companyOptions)
      cleanFilter(filterValues, 'seasonId', seasonOptions)
      cleanFilter(filterValues, 'cycleId', cycleOptions)
      cleanFilter(filterValues, 'teamId', teamOptions)

      if (!isEqual(values, filterValues)) {
        setValues(filterValues)
      }
    }, [
      values,
      setValues,
      companyOptions,
      seasonOptions,
      cycleOptions,
      teamOptions,
    ])

    const [state, setState] = useState({
      darktheme: false,
      viewdetailscreen: false,
      item: {},
      type: 'group',
      tab: 'contacts',
      threadmessageview: false,
      threadmessagetype: null,
      threadmessageitem: {},
      threadmessageparent: {},
      composedthreadmessage: {},
      root: {
        flexGrow: 1,
      },
      paper: {
        padding: 2,
        textAlign: 'center',
      },
    })

    const [currentPage, setCurrentPage] = useState('')

    useEffect(() => {
      if (isShowAttendee) {
        setCurrentPage('info')
      } else if (Object.keys(state.item).length > 0) {
        setCurrentPage('box')
      } else {
        setCurrentPage('list')
      }
    }, [state.item, isShowAttendee, setCurrentPage])

    useEffect(() => {
      setState({ item: {} })
    }, [values.coach])

    useEffect(() => {
      if (isEmpty(attendees)) setState({ item: {} })
    }, [attendees])

    const centerPanelClassName = classNames({
      'ccl-center-panel': true,
      'ccl-chat-center-panel': true,
      'right-panel-active': true,
    })

    const wrapperClassName = classNames({
      users: true,
      dark: state.darktheme,
    })

    const onItemClicked = useCallback(
      async (item, type) => {
        const attendee = await getAttendee(item?.id)
        setState({ item: { ...item, ...attendee }, type })
      },
      [getAttendee],
    )

    const blockUser = useCallback(() => {}, [])

    const unblockUser = useCallback(() => {}, [])

    const toggleSideBar = useCallback(() => {
      const elem = this.leftPanelRef.current

      if (elem.classList.contains('active')) {
        elem.classList.remove('active')
      } else {
        elem.classList.add('active')
      }
    }, [])

    const toggleDetailView = useCallback(() => {
      let viewdetail = !state.viewdetailscreen
      setState({ viewdetailscreen: viewdetail, threadmessageview: false })
    }, [state])

    const closeThreadMessages = useCallback(() => {
      setState({ viewdetailscreen: false, threadmessageview: false })
    }, [])

    const viewMessageThread = useCallback(
      (parentMessage) => {
        const message = { ...parentMessage }
        const threaditem = { ...state.item }
        setState({
          threadmessageview: true,
          threadmessageparent: message,
          threadmessageitem: threaditem,
          threadmessagetype: state.type,
          viewdetailscreen: false,
        })
      },
      [state],
    )

    const onThreadMessageComposed = useCallback(
      (composedMessage) => {
        if (state.type !== state.threadmessagetype) {
          return false
        }

        if (
          (state.threadmessagetype === 'group' &&
            state.item.guid !== state.threadmessageitem.guid) ||
          (state.threadmessagetype === 'user' &&
            state.item.uid !== state.threadmessageitem.uid)
        ) {
          return false
        }

        const message = { ...composedMessage }
        setState({ composedthreadmessage: message })
      },
      [state],
    )

    const actionHandler = useCallback(
      (action, item) => {
        switch (action) {
          case 'blockUser':
            blockUser()
            break
          case 'unblockUser':
            unblockUser()
            break
          case 'viewDetail':
          case 'closeDetailClicked':
            toggleDetailView()
            break
          case 'menuClicked':
          case 'closeMenuClicked':
            toggleSideBar()
            break
          case 'viewMessageThread':
            viewMessageThread(item)
            break
          case 'closeThreadClicked':
            closeThreadMessages()
            break
          case 'threadMessageComposed':
            onThreadMessageComposed(item)
            break
          default:
            break
        }
      },
      [
        blockUser,
        unblockUser,
        toggleSideBar,
        viewMessageThread,
        closeThreadMessages,
        onThreadMessageComposed,
        toggleDetailView,
      ],
    )

    const [chatList] = useState([])

    const attendeeContextStore = useMemo(() => {
      return {
        attendees,
      }
    }, [attendees])

    const backToList = useCallback(() => {
      setState({ item: {}, type: '' })
      setShowAttendee(false)
    }, [])

    const defaultAvatar = useMemo(() => {
      if (state.item.attendeeName) {
        return SvgAvatar.getAvatar(
          state.item.attendeeName,
          state.item.attendeeName.substring(0, 2),
        )
      }
    }, [state.item.attendeeName])

    return {
      loading,
      attendees,
      centerPanelClassName,
      onItemClicked,
      state,
      wrapperClassName,
      actionHandler,
      user,
      chatList,
      attendeeContextStore,
      companyOptions,
      coachOptions,
      articleTemplateOptions,
      cycleOptions,
      seasonOptions,
      teamOptions,
      cycleStateOptions,
      currentRole,
      // handleExpanded,
      // expanded,
      backToList,
      toggleMoreDetail,
      isShowAttendee,
      defaultAvatar,
      currentPage,
    }
  }),
)

export default enhancer(ChatPage)
