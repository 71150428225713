import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Modal, PageLayout, Table, Notification } from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import { clone, filter, get, map } from 'lodash'
import RejectMissionModal from './RejectMissionModal'
import paths from '@common/paths'
import { css } from '@styled'
import MissionInfoModal from './MissionInfoModal'
import { cleanFilter } from '@common/helper'
import { useQuery } from '@hooks'
import { isEqual, compact } from 'lodash'
import { avatarStyle } from '@pages/companies/index/index'

const columnClass = css`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const pageStyle = css`
  .action-on-desktop {
    display: block !important;
  }
  .action-on-mobile {
    display: none !important;
  }

  @media screen and (max-width: 960px) {
    .action-on-desktop {
      display: none !important;
    }
    .action-on-mobile {
      display: block !important;
    }
  }
`

const MissionIndexPage = (props) => (
  <PageLayout
    className={pageStyle}
    title="รายการภารกิจ"
    actions={[
      {
        label: 'เพิ่มภารกิจ',
        icon: 'plus',
        color: 'primary',
        onClick: props.onCreate,
      },
    ]}>
    <Table
      $ref={props.$table}
      filters={[
        {
          description: 'กรองด้วยองค์กร',
          dataField: 'companyId',
          type: 'select',
          options: props.companyOptions,
        },
        {
          description: 'กรองด้วยฤดูกาลแข่ง',
          dataField: 'seasonId',
          type: 'select',
          options: props.seasonOptions,
        },
        {
          description: 'กรองด้วยรอบแข่งที่',
          dataField: 'cycleId',
          type: 'select',
          options: props.cycleOptions,
        },
        {
          description: 'กรองด้วยทีม',
          dataField: 'teamId',
          type: 'select',
          options: props.teamOptions,
        },
        {
          description: 'กรองด้วยผู้แข่งขัน',
          dataField: 'attendee',
          type: 'search',
          className: 'pl-lg-4 pl-md-4',
        },
        {
          description: 'กรองด้วยชื่อภารกิจ',
          dataField: 'title',
          type: 'search',
        },
        {
          description: 'กรองด้วยสถานะการอ่าน',
          dataField: 'state',
          type: 'select',
          options: props.stateOptions,
          selectProps: { disableClearable: true },
        },
      ]}
      groupActions={[
        {
          label: 'อนุญาตให้ส่งภารกิจได้ภายในวันนี้ (กรณีพิเศษ)',
          icon: 'paper-plane',
          hoverColor: 'primary',
          onClick: props.addPermissionForSendMissionToday,
        },
        {
          label: 'ยกเลิกการส่งภารกิจ (กรณีพิเศษ)',
          icon: 'backspace',
          hoverColor: 'danger',
          onClick: props.removePermissionForSendMissionToday,
        },
      ]}
      columns={[
        {
          title: '',
          dataField: 'special-actions',
          type: 'actions',
          actions: [
            {
              label: 'ตรวจ',
              icon: 'check-circle',
              hoverColor: 'primary',
              onClick: props.$missionInfoModal.current?.open,
              hidden: (mission) => !(mission.canApprove || mission.canReject),
            },
            {
              label: 'ดูสถานะ',
              icon: 'eye',
              hoverColor: 'primary',
              onClick: props.$missionInfoModal.current?.open,
              hidden: (mission) =>
                !(mission.state === 'ผ่านแล้ว' || mission.state === 'ไม่ผ่าน'),
            },
          ],
        },
        {
          title: 'ชื่อภารกิจ',
          dataField: 'title',
          className: columnClass,
          type: 'link',
          align: 'left',
          onClick: props.gotoMissionPage,
        },
        {
          title: 'วันที่มอบหมาย',
          dataField: 'date',
          type: 'date',
          className: columnClass,
        },
        {
          title: 'ผู้เข้าร่วม',
          dataField: 'attendeeName',
          align: 'left',
          className: columnClass,
        },
        {
          className: avatarStyle,
          title: 'บริษัท',
          dataField: 'companyLogoUrl',
          type: 'avatar',
          nameKey: 'companyLogoUrl',
        },
        {
          title: 'ชื่อโครงการ',
          dataField: 'seasonName',
        },
        {
          title: 'แก้ไขโดย',
          dataField: 'editorName',
          className: columnClass,
          hide: !props.isAdmin,
        },
        {
          title: 'แก้ไขล่าสุด',
          dataField: 'updatedAt',
          type: 'date',
          hide: !props.isAdmin,
        },
        {
          title: 'ผู้รับผิดชอบ',
          dataField: 'coachName',
          hide: !props.isAdmin,
          className: columnClass,
        },
        {
          title: 'สถานะ',
          dataField: 'state',
          className: columnClass,
        },
        {
          title: 'วันที่อนุญาตให้ส่งภารกิจได้กรณ๊พิเศษ',
          type: 'date',
          dataField: 'specialAllowedDate',
        },
        {
          title: 'การดำเนินการ',
          type: 'actions',
          actions: [
            {
              label: 'Edit',
              icon: 'pen',
              hoverColor: 'primary',
              onClick: props.onEdit,
              visible: (mission) => mission.canEdit,
            },
            {
              label: 'Delete',
              icon: 'trash',
              hoverColor: 'danger',
              onClick: props.onDelete,
              visible: (mission) => mission.canDelete,
            },
          ],
        },
      ]}
      entities={props.missions}
      paging={props.paging}
      onQuery={props.onQuery}
      selectable
    />
    <MissionInfoModal $ref={props.$missionInfoModal} />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    configs: stores.missionStore.configs,
    missions: stores.missionStore.missions,
    fetchMissions: stores.missionStore.fetchMissions,
    fetchConfigs: stores.missionStore.fetchConfigs,
    paging: stores.missionStore.paging,
    deleteMission: stores.missionStore.deleteMission,
    approveMission: stores.missionStore.approveMission,
    currentRole: stores.authStore.currentRole,
    updateSpecialAllowedSendingDateToMissions:
      stores.missionStore.updateSpecialAllowedSendingDateToMissions,
  })),
  withHooks((props) => {
    const {
      missions,
      deleteMission,
      fetchMissions,
      paging,
      fetchConfigs,
      configs,
      approveMission,
      currentRole,
      updateSpecialAllowedSendingDateToMissions,
    } = props

    useEffect(() => {
      fetchConfigs({ cycleActive: false })
    }, [fetchConfigs])
    const $table = useRef()

    const [queryParams, setQueryParams] = useState({})

    const categoryOptions = get(configs, 'filters.category.options')
    const stateOptions = get(configs, 'filters.state.options') || [
      { label: 'ทั้งหมด', value: 'all' },
      { label: 'ยังไม่ได้ส่ง', value: 'new' },
      { label: 'รอโค้ชตรวจ', value: 'pending' },
      { label: 'โค้ชให้ผ่าน', value: 'approved' },
      { label: 'โค้ชไม่ให้ผ่าน', value: 'rejected' },
    ]
    const { companyId, seasonId, cycleId } = queryParams

    const companyOptions = useMemo(() => {
      const options = get(configs, 'filters.companyId.options', [])
      return options
    }, [configs])

    const seasonOptions = useMemo(() => {
      const options = get(configs, 'filters.seasonId.options', [])
      return filter(options, { companyId })
    }, [configs, companyId])

    const cycleOptions = useMemo(() => {
      const options = get(configs, 'filters.cycleId.options', [])
      return filter(options, { seasonId })
    }, [configs, seasonId])

    const teamOptions = useMemo(() => {
      const options = get(configs, 'filters.teamId.options', [])
      return filter(options, { cycleId })
    }, [configs, cycleId])

    const onQuery = setQueryParams

    useEffect(() => {
      if (companyOptions.length === 0) {
        return
      }

      const filteredParams = clone(queryParams)

      cleanFilter(filteredParams, 'companyId', companyOptions)
      cleanFilter(filteredParams, 'seasonId', seasonOptions)
      cleanFilter(filteredParams, 'cycleId', cycleOptions)
      cleanFilter(filteredParams, 'teamId', teamOptions)

      fetchMissions(filteredParams)

      if (!isEqual(filteredParams, queryParams)) {
        setQueryParams(filteredParams)
      }
    }, [
      queryParams,
      fetchMissions,
      companyOptions,
      seasonOptions,
      cycleOptions,
      teamOptions,
    ])

    const q = useQuery()
    useEffect(() => {
      $table.current.setFilter({
        companyId: q.companyId,
        seasonId: q.seasonId,
        cycleId: q.cycleId,
        teamId: q.teamId,
        attendee: q.attendeeName,
        state: 'pending',
      })
    }, [q.companyId, q.seasonId, q.cycleId, q.teamId, q.attendeeName])

    const onCreate = useCallback(() => {
      paths.missionNewPath().push()
    }, [])

    const onDelete = useCallback(
      async (mission) => {
        Modal.confirm({
          children: 'Permanent delete this Mission.',
          onOk: async (modal) => {
            await deleteMission(mission.id)
            Notification.success()
            modal.close()
          },
        })
      },
      [deleteMission],
    )

    const onApprove = useCallback(
      async (mission) => {
        await approveMission(mission.id)
        Notification.success()
      },
      [approveMission],
    )

    const onReject = useCallback(async (mission) => {
      Modal.confirm({
        title: 'Are you reject this Mission?',
        hideFooter: true,
        children: <RejectMissionModal missionIds={[mission.id]} />,
      })
    }, [])

    const onEdit = useCallback((mission) => {
      paths.missionEditPath(mission.id).push()
    }, [])

    const gotoMissionPage = useCallback((mission) => {
      paths.missionPath(mission.id).push()
    }, [])

    const isAdmin = useMemo(() => {
      return currentRole === 'admin'
    }, [currentRole])

    const $missionInfoModal = useRef()

    const openUpdateSpecialAllowedSendingDateConfirmModal = useCallback(
      (message, missions, date) => {
        Modal.confirm({
          children: message,
          onOk: async (modal) => {
            await updateSpecialAllowedSendingDateToMissions({
              missionIds: map(compact(missions), 'id'),
              date,
            })
            Notification.success()
            modal.close()
          },
        })
      },
      [updateSpecialAllowedSendingDateToMissions],
    )

    const addPermissionForSendMissionToday = useCallback(
      (missions) => {
        openUpdateSpecialAllowedSendingDateConfirmModal(
          'คุณต้องการอนุญาติให้ misison เหล่านี้ส่งได้อีกครั้งใช่มั้ย?',
          missions,
          new Date(),
        )
      },
      [openUpdateSpecialAllowedSendingDateConfirmModal],
    )

    const removePermissionForSendMissionToday = useCallback(
      (missions) => {
        openUpdateSpecialAllowedSendingDateConfirmModal(
          'คุณต้อง ยกเลิก การอนุญาติให้ misison เหล่านี้ส่งได้อีกครั้งใช่มั้ย?',
          missions,
          null,
        )
      },
      [openUpdateSpecialAllowedSendingDateConfirmModal],
    )

    return {
      $table,
      missions,
      onQuery,
      onCreate,
      onDelete,
      onApprove,
      onReject,
      onEdit,
      paging,
      companyOptions,
      seasonOptions,
      cycleOptions,
      teamOptions,
      categoryOptions,
      stateOptions,
      gotoMissionPage,
      isAdmin,
      $missionInfoModal,
      addPermissionForSendMissionToday,
      removePermissionForSendMissionToday,
    }
  }),
)

export default enhancer(MissionIndexPage)
