import React, { useCallback } from 'react'
import { compose, withHooks, withStores } from '@enhancers'
import { Grid, Button } from '@components'
import DialogActions from '@material-ui/core/DialogActions'
import { css } from '@styled'

const saveButton = css`
  padding: 8px 16px;
  margin-left: 8px;
`

const PreventForgotSaveModal = (props) => (
  <>
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <div>
          ข้อมูลสุขภาพที่แก้ไขยังไม่ได้บันทึก
          ต้องการบันทึกก่อนดำเนินการต่อหรือไม่
        </div>
      </Grid>
    </Grid>
    <DialogActions style={{ paddingTop: 24 }}>
      <Button onClick={props.onCancel} color="light" size="small">
        ดำเนินการต่อโดยไม่บันทึก
      </Button>
      <Button
        className={saveButton}
        onClick={props.onOk}
        size="small"
        color="primary">
        <i className="fas fa-save" />
        บันทึก
      </Button>
    </DialogActions>
  </>
)

const enhancer = compose(
  withStores((stores) => ({
    closeConfirmModal: stores.appStore.closeConfirmModal,
  })),
  withHooks((props) => {
    const {
      closeConfirmModal,
      onContinueWithSave,
      onContinueWithoutSave,
    } = props

    const onOk = useCallback(async () => {
      if (onContinueWithSave) {
        await onContinueWithSave()
      }
      closeConfirmModal()
    }, [closeConfirmModal, onContinueWithSave])

    const onCancel = useCallback(async () => {
      if (onContinueWithoutSave) {
        await onContinueWithoutSave()
      }
      closeConfirmModal()
    }, [closeConfirmModal, onContinueWithoutSave])

    return {
      onCancel,
      onOk,
    }
  }),
)

export default enhancer(PreventForgotSaveModal)
