import React from 'react'
import { TextField } from 'formik-material-ui'
import InputAdornment from '@material-ui/core/InputAdornment'
import { withHooks } from '@enhancers'
import { css } from '@styled'
import classnames from 'classnames'
import { set } from 'lodash'

// https://material-ui.com/components/text-fields/

export const formikCalibration = (props) => {
  if (props.form) {
    const name = props.field.name
    if (props.form.submitCount) {
      set(props.form.touched, name, true)
    }

    const error = props.form.getFieldMeta(name).error
    if (error) {
      set(props.form.errors, name, error.replace(name, props.label || ''))
    }
  }
}

const suggestMaxLengthStyle = css`
  > .MuiFormHelperText-root {
    color: #ffb74d;
  }
`

export const DEFAULT_PROPS = {
  size: 'small',
  variant: 'outlined',
  InputLabelProps: {
    shrink: true,
  },
  fullWidth: true,
}

export const enhancer = withHooks((props) => {
  formikCalibration(props)

  const {
    helperText,
    suggestMaxLength,
    className,
    InputProps = {},
    unit,
    ...rest
  } = {
    ...DEFAULT_PROPS,
    ...props,
  }

  if (unit) {
    InputProps.endAdornment = (
      <InputAdornment position="end">{unit}</InputAdornment>
    )
  }

  if (rest.field?.value === null || rest.field?.value === undefined) {
    rest.field.value = ''
  }

  const value = props.field?.value || props.value || ''
  const customHelperText = suggestMaxLength
    ? `${helperText} (จำนวนตัวอักษรที่แนะนำ ${value.length}/${suggestMaxLength})`
    : helperText

  const customClassName = classnames(className, {
    [suggestMaxLengthStyle]:
      suggestMaxLength && value.length > suggestMaxLength,
  })

  return {
    ...rest,
    InputProps,
    helperText: customHelperText,
    className: customClassName,
  }
})

export default enhancer(TextField)
