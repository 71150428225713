import { useCallback, useEffect } from 'react'
import { Notification } from '@components'
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  defaultProps,
} from '@enhancers'
import paths from '@common/paths'
import { times } from 'lodash'
import {
  MissionTemplatePage,
  mapValuesToParams,
  transformErrorsWithParams,
} from '../show'
import { missionTemplateSchema } from '../show/missionTemplateValidator'

const enhancer = compose(
  defaultProps({
    dayAmount: 28,
  }),
  withStores((stores) => ({
    configs: stores.missionTemplateStore.configs,
    createMissionTemplate: stores.missionTemplateStore.createMissionTemplate,
    fetchConfigs: stores.missionTemplateStore.fetchConfigs,
  })),
  withFormik({
    mapPropsToValues: (props) => {
      return {
        dailyMissionTemplates: times(props.dayAmount, (index) => ({
          dayNo: index + 1,
        })),
      }
    },
    validationSchema: missionTemplateSchema,
    handleSubmit: async (values, formikBag) => {
      const { ...params } = mapValuesToParams(values)

      try {
        const response = await formikBag.props.createMissionTemplate(params)
        Notification.success()

        const missionTemplateId = response.data.data.missionTemplate.id
        paths.missionTemplateEditPath(missionTemplateId).push()
      } catch (e) {
        throw transformErrorsWithParams(e, params)
      }
    },
  }),
  withHooks((props) => {
    const {
      handleReset,
      handleSubmit,
      fetchConfigs,
      configs,
      dayAmount,
    } = props

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    const onBack = useCallback(() => {
      paths.missionTemplatesPath().push()
    }, [])

    const title = 'เพิ่มต้นแบบภารกิจ'
    const tagOptions = configs?.attributes?.tag?.options || []
    const categoryOptions =
      configs?.attributes?.missionTemplateCategory?.options || []
    const answerTypeOptions = configs?.attributes?.category?.options || []
    const articleTemplateOptions =
      configs?.attributes?.articleTemplate?.options || []

    return {
      onBack,
      onReset: handleReset,
      onSave: handleSubmit,
      dayAmount,
      title,
      tagOptions,
      categoryOptions,
      answerTypeOptions,
      articleTemplateOptions,
    }
  }),
)

export default enhancer(MissionTemplatePage)
