import React from 'react'
import { compose, withFormik, withHooks } from '@enhancers'
import { Grid, Field, Input, Button } from '@components'
import { sleep } from '@common/helper'

const ExampleModal = (props) => (
  <Grid container direction="column">
    <Grid container item lg={4} md={5} sm={6} xs={12}>
      <Field name="email" component={Input} label="Email" />
    </Grid>
    <Grid container item xs={12} justify="flex-end">
      <Button
        type="submit"
        onClick={props.handleSubmit}
        color="primary"
        size="small">
        SAVE
      </Button>
    </Grid>
  </Grid>
)

const enhancer = compose(
  withFormik({
    handleSubmit: async (values, formikBag) => {
      console.log('values', values, formikBag)
      await sleep(2000)
    },
  }),
  withHooks((props) => {
    const { handleSubmit } = props
    return {
      handleSubmit,
    }
  }),
)

export default enhancer(ExampleModal)
