import { compose, withHooks } from '@enhancers'
import { useFormikField } from '@hooks'
import { DatePicker } from '@material-ui/pickers'
import { css } from '@styled'
import classnames from 'classnames'

const datePickerStyle = css`
  input {
    cursor: pointer;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;

    .MuiIconButton-root {
      padding: 14px;
    }
  }
`

const DEFAULT_PROPS = {
  size: 'small',
  inputVariant: 'outlined',
  format: 'dd-MM-yyyy',
  InputLabelProps: {
    shrink: true,
  },
  fullWidth: true,
  clearable: true,
  autoOk: true,
}

export const enhancer = compose(
  withHooks((props) => {
    const {
      className,
      value = null,
      error,
      helperText,
      ...rest
    } = useFormikField({
      ...DEFAULT_PROPS,
      ...props,
    })

    const customClassName = classnames(datePickerStyle, {
      [className]: className,
    })

    return {
      ...rest,
      className: customClassName,
      value,
      error: !!error,
      helperText: error || helperText,
    }
  }),
)

export default enhancer(DatePicker)
