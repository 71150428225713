import React from 'react'
import { compose, withHooks } from '@enhancers'
import { Grid, Field, Image, Input, Select } from '@components'

const MissionTemplateInfo = (props) => (
  <Grid container spacing={2} className={props.className}>
    <Grid item container justify="center">
      <Field
        name="coverImage"
        src={props.coverImageUrl}
        component={Image}
        variant="rounded"
        size={400}
        ratio="16:9"
      />
    </Grid>
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <Field
        label="ชื่อภารกิจต้นแบบ"
        name="title"
        component={Input}
        placeholder="ชื่อภารกิจต้นแบบ"
        required
        suggestMaxLength={70}
        helperText="โปรดระบุชื่อภารกิจต้นแบบ"
      />
    </Grid>
    <Grid item lg={6} md={6} sm={6} xs={12}>
      <Field
        label="แท็กต้นแบบ"
        name="tags"
        component={Select}
        options={props.tagOptions}
        multiple
        placeholder="แท็กต้นแบบ"
        helperText="โปรดเลือกแท็กต้นแบบ"
      />
    </Grid>
    <Grid item lg={6} md={6} sm={6} xs={12}>
      <Field
        label="เงื่อนไขการเลือกภารกิจ"
        name="category"
        component={Select}
        options={props.categoryOptions}
        placeholder="หมวดหมู่"
        helperText="ใช้ในการตรวจสอบว่าควรเลือกภารกิจนี้หรือไม่ตามข้อมูลของผู้เข้าแข่งขัน"
        required
      />
    </Grid>

    <Grid item lg={6} md={6} sm={6} xs={12}>
      <Field
        label="คำอธิบายต้นแบบ"
        name="description"
        component={Input}
        placeholder="คำอธิบายต้นแบบ"
        required
        type="text"
        multiline
        rows={4}
        suggestMaxLength={140}
        helperText="โปรดระบุคำอธิบายต้นแบบ"
      />
    </Grid>
    <Grid item lg={6} md={6} sm={6} xs={12}>
      <Field
        label="กฏต้นแบบ"
        name="rules"
        component={Input}
        placeholder="กฏต้นแบบ"
        required
        type="text"
        multiline
        rows={4}
        suggestMaxLength={140}
        helperText="โปรดระบุกฏต้นแบบ"
      />
    </Grid>
    <Grid item lg={6} md={6} sm={6} xs={12}>
      <Field
        label="เงื่อนไขต้นแบบ"
        name="condition"
        component={Input}
        placeholder="เงื่อนไขต้นแบบ"
        required
        type="text"
        multiline
        rows={4}
        suggestMaxLength={140}
        helperText="โปรดระบุเงื่อนไขต้นแบบ"
      />
    </Grid>
    <Grid item lg={6} md={6} sm={6} xs={12}>
      <Field
        label="รางวัลต้นแบบ"
        name="reward"
        component={Input}
        placeholder="รางวัลต้นแบบ"
        required
        multiline
        rows={4}
        suggestMaxLength={140}
        helperText="โปรดระบุรางวัลต้นแบบ"
      />
    </Grid>
  </Grid>
)

const enhancer = compose(
  withHooks((props) => {
    const { coverImageUrl, tagOptions, categoryOptions, className } = props
    return { coverImageUrl, tagOptions, categoryOptions, className }
  }),
)

export default enhancer(MissionTemplateInfo)
