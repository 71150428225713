import React, { useCallback } from 'react'
import { Field, Input, PageLayout, Grid, Notification } from '@components'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import * as yup from 'yup'

const AdminNewPage = (props) => (
  <PageLayout
    title="เพิ่มผู้ดูแลระบบ"
    actions={[
      {
        label: 'กลับ',
        icon: 'arrow-left',
        onClick: props.onBack,
      },
      {
        label: 'ล้างค่าที่กรอก',
        icon: 'redo',
        onClick: props.onReset,
      },
      {
        label: 'บันทึก',
        icon: 'save',
        color: 'primary',
        onClick: props.onSave,
        type: 'submit',
      },
    ]}>
    <Grid container spacing={3}>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="อีเมล"
          name="email"
          component={Input}
          placeholder="อีเมล"
          required
          helperText="โปรดใส่อีเมลเฉพาะ"
        />
      </Grid>
    </Grid>
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    createAdmin: stores.adminStore.createAdmin,
  })),
  withFormik({
    validationSchema: yup.object().shape({
      email: yup.string().email().required(),
    }),
    handleSubmit: async (values, formikBag) => {
      await formikBag.props.createAdmin(values)
      Notification.success()
      paths.adminsPath().push()
    },
  }),
  withHooks((props) => {
    const { handleReset, handleSubmit } = props

    const onBack = useCallback(() => {
      paths.adminsPath().push()
    }, [])

    return {
      onBack,
      onReset: handleReset,
      onSave: handleSubmit,
    }
  }),
)

export default enhancer(AdminNewPage)
