import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { withHooks } from '@enhancers'

export const history = createBrowserHistory()

const enhancer = withHooks(props => {
  return {
    history,
    children: props.children,
  }
})

export default enhancer(Router)
