import React, { useMemo } from 'react'
import { compose, withHooks, withStores } from '@enhancers'
import {
  Grid,
  Typography,
  FormControlLabel,
  FormControl,
  Icon,
  ImageLightbox,
  Input,
  Field,
} from '@components'
import { get, find } from 'lodash'
import styled from '@styled'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

const Layout = styled(Grid)`
  background-color: #444;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  width: 300px;
  overflow: hidden;

  .MuiIconButton-label {
    color: black !important;
  }

  .MuiFormControlLabel-label {
    white-space: pre-wrap !important;
    width: 230px !important;
    color: black !important;
  }

  .Mui-checked .MuiIconButton-label,
  .Mui-checked + .MuiFormControlLabel-label {
    color: #1bc5bd !important;
  }
`

const Image = styled.img`
  margin: auto;
`

const StateLayout = styled.div`
  background-color: ${(props) => props.color?.background};
  height: 29px;

  p:first-of-type {
    color: ${(props) => props.color?.text};
    line-height: 29px;
    text-align: center;
    font-family: Prompt;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
  }
`

const StateRemarkLayout = styled.div`
  background-color: #ffffff;
  height: 66px;
  padding: 8px;

  div,
  p {
    color: #65564f;
    text-align: flex-start;
  }
`

const Caption = (props) => (
  <Typography style={{ marginBottom: 6 }}>
    <b>
      <u>{props.children}</u>
    </b>
  </Typography>
)

const MissionInfo = (props) => (
  <Layout container spacing={2} direction="column">
    <Grid
      item
      align="left"
      container
      direction="column"
      hidden={!props.isShowState}>
      <StateLayout color={props.stateColor}>
        <Typography>{props.state}</Typography>
      </StateLayout>
      {props.state === 'ไม่ผ่าน' && (
        <StateRemarkLayout>
          <Caption>สาเหตุ:</Caption>
          <Typography>{props.remark}</Typography>
        </StateRemarkLayout>
      )}
    </Grid>
    <Grid item align="left" container direction="column">
      <Caption>รายละเอียด:</Caption>
      <Typography>{props.description}</Typography>
    </Grid>
    <Grid item align="left" container direction="column">
      <Caption>กฏ:</Caption>
      <Typography>{props.rules}</Typography>
    </Grid>
    <Grid
      item
      align="left"
      container
      direction="column"
      hidden={props.answerType !== 'choices'}>
      <Caption>คำถาม:</Caption>
      <Typography>{props.missionQuestion}</Typography>
    </Grid>
    <Grid
      item
      align="left"
      container
      direction="column"
      hidden={!props.answerType}>
      {(() => {
        switch (props.answerType) {
          case 'pacer':
            return (
              <>
                <Caption>คำตอบ:</Caption>
                {props.answerImageUrl ? (
                  <>
                    <Image
                      alt={props.answerImageUrl}
                      src={props.answerImageUrl}
                      style={{ maxWidth: '100%' }}
                      onClick={() => {
                        ImageLightbox.open({ image: props.answerImageUrl })
                      }}
                    />
                    {!props.isShowState ? (
                      <Field
                        className="mt-5"
                        name="pacer"
                        component={Input}
                        type="number"
                        label="ก้าวเดิน"
                        helperText="กรุณาระบุก้าวเดินที่ผู้เล่นส่งมา"
                      />
                    ) : (
                      <Typography style={{ color: '#1BC5BD' }}>
                        {props.answer}
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography style={{ color: '#1BC5BD' }}>
                    {props.answer}
                  </Typography>
                )}
              </>
            )
          case 'image':
            return (
              <>
                <Caption>คำตอบ:</Caption>
                <Image
                  alt={props.answerImageUrl}
                  src={props.answerImageUrl}
                  style={{
                    cursor: 'pointer',
                    borderRadius: 4,
                    maxWidth: '100%',
                  }}
                  onClick={() => {
                    ImageLightbox.open({ image: props.answerImageUrl })
                  }}
                />
              </>
            )
          case 'choices':
            return (
              <>
                <Caption>ตัวเลือก:</Caption>
                <FormControl component="fieldset">
                  <RadioGroup value={props.answer}>
                    {props.customRatio.map((choice, index) => (
                      <div className="d-flex align-items-center" key={index}>
                        {props.missionAnswers[index] && (
                          <Icon
                            name="check"
                            className="position-absolute text-warning mb-2"
                            style={{ fontSize: 11, left: -6 }}
                          />
                        )}
                        <FormControlLabel
                          className="choice-answer"
                          value={choice.value}
                          control={
                            <Radio
                              className="py-0"
                              size="small"
                              style={{ marginLeft: 12 }}
                            />
                          }
                          label={choice.label}
                          disabled
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
              </>
            )
          default:
            return <></>
        }
      })()}
    </Grid>
    <Grid
      item
      align="left"
      container
      direction="column"
      hidden={!props.answerRemark}>
      <Caption>หมายเหตุ:</Caption>
      <Typography style={{ color: '#1BC5BD' }}>{props.answerRemark}</Typography>
    </Grid>
    <Grid
      item
      align="left"
      container
      direction="column"
      hidden={!props.healthScoreReward}>
      <Caption>คะแนนที่ได้:</Caption>
      <Typography>{props.healthScoreReward} คะแนน</Typography>
    </Grid>
  </Layout>
)

const enhancer = compose(
  withStores((stores) => ({
    configs: stores.missionStore.configs,
  })),
  withHooks((props) => {
    const { row, configs } = props
    const answerTypeOptions = get(configs, 'filters.answerType.options', [])
    const answerType = get(row, 'answerTemplate.type', '')
    const answerRemark = get(row, 'answer.remark', null)
    const missionQuestion = get(row, 'answerTemplate.question', null)
    const answer =
      answerType === 'pacer'
        ? `${get(row, 'answer.pace', 0)} ก้าว`
        : get(row, 'answer.message', '')

    const missionAnswers = row?.answerTemplate?.answers || []
    const missionChoices = get(row, 'answerTemplate.choices', null)
    const isShowState =
      get(row, 'state', null) === 'ผ่านแล้ว' ||
      get(row, 'state', null) === 'ไม่ผ่าน'

    const customRatio = useMemo(() => {
      if (missionChoices) {
        return missionChoices.map((choice) => ({
          label: choice,
          value: choice,
          checked: answer === choice,
        }))
      }
    }, [missionChoices, answer])

    const answerTypeLabel = find(answerTypeOptions, {
      value: answerType,
    })?.label

    const stateColor = useMemo(() => {
      if (row.state === 'ผ่านแล้ว') {
        return {
          text: '#1CC0B8',
          background: '#E9F7F8',
        }
      } else {
        return {
          text: '#F30505',
          background: '#FFEEF3',
        }
      }
    }, [row.state])

    return {
      ...row,
      missionAnswers,
      answerTypeLabel,
      answerType,
      answer,
      answerRemark,
      missionQuestion,
      customRatio,
      isShowState,
      stateColor,
    }
  }),
)

export default enhancer(MissionInfo)
