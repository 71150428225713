import { compose, withHooks } from '@enhancers'
import Button from './Button'

const enhancer = compose(
  withHooks(({ className, style, onClick, to, children, wrap }) => {
    return {
      className,
      style,
      variant: 'link',
      onClick,
      to,
      children,
      noPadding: true,
      wrap,
    }
  }),
)

export default enhancer(Button)
