import React, { useCallback } from 'react'
import paths from '@common/paths'
import { Modal, Notification, PageLayout, Table } from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import { css } from '@styled'

export const avatarStyle = css`
  .MuiAvatar-root.MuiAvatar-circle {
    border-radius: 4px !important;
  }
`

const CompanyIndexPage = (props) => (
  <PageLayout
    title="องค์กร"
    actions={[
      {
        label: 'เพิ่มองค์กร',
        icon: 'plus',
        color: 'primary',
        onClick: props.onCreate,
      },
    ]}>
    <Table
      columns={[
        {
          className: avatarStyle,
          title: 'สัญลักษณ์',
          dataField: 'logoUrl',
          type: 'avatar',
          nameKey: 'name',
          onClick: props.gotoCompanyPage,
        },
        {
          title: 'ชื่อองค์กร',
          dataField: 'name',
          sort: true,
          type: 'link',
          align: 'left',
          onClick: props.gotoCompanyPage,
        },
        {
          title: 'จำนวน HR',
          dataField: 'humanResourceAmount',
        },
        {
          title: 'จำนวนพนักงาน',
          dataField: 'employeeAmount',
        },
        {
          title: 'จำนวนฤดูกาลแข่ง',
          dataField: 'seasonAmount',
        },
        {
          title: 'เริ่มใช้งานระบบ',
          dataField: 'subscriptionStartedAt',
          sort: true,
          type: 'date',
        },
        {
          title: 'ใช้งานระบบได้ถึง',
          dataField: 'subscriptionEndedAt',
          sort: true,
          type: 'date',
        },
        {
          title: 'การดำเนินการ',
          type: 'actions',
          actions: [
            {
              label: 'แก้ไข',
              icon: 'pen',
              hoverColor: 'primary',
              onClick: props.onEdit,
            },
            {
              label: 'ลบ',
              icon: 'trash',
              hoverColor: 'danger',
              onClick: props.onDelete,
              visible: (company) => company.canDelete,
            },
          ],
        },
      ]}
      entities={props.companies}
      paging={props.paging}
      onQuery={props.fetchCompanies}
      onEdit={props.onEdit}
      onDelete={props.onDelete}
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    fetchCompanies: stores.companyStore.fetchCompanies,
    deleteCompany: stores.companyStore.deleteCompany,
    companies: stores.companyStore.companies,
    paging: stores.companyStore.paging,
  })),
  withHooks((props) => {
    const { fetchCompanies, deleteCompany, companies, paging } = props

    const onCreate = useCallback(() => {
      paths.companiesNewPath().push()
    }, [])

    const onEdit = useCallback((company) => {
      paths.companyEditPath(company.id).push()
    }, [])

    const gotoCompanyPage = useCallback((company) => {
      paths.companyPath(company.id).dashboardPath().push()
    }, [])

    const onDelete = useCallback(
      async (company) => {
        Modal.confirm({
          children: 'This company will removed permanently.',
          onOk: async (modal) => {
            await deleteCompany(company.id)
            Notification.success()
            modal.close()
          },
        })
      },
      [deleteCompany],
    )

    return {
      companies,
      paging,
      fetchCompanies,
      onCreate,
      onEdit,
      gotoCompanyPage,
      onDelete,
    }
  }),
)

export default enhancer(CompanyIndexPage)
