import './wdyr'

import '@common/firebase'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import theme from '@common/theme'
import {
  BrowserRouter,
  Modal,
  Notification,
  ExcelGenerator,
  ImageLightbox,
} from '@components'
import { SnackbarProvider } from 'notistack'
import { PersistGate } from 'redux-persist/integration/react'
import { MetronicI18nProvider, I18nProvider } from './_metronic/i18n'
import DateFnsAdapter from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
  LayoutSplashScreen,
  MaterialThemeProvider,
} from './_metronic/layout'

import './index.scss'
import './_metronic/_assets/plugins/keenthemes-icons/font/ki.css'
import 'socicon/css/socicon.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '_metronic/_assets/plugins/flaticon/flaticon.css'
import './_metronic/_assets/plugins/flaticon2/flaticon.css'
import 'react-datepicker/dist/react-datepicker.css'

import '@common/yup.locale.th'

import store, { persistor } from '@stores'
import Pages from '@pages/index'

ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          {/* Start App Provider Section */}
          <Provider store={store}>
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
              <React.Suspense fallback={<LayoutSplashScreen />}>
                <BrowserRouter>
                  <MaterialThemeProvider theme={theme}>
                    <I18nProvider>
                      <SnackbarProvider maxSnack={1}>
                        <MuiPickersUtilsProvider utils={DateFnsAdapter}>
                          <Pages />
                          <Modal storeName="appStore.mainModal" />
                          <Modal storeName="appStore.alertModal" />
                          <Modal storeName="appStore.confirmModal" />
                          <ImageLightbox storeName="appStore.imageLightbox" />
                          <Notification />
                          <ExcelGenerator storeName="appStore.excelGenerator" />
                        </MuiPickersUtilsProvider>
                      </SnackbarProvider>
                    </I18nProvider>
                  </MaterialThemeProvider>
                </BrowserRouter>
              </React.Suspense>
            </PersistGate>
          </Provider>
          {/* End App Provider Section */}
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById('root'),
)
