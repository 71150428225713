import { combineReducers } from 'redux'
import {
  createSlice,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import deepmerge from 'deepmerge'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { get } from 'lodash'

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

const dataSlice = createSlice({
  name: 'data',
  initialState: {},
  reducers: {
    mergeStoreData: (state, action) => {
      const { storeName, storeData } = action.payload
      state[storeName] = deepmerge(state[storeName] || {}, storeData || {}, {
        arrayMerge: overwriteMerge,
        clone: false,
      })
    },
  },
})

const store = configureStore({
  reducer: persistReducer(
    {
      key: 'root',
      version: 1,
      storage,
      stateReconciler: autoMergeLevel2,
      blacklist: ['data'],
    },
    combineReducers({
      data: dataSlice.reducer,
    }),
  ),
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH,
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER,
        'data/mergeStoreData',
      ],
    },
  }),
})

export const persistor = persistStore(store, null, () => {
  store.dispatch(
    dataSlice.actions.mergeStoreData({
      storeName: 'appStore',
      storeData: {
        initialized: true,
      },
    }),
  )
})

export class BaseStore {
  constructor(storeName, initialStoreData = {}) {
    this.storeName = storeName
    this.setState(initialStoreData)
  }

  get state() {
    return store.getState().data[this.storeName]
  }

  setState(storeData) {
    store.dispatch(
      dataSlice.actions.mergeStoreData({
        storeName: this.storeName,
        storeData: storeData,
      }),
    )
  }

  $get(selector, defaultValue) {
    return get(this, selector, defaultValue)
  }
}

export default store
