import React, { useCallback, useEffect, useMemo } from 'react'
import {
  Grid,
  Field,
  PageLayout,
  Input,
  Notification,
  DatePicker,
  Typography,
} from '@components'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import * as yup from 'yup'
import { css } from '@styled'
import { useParams } from 'react-router-dom'
import History from '../show/History'
import { omit } from 'lodash'
import { format } from 'date-fns'

const titleText = css`
  padding-bottom: 20px;
`
const secondaryTitleText = css`
  padding: 40px 0 20px 0;
`

export const EmployeeEditPage = (props) => (
  <PageLayout
    title={props.title}
    actions={[
      {
        label: 'กลับ',
        icon: 'arrow-left',
        onClick: props.goBack,
      },
      {
        label: 'ล้างค่าที่กรอก',
        icon: 'redo',
        onClick: props.reset,
      },
      {
        label: 'บันทึก',
        icon: 'save',
        color: 'primary',
        onClick: props.save,
        type: 'submit',
      },
    ]}>
    <Typography className={titleText}>ข้อมูลทั่วไป</Typography>
    <Grid container spacing={3}>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Field
          label="รหัส"
          name="code"
          component={Input}
          placeholder="รหัส"
          required
          helperText="โปรดระบุรหัส"
          disabled={props.codeDisabled}
        />
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Field
          label="ชื่อ"
          name="firstName"
          component={Input}
          placeholder="ชื่อ"
          required
          helperText="โปรดระบุชื่อ"
          disabled={props.disabled}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Field
          label="นามสกุล"
          name="lastName"
          component={Input}
          placeholder="นามสกุล"
          required
          helperText="โปรดระบุนามสกุล"
          disabled={props.disabled}
        />
      </Grid>
    </Grid>

    <Grid container spacing={3}>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Field
          label="วันเกิด"
          name="birthdate"
          component={DatePicker}
          placeholder="วันเกิด"
          helperText="โปรดระบุวันเกิด"
          disabled={props.disabled}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Field
          label="เพศ"
          name="gender"
          component={Input}
          placeholder="เพศ"
          helperText="โปรดระบุเพศ"
          disabled={props.disabled}
        />
      </Grid>
    </Grid>

    <Grid container spacing={3}>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Field
          label="เบอร์โทรศัพท์"
          name="phoneNumber"
          component={Input}
          placeholder="เบอร์โทรศัพท์"
          required
          helperText="โปรดระบุเบอร์โทรศัพท์"
          disabled={props.disabled}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Field
          label="อีเมล"
          name="email"
          component={Input}
          placeholder="อีเมล"
          helperText="โปรดระบุอีเมล"
          disabled={props.disabled}
        />
      </Grid>
    </Grid>

    <Typography className={secondaryTitleText}>ข้อมูลการทำงาน</Typography>
    <Grid container spacing={3}>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Field
          label="แผนก"
          name="department"
          component={Input}
          placeholder="แผนก"
          helperText="โปรดระบุแผนก"
          disabled={props.disabled}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Field
          label="ทีม"
          name="team"
          component={Input}
          placeholder="ทีม"
          helperText="โปรดระบุทีม"
          disabled={props.disabled}
        />
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Field
          label="จำนวนวันลา"
          name="leaveDayAmount"
          component={Input}
          type="number"
          placeholder="จำนวนวันลา"
          helperText="โปรดระบุจำนวนวันลา"
          disabled={props.disabled}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Field
          label="ค่ารักษาพยาบาลที่เบิก"
          name="costForIllness"
          component={Input}
          type="number"
          placeholder="ค่ารักษาพยาบาลที่เบิก"
          helperText="โปรดระบุค่ารักษาพยาบาลที่เบิก"
          disabled={props.disabled}
        />
      </Grid>
    </Grid>

    <Typography className={secondaryTitleText}>ข้อมูลอื่นๆ</Typography>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Field
          label="หมายเหตุ"
          name="note"
          component={Input}
          helperText="สำหรับบันทึกข้อมูลทั่วไป"
          disabled={props.disabled}
        />
      </Grid>
    </Grid>

    {props.currentPage !== 'new' && (
      <History
        disabled={props.disabled}
        dateHistoriesOptions={props.dateHistoriesOptions}
      />
    )}
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    findEmployee: stores.employeeStore.findEmployee,
    currentEmployee: stores.employeeStore.currentEmployee || {},
    updateBasicInfo: stores.employeeStore.updateBasicInfo,
    clearCurrentEmployee: stores.employeeStore.clearCurrentEmployee,
  })),
  withFormik({
    mapPropsToValues: (props) => props.currentEmployee || {},
    validationSchema: yup.object().shape({
      code: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      phoneNumber: yup.string().required().nullable(),
    }),
    handleSubmit: async (values, formikBag) => {
      const { id, ...params } = values
      await formikBag.props.updateBasicInfo(id, omit(params, ['date']))
      Notification.success()
    },
  }),
  withHooks((props) => {
    const {
      handleReset,
      handleSubmit,
      findEmployee,
      currentEmployee,
      clearCurrentEmployee,
    } = props
    const { id } = useParams()

    useEffect(() => {
      findEmployee(id)
    }, [findEmployee, id])

    const goBack = useCallback(() => {
      paths.currentCompanyPath().employeesPath().push()
    }, [])

    const dateHistoriesOptions = useMemo(() => {
      const options = currentEmployee?.dateHistories?.map((item) => {
        return {
          label: format(item, 'dd-MM-yyyy'),
          value: item,
        }
      })

      return options
    }, [currentEmployee])

    useEffect(() => {
      return () => {
        clearCurrentEmployee()
      }
    }, [clearCurrentEmployee])

    return {
      goBack,
      reset: handleReset,
      save: handleSubmit,
      title: `แก้ไขพนักงาน '${currentEmployee?.firstName} ${currentEmployee?.lastName}'`,
      employee: currentEmployee,
      dateHistoriesOptions,
      currentPage: 'edit',
      codeDisabled: true,
    }
  }),
)

export default enhancer(EmployeeEditPage)
