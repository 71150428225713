import React, { useCallback, useEffect } from 'react'
import {
  Field,
  Input,
  PageLayout,
  Grid,
  Notification,
  Image,
  Select,
} from '@components'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import * as yup from 'yup'
import { get } from 'lodash'

const ArticleTemplateNewPage = (props) => (
  <PageLayout
    title="เพิ่มบทความ"
    actions={[
      {
        label: 'กลับ',
        icon: 'arrow-left',
        onClick: props.onBack,
      },
      {
        label: 'ล้างค่าที่กรอก',
        icon: 'redo',
        onClick: props.onReset,
      },
      {
        label: 'บันทึก',
        icon: 'save',
        color: 'primary',
        onClick: props.onSave,
        type: 'submit',
      },
    ]}>
    <Grid
      container
      spacing={1}
      className="mb-20"
      justify="center"
      style={{ marginBottom: '20px' }}>
      <Field
        name="coverImage"
        component={Image}
        variant="rounded"
        size={400}
        ratio="16:9"
      />
    </Grid>
    <Grid container spacing={3}>
      <Grid item container spacing={3}>
        <Grid item lg={4} md={5} sm={6} xs={12}>
          <Field
            label="ชื่อบทความ (สำหรับ Admin)"
            name="name"
            component={Input}
            placeholder="ชื่อบทความ (สำหรับ Admin)"
            required
            suggestMaxLength={70}
            helperText="โปรดระบุชื่อบทความ (สำหรับ Admin)"
          />
        </Grid>
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ชื่อบทความ (แสดงใน App)"
          name="title"
          component={Input}
          placeholder="ชื่อบทความ (แสดงใน App)"
          required
          suggestMaxLength={70}
          helperText="โปรดระบุชื่อบทความ (แสดงใน App)"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="หมวดหมู่บทความ"
          name="category"
          component={Select}
          options={props.categoryOptions}
          placeholder="หมวดหมู่บทความ"
          helperText="โปรดเลือกหมวดหมู่บทความ"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ประเภทบทความ"
          name="articleType"
          component={Select}
          options={props.articleTypeOptions}
          placeholder="ประเภทบทความ"
          helperText="โปรดเลือกประเภทบทความ"
          required
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="Url"
          name="url"
          component={Input}
          placeholder="Url"
          required
          type="text"
          helperText="โปรดระบุ Url"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="รางวัล (social coin)"
          name="socialCoinReward"
          component={Input}
          placeholder="รางวัล (social coin)"
          helperText="โปรดระบุรางวัล (social coin)"
          required
          type="number"
          inputProps={{ min: 0 }}
        />
      </Grid>
    </Grid>
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    configs: stores.articleTemplateStore.configs,
    fetchConfigs: stores.articleTemplateStore.fetchConfigs,
    createArticleTemplate: stores.articleTemplateStore.createArticleTemplate,
  })),
  withFormik({
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      title: yup.string().required(),
      url: yup.string().required(),
      socialCoinReward: yup.number().required().min(0).integer(),
    }),
    handleSubmit: async (values, formikBag) => {
      await formikBag.props.createArticleTemplate(values)
      Notification.success()
      paths.articleTemplatesPath().push()
    },
  }),
  withHooks((props) => {
    const { handleReset, fetchConfigs, handleSubmit, configs } = props

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    const categoryOptions = get(configs, 'attributes.category.options')
    const articleTypeOptions = get(configs, 'attributes.articleType.options')

    const onBack = useCallback(() => {
      paths.articleTemplatesPath().push()
    }, [])

    return {
      onBack,
      onReset: handleReset,
      onSave: handleSubmit,
      categoryOptions,
      articleTypeOptions,
    }
  }),
)

export default enhancer(ArticleTemplateNewPage)
