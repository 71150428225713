import ActiveStore from './ActiveStore'
import server from '@common/api'

class CompanyStore extends ActiveStore {
  constructor() {
    super('company', {
      dashboard: {},
    })
  }

  fetchDashboard = async (companyId) => {
    const response = await server.get(`/companies/${companyId}/dashboard`)
    const { ...dashboard } = response.data.data
    this.setState({ dashboard })
    return dashboard
  }
}

export default new CompanyStore()
