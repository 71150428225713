import { useMemo } from 'react'
import { ContentRoute } from '_metronic/layout'
import { Path } from '@common/paths'
import { withHooks } from '@enhancers'

const enhancer = withHooks((props) => {
  const path = useMemo(() => {
    if (props.path instanceof Path) {
      return props.path.toString()
    } else {
      return props.path
    }
  }, [props.path])

  return {
    ...props,
    path,
  }
})

export default enhancer(ContentRoute)
