// import PropTypes from 'prop-types'
import { compose } from '@enhancers'
import { withFormik as oldWithFormik } from 'formik'
// import { uniq, set, has, forEach } from 'lodash'
import { withStateHandlers } from 'recompose'
import { get, set } from 'lodash'

export function yupToFormErrors(yupError) {
  let errors = {}
  if (yupError.inner) {
    if (yupError.inner.length === 0) {
      return set(errors, yupError.path, yupError.message)
    }
    for (let err of yupError.inner) {
      if (!get(errors, err.path)) {
        errors = set(errors, err.path, err.message)
      }
    }
  }
  return errors
}

const withFormik = (config = {}) => {
  const customMapPropsToValues = (props) => {
    const mapPropsToValues = config.mapPropsToValues || (() => ({}))
    const initializeValues = mapPropsToValues(props) || {}

    // forEach(props.fieldNames, (fieldName) => {
    //   if (!has(initializeValues, fieldName)) {
    //     set(initializeValues, fieldName, undefined)
    //   }
    // })

    return initializeValues
  }
  let customHandleSubmit = undefined

  if (config.handleSubmit) {
    customHandleSubmit = async (values, formikBag) => {
      try {
        if (config.validationSchema) {
          await config.validationSchema.validate(values, { abortEarly: false })
        }
        await config.handleSubmit(values, formikBag)
      } catch (e) {
        if (e.name === 'ValidationError') {
          const errors = yupToFormErrors(e)
          formikBag.setErrors(errors)

          // setTimeout(() => {
          //   const name = get(e, 'inner[0].path')
          //   const $errorElement = document.querySelector(`[name=${name}]`)
          //   if ($errorElement) {
          //     window.scrollTo({ top: $errorElement.offsetTop - 100, behavior: 'smooth' })
          //   }
          // }, 0)
        } else if (e.name === 'ApiValidationError') {
          let errors = e.data
          if (config.namespace) {
            errors = { [config.namespace]: errors }
          }
          formikBag.setErrors(errors)
        } else if (e.name === 'ApiError') {
          formikBag.setErrors({ _error: e.message })
        } else {
          throw e
        }
      }
    }
  }

  let validate = undefined
  if (config.validationSchema) {
    validate = (values) => {
      try {
        config.validationSchema.validateSync(values)
      } catch (e) {
        if (e.name === 'ValidationError') {
          const name = e.path
          const $errorElement = document.querySelector(`[name="${name}"]`)

          if ($errorElement) {
            setTimeout(() => {
              $errorElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
              })
            }, 0)
          }
        }
      }
    }
  }

  return oldWithFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    ...config,
    validate,
    mapPropsToValues: customMapPropsToValues,
    handleSubmit: customHandleSubmit,
  })
}

const withStateFormik = (config) =>
  compose(
    // withStateHandlers(
    //   { fieldNames: [] },
    //   {
    //     registerFieldName: (props) => (fieldName) => ({
    //       fieldNames: uniq([...props.fieldNames, fieldName]),
    //     }),
    //   },
    // ),
    // withContext(
    //   {
    //     registerFieldName: PropTypes.func,
    //   },
    //   ({ registerFieldName }) => ({ registerFieldName }),
    // ),
    withStateHandlers(
      {},
      {
        setPropsToFormikBag: (formikBagProps) => (props) => ({
          ...formikBagProps,
          ...props,
        }),
      },
    ),
    withFormik(config),
  )

export default withStateFormik
