import React, { useCallback } from 'react'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import { Grid, Field, Button, DatePicker } from '@components'
import { get } from 'lodash'
import DialogActions from '@material-ui/core/DialogActions'

const AddDateModal = (props) => (
  <>
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Field
          name="date"
          component={DatePicker}
          label="วันที่เก็บข้อมูล"
          placeholder="วันที่เก็บข้อมูล"
          maxDate={new Date()}
        />
      </Grid>
    </Grid>
    <DialogActions style={{ paddingTop: 24 }}>
      <Button onClick={props.onCancel} color="light" size="small">
        ยกเลิก
      </Button>
      <Button
        type="submit"
        onClick={props.handleSubmit}
        color="primary"
        size="small">
        ตกลง
      </Button>
    </DialogActions>
  </>
)

const enhancer = compose(
  withStores((stores) => ({
    closeConfirmModal: stores.appStore.closeConfirmModal,
    updateEmployeeHistory: stores.employeeStore.updateEmployeeHistory,
    findEmployee: stores.employeeStore.findEmployee,
  })),
  withFormik({
    handleSubmit: async (values, formikBag) => {
      const { date } = values
      const employeeId = get(formikBag, 'props.employeeId')
      await formikBag.props.updateEmployeeHistory(employeeId, { date })
      await formikBag.props.findEmployee(employeeId)
      if (formikBag.props.onSuccess) {
        await formikBag.props.onSuccess(date)
      }
      formikBag.props.closeConfirmModal()
    },
  }),
  withHooks((props) => {
    const { handleSubmit, closeConfirmModal, employeeId } = props
    const onCancel = useCallback(() => {
      closeConfirmModal()
    }, [closeConfirmModal])

    return {
      handleSubmit,
      onCancel,
      employeeId,
    }
  }),
)

export default enhancer(AddDateModal)
