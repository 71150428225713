import React from 'react'
import { compose, withHooks, withStores } from '@enhancers'
import { toAbsoluteUrl } from '_metronic/_helpers'
import { Switch, Route, Redirect } from '@components'

import SignInPage from './sign_in/index'
import RegisterPage from './register/index'
import SetupPasswordPage from './setup_password/index'
import ForgotPasswordPage from './forgot_password/index'

import paths from '@common/paths'

import '_metronic/_assets/sass/pages/login/classic/login-1.scss'

const Layout = (props) => (
  <div className="d-flex flex-column flex-root">
    <div
      className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
      id="kt_login">
      <div
        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-sakid.jpg')})`,
        }}>
        <div className="d-flex flex-row-fluid flex-column justify-content-between">
          <div className="flex-column-fluid d-flex flex-column justify-content-center">
            <img
              alt="Logo"
              className="max-w-200px mb-5"
              src={toAbsoluteUrl('/media/ewc/logo.svg')}
            />
            <h3 className="font-size-h1 mb-5 text-white">
              Welcome to Sakid Backoffice!
            </h3>
            <p className="font-weight-lighter text-white opacity-80">
              DIGITIZED EMPLOYEE WELLNESS MANAGEMENT AND HEALTH QUANTIFICATION
              PLATFORM
            </p>
          </div>
          <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
            <div className="opacity-70 font-weight-bold	text-white">
              &copy; {new Date().getFullYear()} EWC
            </div>
          </div>
        </div>
      </div>
      <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
        <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
          {props.children}
        </div>
        <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
          <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
            &copy; {new Date().getFullYear()} EWC
          </div>
        </div>
      </div>
    </div>
  </div>
)

// prettier-ignore
const AuthPage = (props) => (
  <Layout>
    {props.notFoundAnyAdmin ? (
      <Switch>
        <Route path={paths.registerPath()} component={RegisterPage} exact />

        <Redirect to={paths.registerPath()} />
      </Switch>
    ) : (
      <Switch>
        <Route path={paths.signInPath()}         component={SignInPage}         exact />
        <Route path={paths.setupPasswordPath()}  component={SetupPasswordPage}  exact />
        <Route path={paths.forgotPasswordPath()} component={ForgotPasswordPage} exact />

        <Redirect to={paths.signInPath()} />
      </Switch>
    )}
  </Layout>
)

const enhancer = compose(
  withStores((stores) => ({
    notFoundAnyAdmin: !stores.appStore.hasFirstAdmin,
  })),
  withHooks((props) => {
    const { notFoundAnyAdmin } = props

    return {
      notFoundAnyAdmin,
    }
  }),
)

export default enhancer(AuthPage)
