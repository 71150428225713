import React from 'react'

import { compose } from 'redux'
import { withHooks, withStores } from '@enhancers'

import { makeStyles, withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    width: '100%',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '800px',
    width: '100%',
    padding: theme.spacing(5),
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

const DialogContainer = withStyles((theme) => ({
  root: {
    margin: 0,
    background: 'rgba(0, 0, 0, 0.5);',
  },
  scrollPaper: {
    minWidth: 'auto',
    float: 'right',
  },
}))(Dialog)

const SlideModal = (props) => (
  <DialogContainer
    fullScreen
    open={props.openModal}
    onClose={props.handleCloseModal}
    TransitionComponent={Transition}>
    <form
      onSubmit={props.formik.handleSubmit}
      className="form fv-plugins-bootstrap fv-plugins-framework">
      <AppBar className={props.classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => props.handleCloseModal()}
            aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={props.classes.title}>
            <span>{props.title}</span>
            {props.loading && (
              <span className="ml-3 spinner spinner-white"></span>
            )}
          </Typography>
          <Button type="submit" color="inherit">
            save
          </Button>
        </Toolbar>
      </AppBar>
      <div className={props.classes.contentContainer}>{props.children}</div>
    </form>
  </DialogContainer>
)

const enhancer = compose(
  withStores((stores) => ({})),
  withHooks((props) => {
    const classes = useStyles()
    return {
      classes,
    }
  }),
)
export default enhancer(SlideModal)
