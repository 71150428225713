import { compose, mapProps } from 'recompose'

const withHooks = (mapHooksToProps) =>
  compose(
    mapProps((props) => {
      return mapHooksToProps(props)
    }),
  )

export default withHooks
