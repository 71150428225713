import React, { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  Grid,
  Field,
  PageLayout,
  Input,
  DatePicker,
  Notification,
} from '@components'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import * as yup from 'yup'

const CompanyShowPage = (props) => (
  <PageLayout
    title={props.title}
    actions={[
      {
        label: 'กลับ',
        icon: 'arrow-left',
        onClick: props.onBack,
      },
      {
        label: 'ล้างค่าที่กรอก',
        icon: 'redo',
        onClick: props.onReset,
      },
      {
        label: 'บันทึก',
        icon: 'save',
        color: 'primary',
        onClick: props.onSave,
        type: 'submit',
      },
    ]}>
    <Grid container spacing={3}>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ชื่อองค์กร"
          name="name"
          component={Input}
          placeholder="ชื่อองค์กร"
          required
          helperText="โปรดใส่ชื่อเฉพาะ"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="เริ่มใช้งานระบบ"
          name="subscriptionStartedAt"
          component={DatePicker}
          placeholder="เริ่มใช้งานระบบ"
          helperText="โปรดระบุวันเริ่มใช้งานระบบ"
          required
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ใช้งานระบบได้ถึง"
          name="subscriptionEndedAt"
          component={DatePicker}
          placeholder="ใช้งานระบบได้ถึง"
          helperText="ใช้งานระบบได้ถึง"
          required
        />
      </Grid>
    </Grid>
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    currentCompany: stores.companyStore.currentCompany,
    findCompany: stores.companyStore.findCompany,
    updateCompany: stores.companyStore.updateCompany,
  })),
  withFormik({
    mapPropsToValues: (props) => props.currentCompany || {},
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      subscriptionStartedAt: yup.string().required(),
      subscriptionEndedAt: yup.string().required(),
    }),
    handleSubmit: async (values, formikBag) => {
      const { id, ...params } = values
      await formikBag.props.updateCompany(id, params)
      Notification.success()
      paths.companiesPath().push()
    },
  }),
  withHooks((props) => {
    const { handleReset, handleSubmit, findCompany, currentCompany } = props

    const { companyId } = useParams()
    useEffect(() => {
      findCompany(companyId)
    }, [findCompany, companyId])

    const companyName = currentCompany ? currentCompany.name : ''
    const title = useMemo(() => {
      return `Edit Company '${companyName}'`
    }, [companyName])

    const onBack = useCallback(() => {
      paths.companiesPath().push()
    }, [])

    return {
      onBack,
      onReset: handleReset,
      onSave: handleSubmit,
      title,
    }
  }),
)

export default enhancer(CompanyShowPage)
