import React, { useMemo } from 'react'
import { compose, withHooks } from '@enhancers'
import classnames from 'classnames'

const Icon = (props) => <i className={props.className} style={props.style}></i>

const enhancer = compose(
  withHooks((props) => {
    const { className, name, spin, style } = props
    const classes = useMemo(() => {
      return classnames('fa', {
        [`fa-${name}`]: !!name,
        'fa-spin': spin,
        [className]: className,
      })
    }, [className, name, spin])
    return {
      className: classes,
      style,
    }
  }),
)

export default enhancer(Icon)
