import React from 'react'
import { compose, withHooks } from '@enhancers'
import { Button } from '@components'
import styled, { css } from '@styled'
import classnames from 'classnames'

const buttonStyle = css`
  font-size: 0.925rem !important;
`

const Layout = styled.div`
  transition: all 0.3s;
  max-height: 0;
  opacity: 0;

  &.expanded {
    max-height: 80px;
    opacity: 1;
  }
`

const Grouping = (props) => (
  <Layout className={classnames('form ps', { expanded: props.expanded })}>
    <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
      <div className="col-xl-12">
        <div className="form-group form-group-inline">
          <div className="form-label form-label-no-wrap">
            <label className="-font-bold font-danger-">
              <span>
                Selected records count: <b>{props.selectedItems.length}</b>
              </span>
            </label>
          </div>
          <div>
            {props.actions.map(
              ({ color, hoverColor, onClick, label, icon }) => (
                <Button
                  key={label}
                  className={`${buttonStyle} mr-2`}
                  color={color}
                  hoverColor={hoverColor}
                  icon={icon}
                  onClick={() => onClick && onClick(props.selectedItems)}
                  size="small">
                  {label}
                </Button>
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

const enhancer = compose(
  withHooks(({ selectedItems, actions }) => {
    return {
      selectedItems: selectedItems || [],
      actions,
      expanded: selectedItems.length > 0,
    }
  }),
)

export default enhancer(Grouping)
