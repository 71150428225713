import React from 'react'
import { compose, withFormik, withHooks } from '@enhancers'
import { Grid, Field, Button, DatePicker } from '@components'
import { ApiValidationError } from '@common/api'
import { isEmpty } from 'lodash'

const ExportStrainEmployeeModal = (props) => (
  <Grid container spacing={2}>
    <Grid item sm={6} xs={12}>
      <Field name="strainStartDate" component={DatePicker} label="Start Date" />
    </Grid>
    <Grid item sm={6} xs={12}>
      <Field name="strainEndDate" component={DatePicker} label="End Date" />
    </Grid>
    <Grid item container justify="flex-end">
      <Button
        type="submit"
        onClick={props.handleSubmit}
        color="primary"
        size="small">
        EXPORT
      </Button>
    </Grid>
  </Grid>
)

const enhancer = compose(
  withFormik({
    handleSubmit: async (values, formikBag) => {
      const errors = {}

      if (!values?.strainStartDate) {
        formikBag.setFieldTouched('strainStartDate', true)
        errors.strainStartDate = 'ต้องไม่เว้นว่างเอาไว้'
      }
      if (!values?.strainEndDate) {
        formikBag.setFieldTouched('strainEndDate', true)
        errors.strainEndDate = 'ต้องไม่เว้นว่างเอาไว้'
      }

      if (!isEmpty(errors)) {
        throw new ApiValidationError(errors)
      }

      if (formikBag.props.onSubmit) {
        await formikBag.props.onSubmit(values)
      }
    },
  }),
  withHooks((props) => {
    const { handleSubmit } = props

    return {
      handleSubmit,
    }
  }),
)

export default enhancer(ExportStrainEmployeeModal)
