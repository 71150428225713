import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Poppins', 'Prompt'].join(', '),
  },
  palette: {
    primary: {
      main: '#1bc5bd',
    },
    secondary: {
      main: '#3783e7',
    },
    error: {
      main: '#f64e60',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: false,
    },
    MuiPopover: {
      elevation: 1,
    },
  },
})

export default theme
