import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Modal, PageLayout, Table } from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import { clone, filter, get, map, isEqual } from 'lodash'
import MatchUpModal from './MatchUpModal'
import { cleanFilter } from '@common/helper'

const MatchUpIndexPage = (props) => (
  <PageLayout title="จัดการโค้ชผู้ดูแล">
    <Table
      groupActions={[
        {
          label: 'Match up selected',
          icon: 'hands-helping',
          hoverColor: 'primary',
          onClick: props.onMatchUpAll,
        },
      ]}
      // defaultSorted={[{ dataField: 'code', order: 'asc' }]}
      $ref={props.$table}
      filters={[
        {
          description: 'กรองด้วยองค์กร',
          dataField: 'companyId',
          type: 'select',
          options: props.companyOptions,
        },
        {
          description: 'กรองด้วยฤดูกาลแข่ง',
          dataField: 'seasonId',
          type: 'select',
          options: props.seasonOptions,
        },
        {
          description: 'กรองด้วยรอบแข่งที่',
          dataField: 'cycleId',
          type: 'select',
          options: props.cycleOptions,
        },
        {
          description: 'กรองด้วยทีม',
          dataField: 'teamId',
          type: 'select',
          options: props.teamOptions,
        },
        {
          description: 'กรองด้วยผู้แข่งขัน',
          dataField: 'attendee',
          type: 'search',
          className: 'pl-lg-4 pl-md-4',
        },
        {
          description: 'กรองด้วยสถานะ',
          dataField: 'state',
          type: 'select',
          options: props.stateOptions,
          selectProps: { disableClearable: true },
        },
      ]}
      columns={[
        {
          title: 'ชื่อผู้แข่งขัน',
          dataField: 'attendeeName',
          align: 'left',
        },
        {
          title: 'ชื่อองค์กร',
          dataField: 'companyName',
        },
        {
          title: 'ชื่อฤดูกาลแข่ง',
          dataField: 'seasonName',
        },
        {
          title: 'ทีม',
          dataField: 'teamName',
        },
        {
          title: 'รอบแข่งที่',
          dataField: 'cycleNo',
        },
        {
          title: 'โค้ชที่ดูแล',
          dataField: 'coachName',
        },
        {
          title: 'เริ่มดูแลเมื่อ',
          dataField: 'matchAt',
          type: 'date',
        },
        {
          title: 'การดำเนินการ',
          type: 'actions',
          actions: [
            {
              label: 'จัดการโค้ชผู้ดูแล',
              icon: 'hands-helping',
              hoverColor: 'primary',
              onClick: props.onMatchUp,
              visible: (match) => match.canMatchUp,
            },
          ],
        },
      ]}
      entities={props.matchUps}
      paging={props.paging}
      onQuery={props.onQuery}
      selectable
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    configs: stores.matchUpStore.configs,
    matchUps: stores.matchUpStore.matchUps,
    fetchMatchUps: stores.matchUpStore.fetchMatchUps,
    fetchConfigs: stores.matchUpStore.fetchConfigs,
    paging: stores.matchUpStore.paging,
    deleteMatchUp: stores.matchUpStore.deleteMatchUp,
  })),
  withHooks((props) => {
    const {
      matchUps,
      deleteMatchUp,
      fetchMatchUps,
      paging,
      fetchConfigs,
      configs,
    } = props
    const [queryParams, setQueryParams] = useState({})

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    const $table = useRef()

    const { companyId, seasonId, cycleId } = queryParams
    const companyOptions = useMemo(() => {
      const options = get(configs, 'filters.companyId.options', [])
      return options
    }, [configs])

    const seasonOptions = useMemo(() => {
      const options = get(configs, 'filters.seasonId.options', [])
      return filter(options, { companyId })
    }, [configs, companyId])

    const cycleOptions = useMemo(() => {
      const options = get(configs, 'filters.cycleId.options', [])
      return filter(options, { seasonId })
    }, [configs, seasonId])

    const teamOptions = useMemo(() => {
      const options = get(configs, 'filters.teamId.options', [])
      return filter(options, { cycleId })
    }, [configs, cycleId])

    const stateOptions = useMemo(() => {
      const options = get(configs, 'filters.state.options', [])
      return options
    }, [configs])

    const onQuery = useCallback(
      (params) => {
        const filteredParams = clone(params)

        cleanFilter(filteredParams, 'companyId', companyOptions)
        cleanFilter(filteredParams, 'seasonId', seasonOptions)
        cleanFilter(filteredParams, 'cycleId', cycleOptions)
        cleanFilter(filteredParams, 'teamId', teamOptions)
        cleanFilter(filteredParams, 'state', stateOptions)

        if (!isEqual(params, filteredParams)) {
          $table.current.setFilter({ ...filteredParams, state: 'unmatched' })
        } else if (!isEqual(filteredParams, queryParams)) {
          setQueryParams(filteredParams)
          fetchMatchUps(filteredParams)
        }
      },
      [
        fetchMatchUps,
        companyOptions,
        seasonOptions,
        cycleOptions,
        teamOptions,
        stateOptions,
        queryParams,
      ],
    )

    const onDelete = useCallback(
      async (mission) => {
        Modal.confirm({
          children: 'Permanent delete this MatchUp.',
          onOk: async (modal) => {
            await deleteMatchUp(mission.id)
            Notification.success()
            modal.close()
          },
        })
      },
      [deleteMatchUp],
    )

    const onMatchUp = useCallback(
      async (attendee) => {
        Modal.confirm({
          title: 'Match Up',
          hideFooter: true,
          children: (
            <MatchUpModal
              attendeeIds={[attendee.id]}
              queryParams={queryParams}
            />
          ),
        })
      },
      [queryParams],
    )

    const onMatchUpAll = useCallback(
      async (attendees) => {
        const canMatchUpCoaches = filter(attendees, { canMatchUp: true })
        Modal.confirm({
          title: 'Match up selected',
          hideFooter: true,
          children: (
            <MatchUpModal
              queryParams={queryParams}
              attendeeIds={map(canMatchUpCoaches, 'id')}
              onSuccess={$table.current.clearSelectedIds}
            />
          ),
        })
      },
      [queryParams],
    )

    useEffect(() => {
      $table.current.setFilter({ state: 'unmatched' })
    }, [])

    return {
      $table,
      matchUps,
      onQuery,
      onDelete,
      onMatchUp,
      paging,
      companyOptions,
      seasonOptions,
      cycleOptions,
      teamOptions,
      stateOptions,
      onMatchUpAll,
    }
  }),
)

export default enhancer(MatchUpIndexPage)
