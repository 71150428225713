import ActiveStore from './ActiveStore'

class MatchUpStore extends ActiveStore {
  constructor() {
    super('matchUp')
  }

  assignCoach = async params => {
    await this.server.post(`/assign_coach`, params)
  }
}

export default new MatchUpStore()
