import { TimePicker } from '@material-ui/pickers'
import { enhancer as datePickerEnhancer } from '@components/DatePicker'
import { compose, withHooks } from '@enhancers'

const enhancer = compose(
  datePickerEnhancer,
  withHooks((props) => {
    return {
      ...props,
      format: 'HH:mm:ss',
    }
  }),
)

export default enhancer(TimePicker)
