import React from 'react'
import { compose, withHooks, withStores } from '@enhancers'
import { Redirect, Route, Switch } from '@components'
import paths from '@common/paths'
import { Layout } from '_metronic/layout'

import NotFoundPage from './404/index'
import AdminIndexPage from './admins/index/index'
import AdminNewPage from './admins/new/index'

import CoachIndexPage from './coaches/index/index'
import CoachNewPage from './coaches/new/index'
import CoachShowPage from './coaches/show/index'
import ArticleTemplateIndexPage from './article_templates/index/index'
import ArticleTemplateNewPage from './article_templates/new/index'
import ArticleTemplateEditPage from './article_templates/show/index'
import MissionTemplateIndexPage from './mission_templates/index/index'
import MissionTemplateNewPage from './mission_templates/new/index'
import MissionTemplateEditPage from './mission_templates/show/index'
import CompanyIndexPage from './companies/index/index'
import CompanyNewPage from './companies/new/index'
import CompanyShowPage from './companies/show/index'
import CompanyPage from './company/index'
import ChatIndexPage from './chats/index/index'
// import ComingSoonPage from './ComingSoon'
import MissionIndexPage from './missions/index/index'
import MissionShowPage from './missions/show/index'
import MissionNewPage from './missions/new/index'
import MissionEditPage from './missions/edit/index'
import ArticleIndexPage from './articles/index/index'
import HappinessScoreIndexPage from './happiness_scores/index/index'
import MatchUpIndexPage from './matchUps/index/index'
import ActivityIndexPage from './activities/index/index'
// prettier-ignore
const MainPage = props => (
  <Layout>
    <Switch>
      <Route path={paths.adminsPath()}                                    component={AdminIndexPage}           exact permittedRoles={['admin']} />
      <Route path={paths.adminsNewPath()}                                 component={AdminNewPage}             exact permittedRoles={['admin']} />
      <Route path={paths.coachesPath()}                                   component={CoachIndexPage}           exact permittedRoles={['admin']} />
      <Route path={paths.coachesNewPath()}                                component={CoachNewPage}             exact permittedRoles={['admin']} />
      <Route path={paths.coachEditPath(':coachId')}                       component={CoachShowPage}            exact permittedRoles={['admin']} />
      <Route path={paths.articleTemplatesPath()}                          component={ArticleTemplateIndexPage} exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.articleTemplateNewPath()}                        component={ArticleTemplateNewPage}   exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.articleTemplateEditPath(':templateId')}          component={ArticleTemplateEditPage}  exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.missionTemplatesPath()}                          component={MissionTemplateIndexPage} exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.missionTemplateNewPath()}                        component={MissionTemplateNewPage}   exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.missionTemplateEditPath(':missionTemplateId')}   component={MissionTemplateEditPage}  exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.chatsPath()}                                     component={ChatIndexPage}            exact permittedRoles={['admin', 'coach']} />

      <Route path={paths.companiesPath()}                                 component={CompanyIndexPage}         exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.companiesNewPath()}                              component={CompanyNewPage}           exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.companyEditPath(':companyId')}                   component={CompanyShowPage}          exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.companyPath(':companyId')}                       component={CompanyPage}              permittedRoles={['admin', 'coach', 'human_resource']} />

      <Route path={paths.matchUpPath()}                                   component={MatchUpIndexPage}         exact permittedRoles={['admin']} />
      <Route path={paths.missionsPath()}                                  component={MissionIndexPage}         exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.missionNewPath()}                                component={MissionNewPage}           exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.missionPath(':missionId')}                       component={MissionShowPage}          exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.missionEditPath(':missionId')}                   component={MissionEditPage}          exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.articlesPath()}                                  component={ArticleIndexPage}         exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.happinessScoresPath()}                           component={HappinessScoreIndexPage}         exact permittedRoles={['admin', 'coach']} />
      <Route path={paths.activitiesPath()}                                component={ActivityIndexPage}        exact permittedRoles={['admin']} />

      <Route path={paths.notFoundPath()}                                  component={NotFoundPage}             exact />

      <Redirect to={paths.adminsPath()}           permittedRoles={['admin']} />
      <Redirect to={paths.articleTemplatesPath()} permittedRoles={['coach']} />
      <Redirect to={paths.currentCompanyPath()}   permittedRoles={['human_resource']} />
      <Redirect to={paths.notFoundPath()} />
    </Switch>
  </Layout>
)

const enhancer = compose(
  withStores((stores) => ({})),
  withHooks((props) => {
    return {}
  }),
)

export default enhancer(MainPage)
