import { connect } from 'react-redux'
import deepmerge from 'deepmerge'

import appStore from '@stores/appStore'
import authStore from '@stores/authStore'
import adminStore from '@stores/adminStore'
import companyStore from '@stores/companyStore'
import missionTemplateStore from '@stores/missionTemplateStore'
import articleTemplateStore from '@stores/articleTemplateStore'
import humanResourceStore from '@stores/humanResourceStore'
import seasonStore from '@stores/seasonStore'
import coachStore from '@stores/coachStore'
import employeeStore from '../stores/employeeStore'
import chatStore from '@stores/chatStore'
import announcementStore from '@stores/announcementStore'
import attendeeStore from '@stores/attendeeStore'
import articleStore from '@stores/articleStore'
import socialCoinStore from '@stores/socialCoinStore'
import missionStore from '@stores/missionStore'
import matchUpStore from '@stores/matchUpStore'
import activityStore from '@stores/activityStore'
import happinessScoreStore from '@stores/happinessScoreStore'
import companyMissionTemplateStore from '@stores/companyMissionTemplateStore'

const storeMapping = {
  appStore,
  authStore,
  adminStore,
  companyStore,
  missionTemplateStore,
  articleTemplateStore,
  humanResourceStore,
  seasonStore,
  coachStore,
  employeeStore,
  chatStore,
  announcementStore,
  attendeeStore,
  articleStore,
  happinessScoreStore,
  socialCoinStore,
  missionStore,
  matchUpStore,
  activityStore,
  companyMissionTemplateStore,
}

const withStores = (mapStoresToProps) =>
  connect((state, props) => {
    const stores = deepmerge(storeMapping, state.data, { clone: false })
    return mapStoresToProps(stores, props)
  })

export default withStores
