import React, { useEffect } from 'react'
import * as yup from 'yup'
import { compose, withStores, withFormik, withHooks } from '@enhancers'
import { useQuery } from '@hooks'
import { Field, Input, Button, Grid, Notification } from '@components'
import FormLayout from '../sign_in/FormLayout'
import paths from '@common/paths'

const SetupPasswordPage = (props) => (
  <FormLayout
    title="Setup New Password"
    description="Enter your new password."
    onSubmit={props.handleSubmit}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Field
          label="Password"
          name="password"
          component={Input}
          type="password"
          size="medium"
        />
      </Grid>
      <Grid item xs={12} container justify="space-between" alignItems="center">
        <Button type="submit" icon="cog" size="large" color="primary">
          Setup
        </Button>
      </Grid>
    </Grid>
  </FormLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    setupPassword: stores.authStore.setupPassword,
  })),
  withFormik({
    validationSchema: yup.object().shape({
      password: yup.string().min(8).required(),
    }),
    handleSubmit: async (values, formikBag) => {
      const token = formikBag.props.token
      await formikBag.props.setupPassword({ ...values, token })
      Notification.success('Setup success please sign in.')
      paths.signInPath().push()
    },
  }),
  withHooks((props) => {
    const { handleSubmit, setPropsToFormikBag } = props
    const { token } = useQuery()

    useEffect(() => {
      setPropsToFormikBag({ token })
    }, [setPropsToFormikBag, token])

    return {
      handleSubmit,
    }
  }),
)

export default enhancer(SetupPasswordPage)
