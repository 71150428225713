import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button } from '@components'
import { compose, withHooks } from '@enhancers'
import styled from '@styled'

const Layout = styled.div``

const BrowseButton = ({ getRootProps, getInputProps, ...buttonProps }) => (
  <Layout {...getRootProps()}>
    <input autoComplete="off" {...getInputProps()} />
    <Button {...buttonProps} />
  </Layout>
)

const enhancer = compose(
  withHooks(props => {
    const { onBrowse, ...rest } = props
    const [loading, setLoading] = useState(false)

    const onDrop = useCallback(
      async files => {
        if (onBrowse) {
          setLoading(true)
          try {
            await onBrowse(files[0])
          } catch (e) {}
          setLoading(false)
        }
        setLoading(false)
      },
      [onBrowse],
    )

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
    })

    return { getRootProps, getInputProps, ...rest, loading }
  }),
)

export default enhancer(BrowseButton)
