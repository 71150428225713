import GraphemeSplitter from 'grapheme-splitter'
import { BaseStore } from './index'
import { CometChat } from '@cometchat-pro/chat'
import server from '../common/api'
import authStore from './authStore'
import { isEmpty, slice } from 'lodash'

class ChatStore extends BaseStore {
  constructor() {
    super('chatStore', {
      initialized: false,
      user: null,
      massagers: [],
      massager: {},
      countUnreadMessage: null,
      attendees: [],
      configs: {},
      conversationList: [],
      hasMessageIncoming: false,
    })
  }

  notifyToApp = async (messageInfo) => {
    const { receiverId, data } = messageInfo
    const MAX_LENGTH = 10000

    const params = {
      attendeeId: receiverId.split('-')[2],
      title: 'คุณได้รับข้อความใหม่จากโค้ช',
      message: data?.text?.substring?.(0, MAX_LENGTH).replace(/.$/, '...'),
    }

    if (data.text) {
      const splitter = new GraphemeSplitter()
      const chars = splitter.splitGraphemes(data.text)
      if (chars.length > 0) {
        if (chars.length > MAX_LENGTH) {
          params.message = slice(chars, 0, MAX_LENGTH).join('') + '...'
        } else {
          params.message = chars.join('')
        }
      }
    }

    if (data.url && !params.message) {
      params.message = 'ส่งรูป'
    }

    if (params.message) {
      // Prevent duplicate notify about add articles
      if (/^โค้ชส่งบทความมาให้คุณอ่าน เรื่อง/.test(params.message)) {
        return
      }
      await server.post(`/notify`, params)
    }
  }

  initChat = async (configs = {}) => {
    if (!this.state.initialized) {
      const { appId, authKey, region } = configs
      this.setState({ APP_ID: appId, REGION: region, AUTH_KEY: authKey })

      const COMET_CHAT_SETTING = new CometChat.AppSettingsBuilder()
        .subscribePresenceForAllUsers()
        .setRegion(this.state.REGION)
        .build()

      await CometChat.init(this.state.APP_ID, COMET_CHAT_SETTING)
      this.setState({ initialized: true })
    }
  }

  checkUnreadMessage = async () => {
    this.setState({ hasMessageIncoming: false })

    if (this.state.user) {
      try {
        const { users } = await CometChat.getUnreadMessageCount()
        const hasMessageIncoming = !isEmpty(users)
        this.setState({ hasMessageIncoming })
      } catch (e) {
        console.log('CheckUnreadMessage Failed', e)
      }
    }
  }

  setupMessageListener = async () => {
    if (this.state.massageListenerId) {
      CometChat.removeMessageListener(this.state.massageListenerId)
    }
    if (this.state.user) {
      this.setState({ massageListenerId: 'massage_' + new Date().getTime() })
      CometChat.addMessageListener(
        this.state.massageListenerId,
        new CometChat.MessageListener({
          onTextMessageReceived: () => {
            this.checkUnreadMessage()
          },
          onMessagesRead: () => {
            this.checkUnreadMessage()
          },
          onMessagesDelivered: () => {},
        }),
      )
    }
  }

  loginComet = async (UID) => {
    if (!this.state.initialized) {
      console.log('Cannot login cause never init comet chat.')
      return
    }
    if (UID !== this.state.user?.uid) {
      const user = await CometChat.login(UID, this.state.AUTH_KEY)
      this.setState({ user: null })
      this.setState({ user })

      this.checkUnreadMessage()
      this.setupMessageListener()
    }
  }

  loggedInUser = async () => {
    try {
      const user = await CometChat.getLoggedInUser()
      this.setState({ user })
    } catch (e) {
      console.log('LoggedInUser Failed', e)
    }
  }

  logoutComet = async () => {
    try {
      if (this.state.user) {
        this.setState({ user: null })
        this.checkUnreadMessage()
        this.setupMessageListener()
        await CometChat.logout()
      }
    } catch (e) {
      console.log('LoggedInUser Failed', { e })
    }
  }

  clearAttendees = async () => {
    this.setState({ attendees: [] })
  }

  getAttendees = async (params) => {
    const response = await server.get(`/chats/attendees`, params)

    const attendees = response.data.data.attendees.map((attendee) => {
      attendee.name = attendee.attendeeName || ''
      attendee.uid = attendee.attendeeChatUid || ''
      attendee.blockedByMe = false
      attendee.hasBlockedMe = false
      attendee.status = 'offline'
      return attendee
    })

    if (authStore.state.currentRole === 'admin' && !params.coach) {
      this.setState({
        attendees: [],
      })
    } else {
      this.setState({
        attendees,
      })
    }
    return attendees
  }

  getAttendee = async (id) => {
    const response = await server.get(`/chats/attendees/${id}`)
    const attendee = response.data.data.attendee
    return attendee
  }

  fetchConfigs = async () => {
    const response = await server.get(`/chats/configs`)
    const { configs } = response.data.data
    this.setState({
      configs,
    })

    return configs
  }

  assignArticle = async (params) => {
    const response = await server.post(`/articles`, params)
    return response
  }
}

export default new ChatStore()
