import React, { useCallback, useEffect } from 'react'
import { Modal, Notification, PageLayout, Table } from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import { get } from 'lodash'

const ArticleTemplatePage = (props) => (
  <PageLayout
    title="บทความ"
    actions={[
      {
        label: 'เพิ่มบทความ',
        icon: 'plus',
        color: 'primary',
        onClick: props.onCreate,
      },
    ]}>
    <Table
      filters={[
        {
          description: 'กรองด้วยหมวดหมู่',
          dataField: 'category',
          type: 'select',
          options: props.categoryOptions,
          size: 3,
        },
        {
          description: 'กรองด้วยชื่อบทความ (สำหรับ Admin)',
          dataField: 'name',
          type: 'search',
        },
      ]}
      columns={[
        {
          title: 'ชื่อบทความ (สำหรับ Admin)',
          dataField: 'name',
          sort: true,
          align: 'left',
        },
        {
          title: 'Cover Image',
          dataField: 'coverImageUrl',
          type: 'image',
          nameKey: 'coverImage',
          onClick: props.onEdit,
        },
        {
          title: 'รางวัล (social coin)',
          dataField: 'socialCoinReward',
        },
        {
          title: 'หมวดหมู่บทความ',
          dataField: 'category',
        },
        {
          title: 'URL',
          dataField: 'url',
        },
        {
          title: 'การดำเนินการ',
          type: 'actions',
          actions: [
            {
              label: 'แก้ไข',
              icon: 'pen',
              hoverColor: 'primary',
              onClick: props.onEdit,
            },
            {
              label: 'ลบ',
              icon: 'trash',
              hoverColor: 'danger',
              onClick: props.onDelete,
              visible: (articleTemplate) => articleTemplate.canDelete,
            },
          ],
        },
      ]}
      entities={props.articleTemplates}
      paging={props.paging}
      onQuery={props.onQuery}
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    configs: stores.articleTemplateStore.configs,
    paging: stores.articleTemplateStore.paging,
    fetchConfigs: stores.articleTemplateStore.fetchConfigs,
    articleTemplates: stores.articleTemplateStore.articleTemplates,
    fetchArticleTemplates: stores.articleTemplateStore.fetchArticleTemplates,
    deleteArticleTemplate: stores.articleTemplateStore.deleteArticleTemplate,
  })),
  withHooks((props) => {
    const {
      configs,
      articleTemplates,
      paging,
      fetchArticleTemplates,
      deleteArticleTemplate,
      fetchConfigs,
    } = props

    const categoryOptions = get(configs, 'attributes.category.options', [
      { label: 'อาหาร', value: 'food' },
    ])

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    const onQuery = fetchArticleTemplates

    const onCreate = useCallback(() => {
      paths.articleTemplateNewPath().push()
    }, [])

    const onEdit = useCallback((template) => {
      paths.articleTemplateEditPath(template.id).newTab()
    }, [])

    const onDelete = useCallback(
      async (template) => {
        Modal.confirm({
          children: 'Permanent delete this Template.',
          onOk: async (modal) => {
            await deleteArticleTemplate(template.id)
            Notification.success()
            modal.close()
          },
        })
      },
      [deleteArticleTemplate],
    )

    return {
      onCreate,
      onDelete,
      onEdit,
      articleTemplates,
      paging,
      onQuery,
      categoryOptions,
    }
  }),
)

export default enhancer(ArticleTemplatePage)
