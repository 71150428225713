import ActiveStore from './ActiveStore'
import { clone } from 'lodash'

class SeasonStore extends ActiveStore {
  constructor() {
    super('season')
  }

  publishSeason = async id => {
    await this.server.post(`/${id}/publish`)
    const seasons = clone(this.state.seasons)
    const customSeasons = seasons.map((season) => {
      if(season.id === id) {
        season = { ...season, published: true }
      }
      return season
    })
    this.setState({ seasons: customSeasons })
  }
}

export default new SeasonStore()
