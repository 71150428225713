import { compose, defaultProps } from '@enhancers'
import Avatar from './Avatar'
import PanoramaIcon from '@material-ui/icons/Panorama'

const enhancer = compose(
  defaultProps({
    PlaceholderIcon: PanoramaIcon,
  }),
)

export default enhancer(Avatar)
