import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import qs from 'qs'

const useQuery = () => {
  const { search } = useLocation()

  const params = useMemo(() => {
    return qs.parse(search.replace(/^\?/, ''))
  }, [search])

  return params
}

export default useQuery
