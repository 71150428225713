import React, { useCallback, useEffect, useState, useRef } from 'react'
import { PageLayout, Table } from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import { clone, get, isEqual } from 'lodash'
import { css } from '@styled'
import { cleanFilter } from '@common/helper'
import { useQuery } from '@hooks'
import { avatarStyle } from '@pages/companies/index/index'

const columnClass = css`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const HappinessScore = (props) => (
  <PageLayout title="ระดับความสุข">
    <Table
      $ref={props.$table}
      filters={[
        {
          description: 'กรองด้วยษริษัท',
          dataField: 'companyId',
          type: 'select',
          options: props.companyIdOptions,
        },
        {
          description: 'กรองด้วยชื่อพนักงาน',
          dataField: 'attendeeName',
          type: 'search',
        },
      ]}
      columns={[
        {
          className: avatarStyle,
          title: 'บริษัท',
          dataField: 'companyLogoUrl',
          type: 'avatar',
          nameKey: 'companyLogoUrl',
        },
        {
          title: 'ผู้เข้าร่วม',
          dataField: 'attendeeName',
          align: 'left',
          className: columnClass,
        },
        {
          title: 'วันที่',
          dataField: 'date',
          type: 'date',
          className: columnClass,
        },
        {
          title: 'ระดับความสุข',
          dataField: 'happinessScore',
        },
      ]}
      entities={props.happinessScores}
      paging={props.paging}
      onQuery={props.onQuery}
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    fetchHappinessScores: stores.happinessScoreStore.fetchHappinessScores,
    fetchConfigs: stores.happinessScoreStore.fetchConfigs,
    configs: stores.happinessScoreStore.configs,
    happinessScores: stores.happinessScoreStore.happinessScores,
    paging: stores.happinessScoreStore.paging,
  })),
  withHooks((props) => {
    const {
      happinessScores,
      fetchHappinessScores,
      paging,
      fetchConfigs,
      configs,
    } = props
    const [queryParams, setQueryParams] = useState({})

    const companyIdOptions = get(configs, 'filters.companyId.options')

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    const $table = useRef()
    const onQuery = useCallback(
      (params) => {
        const filteredParams = clone(params)

        cleanFilter(filteredParams, 'companyId', companyIdOptions)

        if (!isEqual(params, filteredParams)) {
          $table.current.setFilter(filteredParams)
        } else if (!isEqual(filteredParams, queryParams)) {
          setQueryParams(filteredParams)
          fetchHappinessScores(filteredParams)
        }
      },
      [fetchHappinessScores, companyIdOptions, queryParams],
    )

    const { companyId, attendeeName } = useQuery()
    useEffect(() => {
      $table.current.setFilter({ companyId, attendeeName })
    }, [companyId, attendeeName])

    return {
      $table,
      happinessScores,
      onQuery,
      paging,
      companyIdOptions,
    }
  }),
)

export default enhancer(HappinessScore)
