import React, { useCallback } from 'react'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import { Grid, Field, Notification, Input, Button } from '@components'
import { get } from 'lodash'
import DialogActions from '@material-ui/core/DialogActions'

const RejectMissionModal = (props) => (
  <>
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Field name="remark" component={Input} label="Remark" />
      </Grid>
    </Grid>
    <DialogActions>
      <Button onClick={props.onCancel} color="light" size="small">
        Cancel
      </Button>
      <Button
        type="submit"
        onClick={props.handleSubmit}
        color="primary"
        size="small">
        SAVE
      </Button>
    </DialogActions>
  </>
)

const enhancer = compose(
  withStores((stores) => ({
    closeConfirmModal: stores.appStore.closeConfirmModal,
    rejectMission: stores.missionStore.rejectMission,
    fetchMission: stores.missionStore.fetchMission,
  })),
  withFormik({
    handleSubmit: async (values, formikBag) => {
      const { remark } = values
      const missionIds = get(formikBag, 'props.missionIds')
      const pacers = formikBag.props.pacers
      await formikBag.props.rejectMission({ missionIds, remark, pacers })
      formikBag.props.closeConfirmModal()
      Notification.success()
      if (formikBag.props.onComplete) {
        formikBag.props.onComplete()
      }
    },
  }),
  withHooks((props) => {
    const { handleSubmit, closeConfirmModal } = props
    const onCancel = useCallback(() => {
      closeConfirmModal()
    }, [closeConfirmModal])

    return {
      handleSubmit,
      onCancel,
    }
  }),
)

export default enhancer(RejectMissionModal)
