import { findKey } from 'lodash'
import { isClient } from './common/helper'

const config = {
  production: {
    HOST: 'https://admin.sakid.app',
    API_HOST: 'https://api.sakid.app',
    API_ENDPOINT: 'https://api.sakid.app/backoffice/api/v2',
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyBy7CC2-jB7GzZmCv0J5qh3cglD794qvGs',
      authDomain: 'sakid-production.firebaseapp.com',
      projectId: 'sakid-production',
      storageBucket: 'sakid-production.appspot.com',
      messagingSenderId: '420514967542',
      appId: '1:420514967542:web:a9df7c8732342ee140e1d7',
      measurementId: 'G-C3XHQRJMNW',
    },
  },
  staging: {
    HOST: 'https://dev.backoffice.ewc-v2.ld.moveplus.dynu.net',
    API_HOST: 'https://dev.api.ewc-v2.ld.moveplus.dynu.net',
    API_ENDPOINT: 'https://dev.api.ewc-v2.ld.moveplus.dynu.net/backoffice/api/v1',
    // FIREBASE_CONFIG: {
    //   apiKey: 'AIzaSyA9F5XslvW9dcyS3sr8VTKuLwNKDeEn_L0',
    //   authDomain: 'sakid-development-c0f2f.firebaseapp.com',
    //   projectId: 'sakid-development-c0f2f',
    //   storageBucket: 'sakid-development-c0f2f.appspot.com',
    //   messagingSenderId: '111832765324',
    //   appId: '1:111832765324:web:bf5eda8142a99ab5033ca9',
    //   measurementId: 'G-LPW1CHCD6V',
    // },
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyBy7CC2-jB7GzZmCv0J5qh3cglD794qvGs',
      authDomain: 'sakid-production.firebaseapp.com',
      projectId: 'sakid-production',
      storageBucket: 'sakid-production.appspot.com',
      messagingSenderId: '420514967542',
      appId: '1:420514967542:web:a9df7c8732342ee140e1d7',
      measurementId: 'G-C3XHQRJMNW',
    },
  },
  local: {
    HOST: 'http://localhost:20002',
    API_HOST: 'http://localhost:20001',
    API_ENDPOINT: 'http://localhost:20001/backoffice/api/v1',
    // FIREBASE_CONFIG: {
    //   apiKey: 'AIzaSyA9F5XslvW9dcyS3sr8VTKuLwNKDeEn_L0',
    //   authDomain: 'sakid-development-c0f2f.firebaseapp.com',
    //   projectId: 'sakid-development-c0f2f',
    //   storageBucket: 'sakid-development-c0f2f.appspot.com',
    //   messagingSenderId: '111832765324',
    //   appId: '1:111832765324:web:bf5eda8142a99ab5033ca9',
    //   measurementId: 'G-LPW1CHCD6V',
    // },
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyBy7CC2-jB7GzZmCv0J5qh3cglD794qvGs',
      authDomain: 'sakid-production.firebaseapp.com',
      projectId: 'sakid-production',
      storageBucket: 'sakid-production.appspot.com',
      messagingSenderId: '420514967542',
      appId: '1:420514967542:web:a9df7c8732342ee140e1d7',
      measurementId: 'G-C3XHQRJMNW',
    },
  },
}

const getEnvByHost = () =>
  isClient ? findKey(config, { HOST: window.location.origin }) : null

export const env = getEnvByHost() || process.env.APP_ENV || 'local'

export default config[env]
