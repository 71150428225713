import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import { showCompanyMenu } from '@common/helper'
import { useParams } from 'react-router-dom'
import paths from '@common/paths'
import ComingSoonPage from '@pages/ComingSoon'

import InfoPage from './info/index'
import DashboardIndexPage from './dashboard/index'
import HumanResourceIndexPage from './human_resources/index/index'
import HumanResourceNewPage from './human_resources/new/index'
import HumanResourceEditPage from './human_resources/edit/index'
import EmployeeIndexPage from './employees/index/index'
import EmployeeNewPage from './employees/new/index'
import EmployeeEditPage from './employees/edit/index'
import EmployeeShowPage from './employees/show/index'
import MissionTemplateIndexPage from './mission_templates/index/index'
import SeasonIndexPage from './seasons/index/index'
import SeasonNewPage from './seasons/new/index'
import SeasonShowPage from './seasons/show/index'
import SeasonEditPage from './seasons/edit/index'
import AttendeeIndexPage from './attendees/index/index'
import AttendeeAddSocialCoinPage from './attendees/edit/index'
import SocialCoinIndexPage from './social_coins/index'
import AnnounceIndexPage from './announcements/index'
import AnnouncementNewPage from './announcements/new'
import AnnouncementShowPage from './announcements/show/index'

// prettier-ignore
const CompanyPage = (props) => props.initialized && (
  <Switch>
    <Route path={paths.companyPath(':companyId').infoPath()}                    component={InfoPage}               exact permittedRoles={['admin', 'human_resource']} />

    <Route path={paths.companyPath(':companyId').dashboardPath()}               component={DashboardIndexPage}     exact permittedRoles={['admin', 'human_resource']} />

    <Route path={paths.companyPath(':companyId').humanResourcesPath()}          component={HumanResourceIndexPage} exact permittedRoles={['admin', 'human_resource']} />
    <Route path={paths.companyPath(':companyId').humanResourcesNewPath()}       component={HumanResourceNewPage}   exact permittedRoles={['admin', 'human_resource']} />
    <Route path={paths.companyPath(':companyId').humanResourcePath(':id')}      component={ComingSoonPage}         exact permittedRoles={['admin', 'human_resource']} />
    <Route path={paths.companyPath(':companyId').humanResourceEditPath(':id')}  component={HumanResourceEditPage}  exact permittedRoles={['admin', 'human_resource']} />

    <Route path={paths.companyPath(':companyId').employeesPath()}               component={EmployeeIndexPage}      exact permittedRoles={['admin', 'human_resource']} />
    <Route path={paths.companyPath(':companyId').employeesNewPath()}            component={EmployeeNewPage}        exact permittedRoles={['admin', 'human_resource']} />
    <Route path={paths.companyPath(':companyId').employeePath(':id')}           component={EmployeeShowPage}       exact permittedRoles={['admin', 'human_resource', 'coach']} />
    <Route path={paths.companyPath(':companyId').employeeEditPath(':id')}       component={EmployeeEditPage}       exact permittedRoles={['admin', 'human_resource']} />

    <Route path={paths.companyPath(':companyId').missionTemplatesPath()}        component={MissionTemplateIndexPage} exact permittedRoles={['admin']} />

    <Route path={paths.companyPath(':companyId').seasonsPath()}                 component={SeasonIndexPage}        exact permittedRoles={['admin', 'human_resource']} />
    <Route path={paths.companyPath(':companyId').seasonsNewPath()}              component={SeasonNewPage}          exact permittedRoles={['admin', 'human_resource']} />
    <Route path={paths.companyPath(':companyId').seasonPath(':seasonId')}       component={SeasonShowPage}         exact permittedRoles={['admin', 'human_resource']} />
    <Route path={paths.companyPath(':companyId').seasonEditPath(':seasonId')}   component={SeasonEditPage}         exact permittedRoles={['admin', 'human_resource']} />

    <Route path={paths.companyPath(':companyId').attendeesPath()}               component={AttendeeIndexPage}      exact permittedRoles={['admin', 'human_resource']} />
    <Route path={paths.companyPath(':companyId').attendeeAddSocialCoinPath(':attendeeId')}   component={AttendeeAddSocialCoinPage}         exact permittedRoles={['admin', 'human_resource']} />

    <Route path={paths.companyPath(':companyId').socialCoinsPath()}             component={SocialCoinIndexPage}    exact permittedRoles={['admin', 'human_resource']} />
    <Route path={paths.companyPath(':companyId').socialCoinsNewPath()}          component={ComingSoonPage}         exact permittedRoles={['admin', 'human_resource']} />
    <Route path={paths.companyPath(':companyId').socialCoinPath(':id')}         component={ComingSoonPage}         exact permittedRoles={['admin', 'human_resource']} />

    <Route path={paths.companyPath(':companyId').announcementsPath()}           component={AnnounceIndexPage}      exact permittedRoles={['admin', 'human_resource']} />
    <Route path={paths.companyPath(':companyId').announcementsNewPath()}        component={AnnouncementNewPage}    exact permittedRoles={['admin', 'human_resource']} />
    <Route path={paths.companyPath(':companyId').announcementPath(':id')}       component={AnnouncementShowPage}   exact permittedRoles={['admin', 'human_resource']} />

    <Redirect to={paths.companyPath(':companyId').infoPath()} permittedRoles={['admin', 'human_resource']} />
  </Switch>
)

const enhancer = compose(
  withStores((stores) => ({
    setCurrentCompanyId: stores.authStore.setCurrentCompanyId,
    currentCompanyId: stores.authStore.currentCompanyId,
    findCompany: stores.companyStore.findCompany,
    clearCurrentCompany: stores.companyStore.clearCurrentCompany,
  })),
  withHooks((props) => {
    const {
      setCurrentCompanyId,
      currentCompanyId,
      findCompany,
      clearCurrentCompany,
    } = props
    const { companyId } = useParams()

    useEffect(() => {
      findCompany(companyId)
    }, [companyId, findCompany])

    useEffect(() => {
      showCompanyMenu(true)
      return () => {
        clearCurrentCompany()
        showCompanyMenu(false)
      }
    }, [clearCurrentCompany])

    useEffect(() => {
      setCurrentCompanyId(companyId)
    }, [companyId, setCurrentCompanyId])

    const initialized = companyId === currentCompanyId

    return { initialized }
  }),
)

export default enhancer(CompanyPage)
