/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import Link from 'src/components/Link'

const LinkColumnFormatter = (cellContent, row, rowIndex, props) =>
  cellContent ? (
    <Link onClick={() => props.onClick(row)}>{cellContent}</Link>
  ) : null

export default LinkColumnFormatter
