/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from 'react'
import authStore from '@stores/authStore'
import { Button } from '@components'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}))

export function QuickUser() {
  const classes = useStyles()

  const signOut = () => {
    const toggle = document.getElementById('kt_quick_user_toggle')
    if (toggle) {
      toggle.click()
    }
    authStore.signOut()
  }

  const title = authStore.$get('state.currentUser.email')

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          Profile
          {/* <small className="text-muted font-size-sm ml-2">12 messages</small> */}
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close">
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5 justify-content-center">
          <div className="d-flex flex-column overflow-hidden align-items-center">
            <Avatar className={classes.avatar} />
            <div className="text-truncate my-5">{title}</div>
            <Button color="danger" icon="sign-out-alt" onClick={signOut}>
              Sign Out
            </Button>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />
      </div>
    </div>
  )
}
