import { Redirect } from 'react-router-dom'
import { withHooks } from '@enhancers'
import { Path } from '@common/paths'
import { useMemo } from 'react'

const enhancer = withHooks((props) => {
  const to = useMemo(() => {
    if (props.to instanceof Path) {
      return props.to.toString()
    } else {
      return props.to
    }
  }, [props.to])

  return {
    ...props,
    to,
  }
})

export default enhancer(Redirect)
