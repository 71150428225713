import React, { useCallback, useMemo } from 'react'
import { find, map } from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { compose, withHooks } from '@enhancers'
import { useFormikField } from '@hooks'
import TextField from '@material-ui/core/TextField'
import { DEFAULT_PROPS as DEFAULT_INPUT_PROPS } from '@components/Input'
import { safe } from '@common/helper'
import { formikCalibration } from './Input'

// https://material-ui.com/api/autocomplete/
// https://material-ui.com/components/autocomplete/

const DEFAULT_PROPS = {
  size: 'small',
  options: [],
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
}

const enhancer = compose(
  withHooks((props) => {
    formikCalibration(props)

    const getOnChangeValue = useCallback(
      (e, label) => {
        if (props.multiple) {
          return safe(
            () =>
              label.map((l) => {
                const option = find(props.options, { label: `${l}` })
                if (option) {
                  return option.value
                } else {
                  return l
                }
              }),
            [],
          )
        } else {
          return safe(() => find(props.options, { label: `${label}` }).value)
        }
      },
      [props.options, props.multiple],
    )

    const {
      name,
      value,
      error,
      disabled,
      onChange,
      onBlur,
      label,
      options,
      helperText,
      required,
      placeholder,
      InputLabelProps,
      disableAutoComplete,
      multiple,
      selectProps,
      ...rest
    } = useFormikField(
      {
        ...DEFAULT_PROPS,
        ...props,
      },
      { getOnChangeValue },
    )

    const customOptions = useMemo(() => {
      return ['', ...map(options, 'label')]
    }, [options])

    const customValue = useMemo(() => {
      if (multiple) {
        const safeValue = safe(
          () =>
            value.map((v) => {
              const option = find(options, { value: `${v}` })
              if (option) {
                return option.label
              } else {
                return v
              }
            }),
          [],
        )
        return safeValue === undefined ? null : safeValue
      } else {
        const safeValue = safe(() => find(options, { value }).label, null)
        return safeValue === undefined ? null : safeValue
      }
    }, [value, options, multiple])
    const setFieldTouched = props.form && props.form.setFieldTouched
    const renderInput = useCallback(
      (params) => (
        <TextField
          {...params}
          {...DEFAULT_INPUT_PROPS}
          inputProps={{
            ...params.inputProps,
            disabled: disableAutoComplete || disabled,
          }}
          label={label}
          required={required}
          error={!!error}
          helperText={error || helperText}
          onClick={() => setFieldTouched && setFieldTouched(name, true)}
          placeholder={placeholder}
        />
      ),
      [
        label,
        name,
        error,
        helperText,
        required,
        setFieldTouched,
        placeholder,
        disableAutoComplete,
        disabled,
      ],
    )

    return {
      ...rest,
      ...selectProps,
      renderInput,
      disabled,
      name,
      options: customOptions,
      value: customValue,
      onChange,
      onBlur,
      multiple,
    }
  }),
)

export default enhancer(Autocomplete)
