import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { compose, withHooks, withStores } from '@enhancers'
import appStore from '@stores/appStore'

const SnackBar = (props) => <></>

const enhancer = compose(
  withStores((stores) => ({
    notification: stores.appStore.notification,
  })),
  withHooks((props) => {
    const { notification } = props
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
      if (notification) {
        const { message, ...rest } = notification
        enqueueSnackbar(message, rest)
      }
    }, [notification, enqueueSnackbar])

    return {}
  }),
)

const Notification = enhancer(SnackBar)

Notification.notify = appStore.notify
Notification.error = appStore.notifyError
Notification.success = appStore.notifySuccess

export default Notification
