import React, { useEffect, useMemo, useCallback, createRef } from 'react'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { compose, withHooks, withStores } from '@enhancers'
import { get, map, forEach, cloneDeep } from 'lodash'
import appStore from '@stores/appStore'

const ExcelGeneratorComponent = ({ sheets, $sheetRefs }) =>
  map(sheets, (sheet, index) => (
    <ExcelExport key={index} {...sheet} ref={$sheetRefs.current[index]} />
  ))

const enhancer = compose(
  withStores((stores, props) =>
    props.storeName ? get(stores, props.storeName) : {},
  ),
  withHooks((props) => {
    const {
      fileName,
      columns,
      data,
      sheets,
      triggeredGenerator,
      onGenerated,
    } = props

    const customSheets = useMemo(() => {
      const temps = cloneDeep(sheets) || [{ name: 'Sheet1', columns, data }]
      forEach(temps, (temp) => {
        temp.fileName = fileName
      })
      return temps
    }, [fileName, columns, data, sheets])

    const sheetsLength = customSheets.length

    const $sheetRefs = React.useRef([])
    if ($sheetRefs.current.length !== sheetsLength) {
      $sheetRefs.current = Array(sheetsLength)
        .fill()
        .map((_, i) => $sheetRefs.current[i] || createRef())
    }

    const save = useCallback(() => {
      const workbookOptionsList = map($sheetRefs.current, ($sheetRef) => {
        return $sheetRef.current.workbookOptions()
      })

      const mainSheetRef = $sheetRefs.current[0].current
      const mainWorkbookOptions = workbookOptionsList[0]

      forEach(workbookOptionsList, (workbookOptions, index) => {
        mainWorkbookOptions.sheets[index] = workbookOptions.sheets[0]
        mainWorkbookOptions.sheets[index].title = customSheets[index].name
      })

      mainSheetRef.save(mainWorkbookOptions)
    }, [$sheetRefs, customSheets])

    useEffect(() => {
      if (triggeredGenerator) {
        save()
        if (onGenerated) {
          onGenerated()
        }
      }
    }, [save, triggeredGenerator, onGenerated])

    return {
      sheets: customSheets,
      $sheetRefs,
    }
  }),
)

const ExcelGenerator = enhancer(ExcelGeneratorComponent)

ExcelGenerator.generate = appStore.generateExcel

export default ExcelGenerator
