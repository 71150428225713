import React, { useCallback, useMemo } from 'react'
import { Modal, PageLayout, Table, Notification } from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import { css } from '@styled'
const columnClass = css`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const SeasonIndexPage = (props) => (
  <PageLayout
    title="รายการฤดูกาลแข่ง"
    actions={[
      {
        label: 'สร้างฤดูกาล',
        icon: 'plus',
        color: 'primary',
        onClick: props.onCreate,
      },
    ]}>
    <Table
      filters={[
        {
          description: 'กรองด้วยชื่อฤดูกาลแข่ง',
          dataField: 'name',
          type: 'search',
        },
      ]}
      columns={[
        {
          title: 'ชื่อ',
          dataField: 'title',
          sort: true,
          align: 'left',
          type: 'link',
          onClick: props.gotoSeasonPage,
        },
        {
          title: 'จำนวนรอบการแข่ง',
          dataField: 'cycleAmountLimit',
        },
        {
          title: 'เริ่มเมื่อ',
          dataField: 'startedAt',
          sort: true,
          type: 'date',
          className: columnClass,
        },
        {
          title: 'สิ้นสุดเมื่อ',
          dataField: 'endedAt',
          type: 'date',
          className: columnClass,
        },
        {
          title: 'เข้าร่วมได้เมื่อ',
          dataField: 'publishedAt',
          sort: true,
          type: 'date',
          className: columnClass,
        },
        {
          title: 'ลงทะเบียน/สูงสุด',
          dataField: 'registeredLimit',
        },
        {
          title: 'การดำเนินการ',
          type: 'actions',
          actions: [
            {
              label: 'เข้าร่วม',
              icon: 'paper-plane',
              hoverColor: 'primary',
              onClick: props.onPublish,
              visible: (season) => !season.published,
            },
            {
              label: 'แก้ไข',
              icon: 'pen',
              hoverColor: 'primary',
              onClick: props.onEdit,
              visible: (season) => !season.published,
            },
            {
              label: 'ลบ',
              icon: 'trash',
              hoverColor: 'danger',
              onClick: props.onDelete,
              visible: (season) => !season.published,
            },
          ],
        },
      ]}
      entities={props.seasons}
      paging={props.paging}
      onEdit={props.onEdit}
      onQuery={props.onQuery}
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    seasons: stores.seasonStore.seasons,
    fetchSeasons: stores.seasonStore.fetchSeasons,
    paging: stores.seasonStore.paging,
    configs: stores.seasonStore.configs,
    publishSeason: stores.seasonStore.publishSeason,
    deleteSeason: stores.seasonStore.deleteSeason,
  })),
  withHooks((props) => {
    const { seasons, fetchSeasons, deleteSeason, publishSeason, paging } = props
    const onQuery = fetchSeasons
    const onCreate = useCallback(() => {
      paths.currentCompanyPath().seasonsNewPath().push()
    }, [])

    const onEdit = useCallback((season) => {
      paths.currentCompanyPath().seasonEditPath(season.id).push()
    }, [])

    const onDelete = useCallback(
      async (season) => {
        Modal.confirm({
          children: 'Permanent delete this season.',
          onOk: async (modal) => {
            await deleteSeason(season.id)
            await fetchSeasons()
            Notification.success()
            modal.close()
          },
        })
      },
      [deleteSeason, fetchSeasons],
    )

    const onPublish = useCallback(
      async (season) => {
        await publishSeason(season.id)
        Notification.success()
      },
      [publishSeason],
    )

    const gotoSeasonPage = useCallback((season) => {
      paths.currentCompanyPath().seasonPath(season.id).push()
    }, [])

    const customSeasons = useMemo(() => {
      return seasons.map((season) => {
        const registeredAmount = season.registeredAmount || 0
        const attendeeAmountLimit = season.attendeeAmountLimit ?? '∞'
        return {
          ...season,
          registeredLimit: `${registeredAmount}/${attendeeAmountLimit}`,
        }
      })
    }, [seasons])

    return {
      seasons: customSeasons,
      onQuery,
      onCreate,
      onDelete,
      onPublish,
      onEdit,
      paging,
      gotoSeasonPage,
    }
  }),
)

export default enhancer(SeasonIndexPage)
