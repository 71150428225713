import React from 'react'
import { Field, Select, Grid, Button, Typography } from '@components'
import { compose, withHooks } from '@enhancers'
import { times } from 'lodash'

const ArticleTemplateInfo = (props) => (
  <Grid item container direction="column" spacing={2}>
    <Grid item>
      <Typography>บทความที่เกี่ยวข้อง</Typography>
    </Grid>
    <Grid item container spacing={2}>
      {times(props.itemsAmount, (index) => (
        <Grid key={index} item sm={6} xs={12}>
          <Field
            label="บทความ"
            name={`${props.name}[${index}].articleTemplateId`}
            component={Select}
            options={props.articleTemplateOptions}
            placeholder="บทความ"
            helperText="โปรดเลือกบทความ"
            required
            disabled={props.disabled}
          />
          {!props.disabled && (
            <Button
              onClick={() => props.removeArticle(index)}
              icon="trash"
              size="small"
              className="ml-5"
            />
          )}
        </Grid>
      ))}
    </Grid>
    {!props.disabled && (
      <Grid item>
        <Button size="small" color="primary" onClick={props.addArticle}>
          เพิ่มบทความ
        </Button>
      </Grid>
    )}
  </Grid>
)

const enhancer = compose(
  withHooks((props) => {
    const { form, name, push, remove, articleTemplateOptions, disabled } = props

    const itemsAmount = form.getFieldProps(name).value?.length || 0

    return {
      name,
      itemsAmount,
      addArticle: push,
      removeArticle: remove,
      articleTemplateOptions,
      disabled,
    }
  }),
)

export default enhancer(ArticleTemplateInfo)
