import React, { useCallback } from 'react'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import { Field, Notification, Select, Button } from '@components'
import { get } from 'lodash'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

const ArticleModal = (props) => (
  <>
    <DialogContent>
      <Field
        name="articleTemplateId"
        component={Select}
        options={props.articleTemplateOptions}
        label="Article"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onCancel} color="light" size="small">
        Cancel
      </Button>
      <Button
        type="submit"
        onClick={props.handleSubmit}
        color="primary"
        size="small">
        Assign
      </Button>
    </DialogActions>
  </>
)

const enhancer = compose(
  withStores((stores) => ({
    configs: stores.chatStore.configs,
    assignArticle: stores.chatStore.assignArticle,
    closeConfirmModal: stores.appStore.closeConfirmModal,
  })),
  withFormik({
    handleSubmit: async (values, formikBag) => {
      const { handleSelectArticle, sendTextMessage } = get(formikBag, 'props')
      const attendeeId = get(formikBag, 'props.item.id')
      await formikBag.props
        .assignArticle({ ...values, attendeeId })
        .then((response) => {
          const { data } = response.data
          handleSelectArticle(data.message, data.articleId)
          sendTextMessage()
        })

      formikBag.props.closeConfirmModal()
      Notification.success()
    },
  }),
  withHooks((props) => {
    const { handleSubmit, closeConfirmModal, configs } = props
    const onCancel = useCallback(() => {
      closeConfirmModal()
    }, [closeConfirmModal])

    const articleTemplateOptions = get(
      configs,
      'filters.articleTemplate.options',
    )
    return {
      handleSubmit,
      onCancel,
      articleTemplateOptions,
    }
  }),
)

export default enhancer(ArticleModal)
