import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { PageLayout, Table, ExcelGenerator } from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import {
  clone,
  filter,
  get,
  isEqual,
  forEach,
  groupBy,
  map,
  find,
} from 'lodash'
import { format } from 'date-fns'
import { css } from '@styled'
import { cleanFilter } from '@common/helper'

const columnClass = css`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ArticleIndexPage = (props) => (
  <PageLayout
    title="การอ่านบทความ"
    actions={[
      {
        label: 'ออกรายงาน',
        icon: 'download',
        color: 'primary',
        onClick: props.onExport,
      },
    ]}>
    <Table
      $ref={props.$table}
      filters={[
        {
          description: 'กรองด้วยองค์กร',
          dataField: 'companyId',
          type: 'select',
          options: props.companyOptions,
        },
        {
          description: 'กรองด้วยฤดูกาลแข่ง',
          dataField: 'seasonId',
          type: 'select',
          options: props.seasonOptions,
        },
        {
          description: 'กรองด้วยรอบแข่งที่',
          dataField: 'cycleId',
          type: 'select',
          options: props.cycleOptions,
        },
        {
          description: 'กรองด้วยทีม',
          dataField: 'teamId',
          type: 'select',
          options: props.teamOptions,
        },
        {
          description: 'กรองด้วยผู้แข่งขัน',
          dataField: 'attendeeId',
          type: 'select',
          options: props.attendeeOptions,
          className: 'pl-lg-4 pl-md-4',
        },
        {
          description: 'กรองด้วยหมวดหมู่',
          dataField: 'category',
          type: 'select',
          options: props.categoryOptions,
        },
        {
          description: 'กรองด้วยชื่อบทความ (สำหรับ Admin)',
          dataField: 'name',
          type: 'search',
        },
        {
          description: 'กรองด้วยสถานะการอ่าน',
          dataField: 'state',
          type: 'select',
          options: props.stateOptions,
        },
        {
          description: 'กรองด้วยประเภทบทความ',
          dataField: 'articleType',
          type: 'select',
          options: props.articleTypeOptions,
          className: 'pl-lg-4 pl-md-4',
        },
      ]}
      columns={[
        {
          title: 'ชื่อผู้แข่งขัน',
          dataField: 'attendee',
          align: 'left',
          sort: true,
          className: columnClass,
        },
        {
          title: 'ชื่อบทความ (สำหรับ Admin)',
          dataField: 'name',
          align: 'left',
          sort: true,
        },
        {
          title: 'หมวดหมู่',
          dataField: 'category',
        },
        {
          title: 'รางวัล (social coin)',
          dataField: 'socialCoinReward',
          sort: true,
        },
        {
          title: 'สถานะการอ่าน',
          dataField: 'state',
        },
        {
          title: 'องค์กร',
          dataField: 'company',
          className: columnClass,
        },
        {
          title: 'ฤดูกาลแข่ง',
          dataField: 'season',
        },
        {
          title: 'รอบแข่งที่',
          dataField: 'cycle',
        },
        {
          title: 'ทีม',
          dataField: 'team',
          className: columnClass,
        },
        {
          title: 'Cover Image',
          dataField: 'coverImage',
          type: 'image',
          nameKey: 'coverImage',
        },
        {
          title: 'URL',
          dataField: 'url',
          className: columnClass,
        },
      ]}
      entities={props.articles}
      paging={props.paging}
      onQuery={props.onQuery}
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    configs: stores.articleStore.configs,
    articles: stores.articleStore.articles,
    fetchArticles: stores.articleStore.fetchArticles,
    fetchConfigs: stores.articleStore.fetchConfigs,
    paging: stores.articleStore.paging,
  })),
  withHooks((props) => {
    const { articles, fetchArticles, paging, fetchConfigs, configs } = props
    const [queryParams, setQueryParams] = useState({})

    const companyOptions = get(configs, 'filters.companyId.options')
    const categoryOptions = get(configs, 'filters.category.options')
    const stateOptions = get(configs, 'filters.state.options')
    const articleTypeOptions = get(configs, 'filters.articleType.options')

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    const { companyId, seasonId, cycleId, teamId } = queryParams

    const seasonOptions = useMemo(() => {
      const options = get(configs, 'filters.seasonId.options')
      return filter(options, { companyId })
    }, [configs, companyId])

    const cycleOptions = useMemo(() => {
      const options = get(configs, 'filters.cycleId.options', [])
      return filter(options, { seasonId })
    }, [configs, seasonId])

    const teamOptions = useMemo(() => {
      const options = get(configs, 'filters.teamId.options', [])
      return filter(options, { cycleId })
    }, [configs, cycleId])

    const attendeeOptions = useMemo(() => {
      const options = get(configs, 'filters.attendeeId.options', [])
      return filter(options, { teamId })
    }, [configs, teamId])

    const $table = useRef()
    const onQuery = useCallback(
      (params) => {
        const filteredParams = clone(params)

        cleanFilter(filteredParams, 'companyId', companyOptions)
        cleanFilter(filteredParams, 'seasonId', seasonOptions)
        cleanFilter(filteredParams, 'cycleId', cycleOptions)
        cleanFilter(filteredParams, 'teamId', teamOptions)
        cleanFilter(filteredParams, 'attendeeId', attendeeOptions)

        if (!isEqual(params, filteredParams)) {
          $table.current.setFilter(filteredParams)
        } else if (!isEqual(filteredParams, queryParams)) {
          setQueryParams(filteredParams)
          fetchArticles(filteredParams)
        }
      },
      [
        fetchArticles,
        attendeeOptions,
        companyOptions,
        seasonOptions,
        cycleOptions,
        teamOptions,
        queryParams,
      ],
    )

    const onExport = useCallback(async () => {
      const data = await fetchArticles(
        {
          ...$table.current.getQueryParams(),
          page: 1,
          perPage: 1000000,
        },
        { preventSetState: true },
      )

      const dataGroupByTemplate = groupBy(data, 'articleTemplateId')

      const sheets = map(dataGroupByTemplate, (data) => {
        const typeFormQuestionMap = {}

        forEach(data, (record) => {
          const typeform = record.typeformResponse
          if (typeform) {
            forEach(typeform.answers, (answer) => {
              const id = answer.field.id
              switch (answer.type) {
                case 'choice':
                  const question = find(typeform.definition.fields, { id })
                  if (question) {
                    typeFormQuestionMap[id] = question.title
                  }
                  record[id] = answer.choice.label
                  break
                default:
                  break
              }
            })
          }
        })

        const typeFormColumns = map(typeFormQuestionMap, (title, id) => ({
          title,
          field: id,
        }))

        const sheet = {
          name: data[0].title,
          columns: [
            {
              title: 'ชื่อผู้แข่งขัน',
              field: 'attendee',
            },
            {
              title: 'ชื่อบทความ',
              field: 'title',
            },
            {
              title: 'หมวดหมู่',
              field: 'category',
            },
            {
              title: 'รางวัล (social coin)',
              field: 'socialCoinReward',
            },
            {
              title: 'สถานะการอ่าน',
              field: 'state',
            },
            {
              title: 'องค์กร',
              field: 'company',
            },
            {
              title: 'ฤดูกาลแข่ง',
              field: 'season',
            },
            {
              title: 'รอบแข่งที่',
              field: 'cycle',
            },
            {
              title: 'ทีม',
              field: 'team',
            },
            {
              title: 'URL',
              field: 'url',
            },
            ...typeFormColumns,
          ],
          data,
        }

        return sheet
      })

      ExcelGenerator.generate({
        fileName: `articles-${format(new Date(), 'yyyyMMddhhmmss')}`,
        sheets,
      })
    }, [fetchArticles, $table])

    return {
      $table,
      articles,
      onQuery,
      onExport,
      paging,
      companyOptions,
      seasonOptions,
      cycleOptions,
      teamOptions,
      stateOptions,
      categoryOptions,
      attendeeOptions,
      articleTypeOptions,
    }
  }),
)

export default enhancer(ArticleIndexPage)
