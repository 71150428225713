import PeopleAlt from '@material-ui/icons/PeopleAlt'
import paths, { Path } from './paths'

const formatPathToString = (menu) =>
  menu.map((m) => {
    if (m.path instanceof Path) {
      m.path = m.path.toString()
    }
    return m
  })

export const MAIN_MENU = formatPathToString([
  {
    label: 'ผู้ดูแลระบบ',
    Icon: PeopleAlt,
    path: paths.adminsPath(),
    permittedRoles: ['admin'],
  },
  {
    label: 'องค์กร',
    path: paths.companiesPath(),
    icon: 'house-user',
    permittedRoles: ['admin', 'human_resource'],
  },
  {
    label: 'โค้ช',
    path: paths.coachesPath(),
    icon: 'user-secret',
    permittedRoles: ['admin'],
  },
  {
    label: 'ต้นแบบบทความ',
    path: paths.articleTemplatesPath(),
    icon: 'book',
    permittedRoles: ['admin', 'coach'],
  },
  {
    label: 'ต้นแบบภารกิจ',
    path: paths.missionTemplatesPath(),
    icon: 'dice-d6',
    permittedRoles: ['admin', 'coach'],
  },
  {
    label: 'แชท',
    path: paths.chatsPath(),
    icon: 'comments',
    permittedRoles: ['admin', 'coach'],
  },
  {
    label: 'จัดการโค้ชผู้ดูแล',
    path: paths.matchUpPath(),
    icon: 'handshake',
    permittedRoles: ['admin'],
  },
  {
    label: 'ภารกิจ',
    path: paths.missionsPath(),
    icon: 'flag-checkered',
    permittedRoles: ['admin', 'coach'],
  },
  {
    label: 'บทความ',
    path: paths.articlesPath(),
    icon: 'book-reader',
    permittedRoles: ['admin', 'coach'],
  },
  {
    label: 'ระดับความสุข',
    path: paths.happinessScoresPath(),
    icon: 'smile',
    permittedRoles: ['admin', 'coach'],
  },
  {
    label: 'กิจกรรม',
    path: paths.activitiesPath(),
    icon: 'shoe-prints',
    permittedRoles: ['admin'],
  },
])

export const COMPANY_MENU = (companyId) =>
  formatPathToString([
    {
      label: 'ภาพรวม',
      path: paths.companyPath(companyId).dashboardPath(),
      icon: 'chart-line',
      permittedRoles: ['admin', 'human_resource'],
    },
    {
      label: 'ข้อมูลบริษัท',
      path: paths.companyPath(companyId).infoPath(),
      icon: 'info',
      permittedRoles: ['admin', 'human_resource'],
    },
    {
      label: 'ฝ่ายบุคคล',
      path: paths.companyPath(companyId).humanResourcesPath(),
      icon: 'user-tie',
      permittedRoles: ['admin', 'human_resource'],
    },
    {
      label: 'พนักงาน',
      path: paths.companyPath(companyId).employeesPath(),
      icon: 'users',
      permittedRoles: ['admin', 'coach', 'human_resource'],
    },
    {
      label: 'ต้นแบบภารกิจ',
      path: paths.companyPath(companyId).missionTemplatesPath(),
      icon: 'dice-d6',
      permittedRoles: ['admin'],
    },
    {
      label: 'ฤดูกาลแข่ง',
      path: paths.companyPath(companyId).seasonsPath(),
      icon: 'gamepad',
      permittedRoles: ['admin', 'human_resource'],
    },
    {
      label: 'ผู้แข่งขัน',
      path: paths.companyPath(companyId).attendeesPath(),
      icon: 'users',
      permittedRoles: ['admin', 'human_resource'],
    },
    {
      label: 'สะกิดคอยน์',
      path: paths.companyPath(companyId).socialCoinsPath(),
      icon: 'coins',
      permittedRoles: ['admin', 'human_resource'],
    },
    {
      label: 'การประกาศ',
      path: paths.companyPath(companyId).announcementsPath(),
      icon: 'bullhorn',
      permittedRoles: ['admin', 'human_resource'],
    },
  ])

export default {
  MAIN: MAIN_MENU,
  COMPANY: COMPANY_MENU,
}
