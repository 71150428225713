import Checkbox from '@material-ui/core/Checkbox'
import { useCallback } from 'react'
import { compose, withHooks } from '@enhancers'
import { safe } from '@common/helper'
import { useFormikField } from '@hooks'
const enhancer = compose(
  withHooks(props => {
    const getOnChangeValue = useCallback(e => {
      return safe(() => e.target.checked)
    }, [])

    const { onChange, value, ...rest } = useFormikField(props, {
      getOnChangeValue,
    })

    return {
      ...rest,
      checked: !!value,
      onChange,
    }
  }),
)

export default enhancer(Checkbox)
