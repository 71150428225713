import React from 'react'
import {useMemo} from 'react'
import { compose, withHooks } from "@enhancers"
import {useFormikContext } from '@hooks'
import {isFunction, get} from 'lodash'

const FormSpy = props => props.children

const enhancer = compose(
  withHooks(props => {
    const { subscription="", children, hidden } = props
    const { values } = useFormikContext();

    const subscriptionValues = useMemo(() => {
      const subscriptionNames = subscription.split(",")
      const subscriptionValues = subscriptionNames.map(name => get(values, name))
      return subscriptionValues
    }, [subscription, values])

    const customHidden = isFunction(hidden) ? hidden(subscriptionValues) : undefined
    const resolvedChildren = useMemo(() => {
      if (!customHidden) {
        if (isFunction(children)) {
          return children(subscriptionValues)
        } else {
          return children
        }
      } else {
        return <></>
      }
    }, [children, subscriptionValues, customHidden])

    return {
      children: resolvedChildren
    }
  })
)

export default enhancer(FormSpy)