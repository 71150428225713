import React, { useCallback } from 'react'
import { PageLayout, Table } from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import { css } from '@styled'
import paths from '@common/paths'

const columnClass = css`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AnnouncementIndex = (props) => (
  <PageLayout
    title="การประกาศ"
    actions={[
      {
        label: 'สร้างการประกาศ',
        icon: 'plus',
        color: 'primary',
        onClick: props.onCreate,
      },
    ]}>
    <Table
      columns={[
        {
          title: 'ชื่อ',
          dataField: 'title',
          align: 'left',
          type: 'link',
          onClick: props.gotoShowPage,
        },
        {
          title: 'คำอธิบาย',
          dataField: 'description',
          align: 'left',
          className: columnClass,
        },
        {
          title: 'รูปแบบ',
          dataField: 'actionType',
        },
        {
          title: 'ตารางเวลา',
          dataField: 'scheduleTime',
        },
        {
          title: 'ส่งเมื่อ',
          dataField: 'sendAt',
        },
        {
          title: 'สถานะการส่ง',
          dataField: 'status',
        },
      ]}
      entities={props.announcements}
      paging={props.paging}
      onQuery={props.onQuery}
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    fetchAnnouncement: stores.announcementStore.fetchAnnouncement,
    announcements: stores.announcementStore.announcements,
    paging: stores.announcementStore.paging,
  })),
  withHooks((props) => {
    const { fetchAnnouncement, announcements, paging } = props

    const onQuery = fetchAnnouncement

    const onCreate = useCallback(() => {
      paths.currentCompanyPath().announcementsNewPath().push()
    }, [])

    const gotoShowPage = useCallback((announcement) => {
      paths.currentCompanyPath().announcementPath(announcement.id).push()
    }, [])

    return {
      announcements,
      paging,
      onQuery,
      onCreate,
      gotoShowPage
    }
  }),
)

export default enhancer(AnnouncementIndex)
