import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react'
import { withHooks, compose } from '@enhancers'
import { Grid, Link, Button } from '@components'
import { SvgAvatar } from '../../CometChat/util/svgavatar'
import paths from '@common/paths'
import './style.scss'
import { AttendeeAvatar } from '@pages/chats/CometChat/components/ConversationView/index'
import { groupBy, map, sortBy, forEach } from 'lodash'
import { format } from 'date-fns'
import MissionInfoModal from '../../../missions/index/MissionInfoModal'
import server from '@common/api'

const CometChatUserDetail = (props) => {
  if (!props.name) return null
  return (
    <>
      <Grid container alignItems="center">
        <AttendeeAvatar name={props.name} />
        <Link
          className="ml-2"
          onClick={props.gotoInfo}
          style={{ fontSize: 18 }}>
          {props.name}
        </Link>
      </Grid>
      <Grid className="mt-2" container direction="column">
        <Grid item>
          <h4>ข้อมูลทั่วไป</h4>
        </Grid>
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <div className="text-nowrap font-weight-bold">บริษัท:</div>
            <div className="ml-1">{props.companyName ?? '-'}</div>
          </Grid>
          <Grid item>
            <div className="text-nowrap font-weight-bold">ฤดูการแข่งขัน:</div>
            <div className="ml-1">{props.seasonName ?? '-'}</div>
          </Grid>
          <Grid item>
            <div className="text-nowrap font-weight-bold">รอบการแข่งที่:</div>
            <div className="ml-1">{props.cycleNo ?? '-'}</div>
          </Grid>
          <Grid item>
            <div className="text-nowrap font-weight-bold">ทีม:</div>
            <div className="ml-1">{props.teamName ?? '-'}</div>
          </Grid>
          <Grid item>
            <div className="text-nowrap font-weight-bold">คะแนนสุขภาพ:</div>
            <div className="ml-1">{props.healthScore ?? '-'}</div>
          </Grid>
          <Grid item>
            <div className="text-nowrap font-weight-bold">หมายเหตุ:</div>
            <div className="ml-1">{props.note ?? '-'}</div>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className="my-6" alignItems="center">
        <Link onClick={props.gotoMissionHistory}>
          <u>ประวัติการทำภารกิจ</u>
        </Link>
        <div className="mx-1">{' | '}</div>
        <Link onClick={props.gotoHappinessScoreHistory}>
          <u>ประวัติระดับความสุข</u>
        </Link>
      </Grid>

      <Grid container direction="column">
        <Grid item>
          <h4>ภารกิจที่กำลังดำเนินการ</h4>
        </Grid>
        <Grid item container direction="column" spacing={1}>
          {map(props.missionGroups, (missions, name) => (
            <Grid key={name} item container direction="column">
              <Grid item>
                <div className="font-weight-bold">{name}</div>
              </Grid>
              <Grid item container direction="column">
                {map(missions, (mission, index) => (
                  <Grid key={index} item>
                    {mission.canApprove || mission.canReject ? (
                      <Button
                        style={{ width: 30, height: 30, minWidth: 30 }}
                        size="small"
                        icon="check-circle"
                        hoverColor="primary"
                        label="ตรวจ"
                        onClick={() =>
                          props.$missionInfoModal.current?.open(mission)
                        }
                      />
                    ) : mission.state === 'ผ่านแล้ว' ||
                      mission.state === 'ไม่ผ่าน' ? (
                      <Button
                        style={{ width: 30, height: 30, minWidth: 30 }}
                        size="small"
                        icon="eye"
                        hoverColor="primary"
                        label="ดูสถานะ"
                        onClick={() =>
                          props.$missionInfoModal.current?.open(mission)
                        }
                      />
                    ) : (
                      <span style={{ width: 30, height: 30, minWidth: 30 }} />
                    )}
                    <Link
                      className={`ml-1 text-left ${
                        mission.canApprove || mission.canReject
                          ? 'text-danger'
                          : ''
                      }`}
                      wrap
                      onClick={() => props.gotoMissionDetail(mission.id)}>
                      {mission.title}
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <MissionInfoModal
        $ref={props.$missionInfoModal}
        onApproveSuccess={props.refreshMissionInfo}
        onRejectSuccess={props.refreshMissionInfo}
      />
    </>
  )
}

const enhancer = compose(
  withHooks((props) => {
    const { item } = props
    const [activeMissions, setActiveMissions] = useState([])

    useEffect(() => {
      setActiveMissions(item.missions)
    }, [item])

    const $missionInfoModal = useRef()

    const defaultAvatar = useMemo(() => {
      if (item.attendeeName) {
        return SvgAvatar.getAvatar(
          item.attendeeName,
          item.attendeeName.substring(0, 2),
        )
      }
    }, [item.attendeeName])

    const gotoInfo = useCallback(() => {
      window.open(paths.chatInfoPath(item.companyId, item.employeeId), '_blank')
    }, [item])

    const gotoMissionHistory = useCallback(() => {
      window.open(
        paths.missionsPath({
          companyId: item.companyId,
          seasonId: item.seasonId,
          cycleId: item.cycleId,
          teamId: item.teamId,
          attendeeName: item.attendeeName,
        }),
        '_blank',
      )
    }, [
      item.companyId,
      item.seasonId,
      item.cycleId,
      item.teamId,
      item.attendeeName,
    ])

    const gotoHappinessScoreHistory = useCallback(() => {
      window.open(
        paths.happinessScoresPath({
          companyId: item.companyId,
          attendeeName: item.attendeeName,
        }),
        '_blank',
      )
    }, [item.companyId, item.attendeeName])

    const gotoMissionDetail = useCallback((id) => {
      paths.missionPath(id).newTab()
    }, [])

    const missionGroups = useMemo(() => {
      const missions = map(activeMissions, (mission) => {
        const { missionTemplateName, ...rest } = mission
        return {
          ...rest,
          title: `[${format(rest.date, '[dd/MM/yyyy]')}] '${rest.title}'`,
          missionTemplateName: missionTemplateName || 'ภารกิจเพิ่มเติมจากโค้ช',
        }
      })

      const groups = groupBy(missions, 'missionTemplateName')
      forEach(groups, (group, index) => {
        groups[index] = sortBy(group, 'date')
      })

      return groups
    }, [activeMissions])

    const attendeeId = item?.id
    const refreshMissionInfo = useCallback(
      async (mission) => {
        if (attendeeId) {
          const response = await server.get(`/chats/attendees/${attendeeId}`)
          const attendee = response.data.data.attendee

          setActiveMissions(attendee.missions)
        }
      },
      [attendeeId],
    )

    return {
      ...item,
      gotoMissionHistory,
      gotoHappinessScoreHistory,
      gotoMissionDetail,
      gotoInfo,
      defaultAvatar,
      missionGroups,
      $missionInfoModal,
      refreshMissionInfo,
    }
  }),
)

export default enhancer(CometChatUserDetail)
