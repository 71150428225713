import { BaseStore } from './index'
import server from '../common/api'

class AnnouncementStore extends BaseStore {
  constructor() {
    super('announcementStore', {
      announcements: [],
      configs: {},
    })
  }

  fetchConfigs = async () => {
    const response = await server.get(`/notification_schedules/configs`)
    const configs = response.data.data.configs
    this.setState({
      configs,
    })
  }

  fetchAnnouncement = async (params) => {
    const response = await server.get(`/notification_schedules`, params)
    const { notificationSchedule } = response.data.data
    this.setState({
      announcements: notificationSchedule,
    })
  }

  createAnnouncement = async (params) => {
    await server.post(`/notification_schedules`, params)
  }
}

export default new AnnouncementStore()
