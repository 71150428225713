import React, { useCallback, useEffect, useState } from 'react'
import {
  Grid,
  Field,
  PageLayout,
  Input,
  DateTimePicker,
  Select,
} from '@components'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import { get } from 'lodash'
import CKEditor from 'ckeditor4-react'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '1400px',
    height: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2);',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'canter',
    width: '48%',
    padding: '20px',
  },
  previewContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#EBECF0',
    padding: '20px',
    width: '100%',
    height: '100%',
    minHeight: '450px',
    marginBottom: '20px',
  },
  dropZone: {
    maxWidth: '45%',
    maxHeight: 250,
  },
  textField: {
    marginRight: theme.spacing(1),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginRight: theme.spacing(1),
    width: '48%',
  },
  description: {
    marginRight: theme.spacing(1),
    width: '48%',
  },
  action: {
    marginRight: theme.spacing(1),
    width: '48%',
  },
  scheduleTime: {
    marginRight: theme.spacing(1),
    width: '48%',
  },
  phoneNumber: {
    marginRight: theme.spacing(1),
    width: '48%',
  },
  AndroidMainLayout: {
    position: 'relative',
    display: 'flex',
    backgroundImage: 'url("/media/android.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    height: '100%',
    width: '100%',
  },
  AndroidMessageCard: {
    width: '100%',
    height: '56px',
    background: 'white',
    position: 'absolute',
    top: '70px',
    maxWidth: '345px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: '0',
    right: '0',
    display: 'flex',
    padding: '8px',
  },
  IOSMainLayout: {
    position: 'relative',
    display: 'flex',
    backgroundImage: 'url("/media/long_ios.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    height: '100%',
    width: '100%',
    marginTop: '24px',
  },
  IOSMessageCard: {
    width: '100%',
    height: '56px',
    background: 'white',
    position: 'absolute',
    top: '62px',
    maxWidth: '345px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: '0',
    right: '0',
    borderRadius: '8px',
    display: 'flex',
    padding: '8px',
  },
  MessageMainLayout: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
  },
  TextOS: {
    marginTop: '24px',
    fontSize: '13px',
  },
  previewImg: {
    width: '40px',
    height: '40px',
  },
  MessageTitle: {
    fontSize: '10px',
    textAlign: 'left',
    fontWeight: 'bold'
  },
  MessageDescription: {
    fontSize: '10px',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    whiteSpace: 'pre',
    overflow: 'hidden',
    width: '100%',
    maxWidth: '250px',
  },
}))

export const AnnouncementShowPage = (props) => (
  <PageLayout
    title={props.title}
    actions={[
      {
        label: 'กลับ',
        icon: 'arrow-left',
        onClick: props.onBack,
      },
    ]}>
    <Grid container spacing={3} justify="center">
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <div className={props.classes.previewContent}>
          <div className={props.classes.AndroidMainLayout}>
            <div className={props.classes.AndroidMessageCard}>
              <div className={props.classes.MessageMainLayout}>
                <div className={props.classes.MessageTitle}>
                  {get(props, 'values.title')}
                </div>
                <div className={props.classes.MessageDescription}>
                  {get(props, 'values.description')}
                </div>
              </div>
              <img
                className={props.classes.previewImg}
                src="/media/logos/favicon.ico"
                alt=""
              />
            </div>
          </div>
          <div className={props.classes.TextOS}>Android</div>
          <div className={props.classes.IOSMainLayout}>
            <div className={props.classes.IOSMessageCard}>
              <div className={props.classes.MessageMainLayout}>
                <div className={props.classes.MessageTitle}>
                  {get(props, 'values.title')}
                </div>
                <div className={props.classes.MessageDescription}>
                  {get(props, 'values.description')}
                </div>
              </div>
              <img
                className={props.classes.previewImg}
                src="/media/logos/favicon.ico"
                alt=""
              />
            </div>
          </div>
          <div className={props.classes.TextOS}>iOS</div>
        </div>
      </Grid>
    </Grid>
    <Grid container spacing={3} className="mt-10">
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ชื่อหัวข้อ"
          name="title"
          component={Input}
          placeholder="ชื่อหัวข้อ"
          required
          helperText="โปรดใส่ชื่อหัวข้อเฉพาะ"
          disabled
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="คำอธิบาย"
          name="description"
          component={Input}
          placeholder="คำอธิบาย"
          helperText="โปรดระบุคำอธิบาย"
          required
          disabled
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="กำหนดเวลาที่ส่ง"
          name="scheduleTime"
          component={DateTimePicker}
          placeholder="กำหนดเวลาที่ส่ง"
          helperText="โปรดระบุกำหนดเวลาที่ส่ง"
          required
          disabled
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="รหัสพนักงาน"
          name="codes"
          component={Select}
          options={props.codeOptions}
          multiple
          placeholder="รหัสพนักงาน"
          helperText="โปรดเลือกรหัสพนักงาน"
          disabled
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="รูปแบบ"
          name="actionType"
          component={Select}
          options={props.actionOptions}
          placeholder="รูปแบบ"
          required
          helperText="โปรดเลือกรูปแบบ"
          onChange={props.onSelectActionType}
          disabled
        />
      </Grid>
      {props.action === 'html' && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CKEditor
            config={{
              fullPage: true,
              allowedContent: true,
              width: '100%',
              height: '100%',
              resize_dir: 'both',
              removeButtons:
                'Paste,PasteText,PasteFromWord,About,Scayt',
            }}
            readOnly={true}
            data={props.values.templateHtml}
          />
        </Grid>
      )}
      {props.action === 'web_view' && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Field
            label="Web View"
            name="url"
            component={Input}
            placeholder="Web View"
            helperText="Please input link web view."
            required
            disabled
          />
        </Grid>
      )}
    </Grid>
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    configs: stores.announcementStore.configs,
    fetchConfigs: stores.announcementStore.fetchConfigs,
    fetchAnnouncement: stores.announcementStore.fetchAnnouncement,
    announcements: stores.announcementStore.announcements || {},
  })),
  withFormik({
    mapPropsToValues: (props) => props.announcements[0],
  }),
  withHooks((props) => {
    const {
      setFieldValue,
      fetchConfigs,
      configs,
      values,
      fetchAnnouncement,
    } = props

    const [action, setAction] = useState(null)
    const { id } = useParams()

    useEffect(() => {
      fetchAnnouncement({ ids: [id] })
      fetchConfigs()
    }, [fetchConfigs, fetchAnnouncement, id])

    const actionOptions = get(configs, 'attributes.actionType.options')
    const codeOptions = get(configs, 'attributes.code.options')

    const onBack = useCallback(() => {
      paths.currentCompanyPath().announcementsPath().push()
    }, [])

    const classes = useStyles()

    const onSelectActionType = useCallback((value) => {
      setAction(value)
    }, [])

    const handleChangeHtml = useCallback(
      (event) => {
        setFieldValue('templateHtml', event.editor.getData())
      },
      [setFieldValue],
    )

    return {
      onBack,
      title: `การประกาศ '${values.title}'`,
      actionOptions,
      classes,
      onSelectActionType,
      action: action || values.actionType,
      handleChangeHtml,
      values,
      codeOptions,
    }
  }),
)

export default enhancer(AnnouncementShowPage)
