import React from 'react'
import { compose, withHooks, withStores } from '@enhancers'
import { get, isFunction, isString } from 'lodash'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from './Button'
import appStore from '@stores/appStore'

const ModalComponent = ({
  isShow,
  title,
  children,
  cancelButtonLabel,
  okButtonLabel,
  disabled,
  fullWidth,
  maxWidth,
  onOk,
  onCancel,
  onClose,
  hideFooter,
  ...rest
}) => (
  <Dialog
    open={isShow}
    onClose={disabled ? undefined : onClose}
    aria-labelledby="global-modal-title"
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    {...rest}>
    <DialogTitle id="global-modal-title">{title}</DialogTitle>
    <DialogContent>
      {isString(children) ? (
        <DialogContentText style={{ fontSize: 16 }}>
          {children}
        </DialogContentText>
      ) : isFunction(children) ? (
        children()
      ) : (
        children
      )}
    </DialogContent>
    {!hideFooter ? (
      <DialogActions>
        {onCancel && (
          <Button
            onClick={onCancel}
            color="light"
            size="small"
            disabled={disabled}>
            {cancelButtonLabel}
          </Button>
        )}
        {onOk && (
          <Button
            onClick={onOk}
            color="primary"
            size="small"
            disabled={disabled}>
            {okButtonLabel}
          </Button>
        )}
      </DialogActions>
    ) : null}
  </Dialog>
)

const enhancer = compose(
  withStores((stores, props) =>
    props.storeName ? get(stores, props.storeName) : {},
  ),
  withHooks((props) => {
    const { storeName, dispatch, ...rest } = props
    return rest
  }),
)

const Modal = enhancer(ModalComponent)

Modal.open = appStore.openMainModal
Modal.close = appStore.closeMainModal
Modal.alert = appStore.openAlertModal
Modal.confirm = appStore.openConfirmModal

export default Modal
