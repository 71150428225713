import React, { useCallback, useRef } from 'react'
import {
  Modal,
  PageLayout,
  Table,
  Notification,
  ExcelGenerator,
} from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import { format } from 'date-fns'

const HumanResourcePage = (props) => (
  <PageLayout
    title="ฝ่ายบุคคล"
    actions={[
      {
        label: 'ออกรายงาน',
        icon: 'download',
        color: 'primary',
        onClick: props.onExport,
      },
      {
        label: 'เพิ่มฝ่ายบุคคล',
        icon: 'plus',
        color: 'primary',
        onClick: props.onCreate,
      },
    ]}>
    <Table
      $ref={props.$table}
      columns={[
        {
          title: 'อีเมล',
          dataField: 'email',
          sort: true,
          align: 'left',
        },
        {
          title: 'ชื่อ',
          dataField: 'name',
          sort: true,
          align: 'left',
        },
        {
          title: 'วันที่ยืนยันอีเมล',
          dataField: 'invitationConfirmedAt',
          type: 'date',
        },
        {
          title: 'จำนวนวันที่เข้าระบบ',
          dataField: 'activeDayAmount',
        },
        {
          title: 'ใช้ระบบล่าสุด',
          dataField: 'lastActiveAt',
          type: 'date',
        },
        {
          title: 'แพลทฟอร์ม',
          dataField: 'platform',
        },
        {
          title: 'IP',
          dataField: 'ip',
        },
        {
          title: 'ชื่อเบราว์เซอร์',
          dataField: 'browserName',
        },
        {
          title: 'รุ่นเบราว์เซอร์',
          dataField: 'browserVersion',
        },
        {
          title: 'การดำเนินการ',
          type: 'actions',
          actions: [
            {
              label: 'ส่งอีเมลยืนยันอีกครั้ง',
              icon: 'paper-plane',
              hoverColor: 'primary',
              onClick: props.onResend,
              visible: (hr) => !hr.invitationConfirmedAt,
            },
            {
              label: 'แก้ไข',
              icon: 'pen',
              hoverColor: 'primary',
              onClick: props.onEdit,
            },
            {
              label: 'ลบ',
              icon: 'trash',
              hoverColor: 'danger',
              onClick: props.onDelete,
            },
          ],
        },
      ]}
      entities={props.humanResources}
      paging={props.paging}
      onQuery={props.onQuery}
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    humanResources: stores.humanResourceStore.humanResources,
    fetchHumanResources: stores.humanResourceStore.fetchHumanResources,
    deleteHumanResource: stores.humanResourceStore.deleteHumanResource,
    resendVerifyHrEmail: stores.humanResourceStore.resendVerifyHrEmail,
    paging: stores.humanResourceStore.paging,
    configs: stores.humanResourceStore.configs,
  })),
  withHooks((props) => {
    const {
      humanResources,
      fetchHumanResources,
      deleteHumanResource,
      resendVerifyHrEmail,
      paging,
    } = props
    const onQuery = fetchHumanResources

    const onCreate = useCallback(() => {
      paths.currentCompanyPath().humanResourcesNewPath().push()
    }, [])

    const onDelete = useCallback(
      async (humanResource) => {
        Modal.confirm({
          children: 'Permanent delete this humanResource.',
          onOk: async (modal) => {
            await deleteHumanResource(humanResource.id)
            await fetchHumanResources()
            Notification.success()
            modal.close()
          },
        })
      },
      [deleteHumanResource, fetchHumanResources],
    )

    const onResend = useCallback(
      async (humanResource) => {
        await resendVerifyHrEmail(humanResource.id)
        Notification.success()
      },
      [resendVerifyHrEmail],
    )

    const $table = useRef()

    const onExport = useCallback(async () => {
      const data = await fetchHumanResources(
        {
          ...$table.current.getQueryParams(),
          page: 1,
          perPage: 1000000,
        },
        { preventSetState: true },
      )
      await ExcelGenerator.generate({
        fileName: `humanResources-${format(new Date(), 'yyyyMMddhhmmss')}`,
        columns: [
          {
            title: 'อีเมล',
            field: 'email',
          },
          {
            title: 'ชื่อ',
            field: 'name',
          },
          {
            title: 'วันที่ยืนยันอีเมล',
            field: 'invitationConfirmedAt',
          },
          {
            title: 'จำนวนวันที่เข้าระบบ',
            field: 'activeDayAmount',
          },
          {
            title: 'ใช้ระบบล่าสุด',
            field: 'lastActiveAt',
          },
          {
            title: 'แพลทฟอร์ม',
            field: 'platform',
          },
          {
            title: 'IP',
            field: 'ip',
          },
          {
            title: 'ชื่อเบราว์เซอร์',
            field: 'browserName',
          },
          {
            title: 'รุ่นเบราว์เซอร์',
            field: 'browserVersion',
          },
        ],
        data,
      })
    }, [fetchHumanResources, $table])

    const onEdit = useCallback((hr) => {
      paths.currentCompanyPath().humanResourceEditPath(hr.id).push()
    }, [])

    return {
      $table,
      humanResources,
      onQuery,
      onCreate,
      onDelete,
      onResend,
      paging,
      onExport,
      onEdit,
    }
  }),
)

export default enhancer(HumanResourcePage)
