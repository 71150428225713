import React from 'react'
import { compose, withFormik, withHooks } from '@enhancers'
import { Grid, Field, Button, Input } from '@components'
import * as yup from 'yup'

const AddSocialCoinModal = (props) => (
  <Grid container spacing={2}>
    <Grid item sm={6} xs={12}>
      <Field
        label="จำนวนเหรียญ"
        name="amount"
        component={Input}
        placeholder="จำนวนเหรียญ"
        required
        type="number"
        helperText="หากต้องการลดเหรียญให้ใส่ค่าลบ"
      />
    </Grid>
    <Grid item sm={6} xs={12}>
      <Field
        label="คำอธิบาย"
        name="description"
        component={Input}
        placeholder="คำอธิบาย"
        required
        helperText="โปรดใส่คำอธิบายเฉพาะ"
      />
    </Grid>
    <Grid item container justify="flex-end">
      <Button
        type="submit"
        onClick={props.handleSubmit}
        color="primary"
        size="small">
        ให้เหรียญ
      </Button>
    </Grid>
  </Grid>
)

const enhancer = compose(
  withFormik({
    validationSchema: yup.object().shape({
      amount: yup.number().required(),
      description: yup.string().required(),
    }),
    handleSubmit: async (values, formikBag) => {
      if (formikBag.props.onSubmit) {
        await formikBag.props.onSubmit(values)
      }
    },
  }),
  withHooks((props) => {
    const { handleSubmit } = props
    return {
      handleSubmit,
    }
  }),
)

export default enhancer(AddSocialCoinModal)
