/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'
import objectPath from 'object-path'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import { useHtmlClassService } from '../../_core/MetronicLayout'
import { toAbsoluteUrl } from '../../../_helpers'
import { NavLink } from 'react-router-dom'
import { AsideMenu } from './aside-menu/AsideMenu'
import { QuickUserToggler } from '../extras/QuickUserToggler'
import { Brand } from '../brand/Brand'
import { compose, withHooks, withStores } from '@enhancers'
import { MAIN_MENU } from '@menu'
import { css } from '@styled'
import classNames from 'classnames'

const notificationClass = css`
  position: absolute;
  top: 0px;
  right: 0;
  color: red;
  height: 8px;
  background: red;
  border-radius: 50%;
  width: 8px;
  &.hide {
    display: none;
  }
`

const AsideComponent = (props) => (
  <div id="kt_aside" className={'aside aside-left d-flex aside-fixed'}>
    <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
      <Brand />
      <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid scroll scroll-pull ps">
        <ul className="list-unstyled flex-column" role="tablist">
          {props.menu.map(({ label, path, Icon, icon }) => (
            <li key={label} className="nav-item mb-2">
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>{label}</Tooltip>}>
                <NavLink
                  className="position-relative nav-link btn btn-icon btn-clean btn-lg"
                  to={path}>
                  {label === 'แชท' && (
                    <div
                      className={`${notificationClass} ${props.wrapperClassName}`}
                    />
                  )}
                  <span className="svg-icon svg-icon-lg">
                    {Icon && <Icon />}
                    {icon && <i className={`fa fa fa-${icon}`}></i>}
                  </span>
                </NavLink>
              </OverlayTrigger>
            </li>
          ))}
        </ul>
      </div>

      <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
        {props.layoutProps.extrasSearchDisplay && (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="toggle-search">Quick Search</Tooltip>}>
            <a
              href="#"
              className="btn btn-icon btn-clean btn-lg mb-1"
              id="kt_quick_search_toggle">
              <span className="svg-icon svg-icon-lg">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/General/Search.svg')}
                />
              </span>
            </a>
          </OverlayTrigger>
        )}

        {props.layoutProps.extrasNotificationsDisplay && (
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="toggle-notifications">Notifications</Tooltip>
            }>
            <a
              href="#"
              className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
              id="kt_quick_notifications_toggle"
              data-placement="right"
              data-container="body"
              data-boundary="window">
              <span className="svg-icon svg-icon-lg">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')}
                />
              </span>
            </a>
          </OverlayTrigger>
        )}

        <QuickUserToggler />
      </div>
    </div>

    <div className="aside-secondary d-flex flex-row-fluid">
      <div className="aside-workspace scroll scroll-push ps ps--active-y">
        <div className="tab-content">
          <AsideMenu currentRole={props.currentRole} />
        </div>
      </div>
    </div>
  </div>
)

const enhancer = compose(
  withStores((stores) => ({
    currentRole: stores.authStore.currentRole,
    hasMessageIncoming: stores.chatStore.hasMessageIncoming,
  })),
  withHooks(({ currentRole, hasMessageIncoming }) => {
    const uiService = useHtmlClassService()
    const layoutProps = useMemo(() => {
      return {
        asideClassesFromConfig: uiService.getClasses('aside', true),
        asideSecondaryDisplay: objectPath.get(
          uiService.config,
          'aside.secondary.display',
        ),
        asideSelfMinimizeToggle: objectPath.get(
          uiService.config,
          'aside.self.minimize.toggle',
        ),
        extrasSearchDisplay: objectPath.get(
          uiService.config,
          'extras.search.display',
        ),
        extrasNotificationsDisplay: objectPath.get(
          uiService.config,
          'extras.notifications.display',
        ),
        extrasQuickActionsDisplay: objectPath.get(
          uiService.config,
          'extras.quick-actions.display',
        ),
        extrasQuickPanelDisplay: objectPath.get(
          uiService.config,
          'extras.quick-panel.display',
        ),
        extrasLanguagesDisplay: objectPath.get(
          uiService.config,
          'extras.languages.display',
        ),
        extrasUserDisplay: objectPath.get(
          uiService.config,
          'extras.user.display',
        ),
      }
    }, [uiService])

    const menu = useMemo(() => {
      return MAIN_MENU.filter((m) => m.permittedRoles.includes(currentRole))
    }, [currentRole])

    const wrapperClassName = classNames({
      hide: !hasMessageIncoming,
    })

    return {
      layoutProps,
      menu,
      currentRole,
      wrapperClassName,
    }
  }),
)

export const Aside = enhancer(AsideComponent)
