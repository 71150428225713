import React, { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  Field,
  Input,
  PageLayout,
  Grid,
  Notification,
  Image,
  Select,
} from '@components'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import * as yup from 'yup'
import { get } from 'lodash'

const ArticleTemplateEditPage = (props) => (
  <PageLayout
    title={props.title}
    actions={[
      {
        label: 'กลับ',
        icon: 'arrow-left',
        onClick: props.onBack,
      },
      {
        label: 'ล้างค่าที่กรอก',
        icon: 'redo',
        onClick: props.onReset,
      },
      {
        label: 'บันทึก',
        icon: 'save',
        color: 'primary',
        onClick: props.onSave,
        type: 'submit',
      },
    ]}>
    <Grid
      container
      spacing={1}
      className="mb-20"
      justify="center"
      style={{ marginBottom: '20px' }}>
      <Field
        name="coverImage"
        component={Image}
        src={props.coverImageUrl}
        variant="rounded"
        size={400}
        ratio="16:9"
      />
    </Grid>
    <Grid container spacing={3}>
      <Grid item container spacing={3}>
        <Grid item lg={4} md={5} sm={6} xs={12}>
          <Field
            label="ชื่อบทความ (สำหรับ Admin)"
            name="name"
            component={Input}
            placeholder="ชื่อบทความ (สำหรับ Admin)"
            required
            suggestMaxLength={70}
            helperText="โปรดระบุชื่อบทความ (สำหรับ Admin)"
          />
        </Grid>
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ชื่อบทความ (แสดงใน App)"
          name="title"
          component={Input}
          placeholder="ชื่อบทความ (แสดงใน App)"
          required
          suggestMaxLength={70}
          helperText="โปรดระบุชื่อบทความ (แสดงใน App)"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="หมวดหมู่บทความ"
          name="category"
          component={Select}
          options={props.categoryOptions || []}
          placeholder="หมวดหมู่บทความ"
          helperText="โปรดเลือกหมวดหมู่บทความ"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ประเภทบทความ"
          name="articleType"
          component={Select}
          options={props.articleTypeOptions}
          placeholder="ประเภทบทความ"
          helperText="โปรดเลือกประเภทบทความ"
          required
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="Url"
          name="url"
          component={Input}
          placeholder="Url"
          required
          type="text"
          helperText="โปรดระบุ Url"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="รางวัล (social coin)"
          name="socialCoinReward"
          component={Input}
          placeholder="รางวัล (social coin)"
          helperText="โปรดระบุรางวัล (social coin)"
          required
          type="number"
          inputProps={{ min: 0 }}
        />
      </Grid>
    </Grid>
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    configs: stores.articleTemplateStore.configs,
    currentArticleTemplate: stores.articleTemplateStore.currentArticleTemplate,
    fetchConfigs: stores.articleTemplateStore.fetchConfigs,
    findArticleTemplate: stores.articleTemplateStore.findArticleTemplate,
    updateArticleTemplate: stores.articleTemplateStore.updateArticleTemplate,
  })),
  withFormik({
    mapPropsToValues: (props) => props.currentArticleTemplate || {},
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      title: yup.string().required(),
      url: yup.string().required(),
      socialCoinReward: yup.number().required().positive().integer(),
    }),
    handleSubmit: async (values, formikBag) => {
      const { id, ...params } = values
      await formikBag.props.updateArticleTemplate(id, params)
      Notification.success()
    },
  }),
  withHooks((props) => {
    const {
      handleReset,
      handleSubmit,
      configs,
      currentArticleTemplate,
      findArticleTemplate,
      fetchConfigs,
    } = props
    const { templateId } = useParams()

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    useEffect(() => {
      findArticleTemplate(templateId)
    }, [findArticleTemplate, templateId])

    const categoryOptions = get(configs, 'attributes.category.options', [
      { label: 'อาหาร', value: 'food' },
    ])

    const articleTypeOptions = get(configs, 'attributes.articleType.options')

    const onBack = useCallback(() => {
      paths.articleTemplatesPath().push()
    }, [])

    const articleTemplateName = currentArticleTemplate
      ? currentArticleTemplate.title
      : ''
    const title = useMemo(() => {
      return `แก้ไขบทความ '${articleTemplateName}'`
    }, [articleTemplateName])

    const coverImageUrl = useMemo(() => {
      return get(currentArticleTemplate, 'coverImageUrl')
    }, [currentArticleTemplate])

    return {
      onBack,
      onReset: handleReset,
      onSave: handleSubmit,
      categoryOptions,
      title,
      coverImageUrl,
      articleTypeOptions,
    }
  }),
)

export default enhancer(ArticleTemplateEditPage)
