import ActiveStore from './ActiveStore'
import { reject } from 'lodash'

class CompanyMissionTemplateStore extends ActiveStore {
  constructor() {
    super('companyMissionTemplate')
  }

  setPublished = (missionTemplateId, published) => {
    const companyMissionTemplates = [
      ...reject(this.state.companyMissionTemplates, {
        missionTemplateId,
      }),
      { missionTemplateId, published },
    ]

    this.setState({ companyMissionTemplates })
  }

  publishCompanyMissionTemplate = async (id) => {
    await this.server.post('/publish', { mission_template_ids: [id] })
    this.setPublished(id, true)
  }

  unpublishedCompanyMissionTemplate = async (id) => {
    await this.server.post('/unpublish', { mission_template_ids: [id] })
    this.setPublished(id, false)
  }
}

export default new CompanyMissionTemplateStore()
