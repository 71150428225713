/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { Button, Tooltip } from '@components'
import { isFunction } from 'lodash'
import classnames from 'classnames'

const ActionsColumnFormatter = (cellContent, row, rowIndex, props) => {
  let visibleCount = 0
  return props.actions.map(
    (
      {
        id,
        className,
        label,
        icon,
        color,
        hoverColor,
        onClick,
        hidden,
        visible = true,
        tooltipProps = { placement: 'top' },
      },
      index,
    ) => {
      if (isFunction(hidden)) {
        hidden = hidden(row)
      }
      if (hidden) {
        return null
      }
      if (isFunction(visible)) {
        visible = visible(row)
      }
      if (isFunction(label)) {
        label = label(row)
      }

      visibleCount = visibleCount + 1

      return (
        <Tooltip key={Math.random()} title={label} {...tooltipProps}>
          <div
            className={classnames(
              'd-inline-flex',
              visibleCount === 1 ? 'mx-2' : 'mr-2',
              {
                invisible: !visible,
              },
            )}>
            <Button
              id={id}
              className={className}
              color={color}
              hoverColor={hoverColor}
              onClick={() => onClick && onClick(row)}
              icon={icon}
              size="small"
            />
          </div>
        </Tooltip>
      )
    },
  )
}

export default ActionsColumnFormatter
