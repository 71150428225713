// prettier-ignore
const HEALTH_INFO_CONFIG = [
  {
    title: 'สารประกอบชีวเคมีในเลือด',
    items: [
      { name: 'fbs'    , unit: "mg/dL" , label: 'ปริมาณน้ำตาล'     , placeholder: 'ปริมาณน้ำตาล'     },
      { name: 'ua'     , unit: "mg/dL" , label: 'กรดยูริค'         , placeholder: 'กรดยูริค'         },
      { name: 'tc'     , unit: "mg/dL" , label: 'คอเลสเตอรอลรวม'   , placeholder: 'คอเลสเตอรอลรวม'   },
      { name: 'tg'     , unit: "mg/dL" , label: 'ไตรกลีเซอไรด์'    , placeholder: 'ไตรกลีเซอไรด์'    },
      { name: 'hdl'    , unit: "mg/dL" , label: 'เอชดีแอล (HDL)'   , placeholder: 'เอชดีแอล (HDL)'   },
      { name: 'ldl'    , unit: "mg/dL" , label: 'แอลดีแอล (LDL)'   , placeholder: 'แอลดีแอล (LDL)'   },
      { name: 'hb_a1c' , unit: "%"     , label: 'น้ำตาลเฉลี่ยสะสม' , placeholder: 'น้ำตาลเฉลี่ยสะสม' },
      { name: 'na'     , unit: "mmol/L", label: 'โซเดียม'          , placeholder: 'โซเดียม'          },
      { name: 'k'      , unit: "mmol/L", label: 'โพแทสเซียม'       , placeholder: 'โพแทสเซียม'       },
      { name: 'ci'     , unit: "mmol/L", label: 'คลอไรด์'          , placeholder: 'คลอไรด์'          },
      { name: 'co2'    , unit: "mmol/L", label: 'คาร์บอนไดออกไซด์' , placeholder: 'คาร์บอนไดออกไซด์' },
      { name: 'ca'     , unit: "mg/dL" , label: 'แคลเซียม'         , placeholder: 'แคลเซียม'         },
      { name: 'p'      , unit: "mg/dL" , label: 'ฟอสฟอรัส'         , placeholder: 'ฟอสฟอรัส'         },
      { name: 'mg'     , unit: "mg/dL" , label: 'แมกนีเซียม'       , placeholder: 'แมกนีเซียม'       },
    ],
  },
  {
    title: 'การทำงานของไต',
    items: [
      { name: 'bun'                  , unit: "mg/dL"         , label: 'บียูเอ็น (BUN)'          , placeholder: 'บียูเอ็น (BUN)'          },
      { name: 'creat'                , unit: "mg/dL"         , label: 'ครีเอทินิน (Creatinine)' , placeholder: 'ครีเอทินิน (Creatinine)'      },
      { name: 'e_gfr'                , unit: "mL/min/1.73sqm", label: 'ค่าการกรองของไต'         , placeholder: 'ค่าการกรองของไต'         },
      { name: 'urine_creatinine'     , unit: "g/24hr"        , label: 'Urine Creatinine(24 Hr)' , placeholder: 'Urine Creatinine(24 Hr)' },
      { name: 'urine_protein'        , unit: "mg/24hr"       , label: 'Urine Protein (24 Hr)'   , placeholder: 'Urine Protein (24 Hr)'   },
      { name: 'mau_quanti'           , unit: ""              , label: 'MAU-Quanti.'           , placeholder: 'MAU-Quanti.'        },
      { name: 'creatinine_clearance' , unit: ""              , label: 'Creatinine clearance'    , placeholder: 'Creatinine clearance'    },
    ],
  },
  {
    title: "การทำงานของตับ",
    items: [
      { name: "total_protien"     , unit: "g/dL", label: "ปริมาณโปรตีนรวมในกระแสเลือด", placeholder: "ปริมาณโปรตีนรวมในกระแสเลือด"},
      { name: "alb"               , unit: "g/dL", label: "ปริมาณโปรตีนอัลบูมิน"       , placeholder: "ปริมาณโปรตีนอัลบูมิน"       },
      { name: "glo"               , unit: "g/dL", label: "ปริมาณโปรตีนโกลบูลิน"       , placeholder: "ปริมาณโปรตีนโกลบูลิน"       },
      { name: "total_bilirubin"   , unit: "mg/dL", label: "Total Bilirubin"            , placeholder: "Total Bilirubin"            },
      { name: "direct_bilirubin"  , unit: "mg/dL", label: "Direct Bilirunbin"          , placeholder: "Direct Bilirunbin"          },
      { name: "indirect_bilirubin", unit: "mg/dL", label: "Indirect Bilirunbin"        , placeholder: "Indirect Bilirunbin"        },
      { name: "alp"               , unit: "U/L", label: "Alkaline phosphatase"       , placeholder: "Alkaline phosphatase"       },
      { name: "sgot"              , unit: "U/L", label: "เอสจีโอที (SGOT/AST)"       , placeholder: "เอสจีโอที (SGOT/AST)"       },
      { name: "sgpt"              , unit: "U/L", label: "เอสจีพีที (SGPT/ALT)"       , placeholder: "เอสจีพีที (SGPT/ALT)"       },
    ]
  },
  {
    title: "Cardiac marker",
    items: [
      { name: "cpk"    , unit: "U/L", label: "CPK"        , placeholder: "CPK"        },
      { name: "trop_t" , unit: ""   , label: "Troponin T" , placeholder: "Troponin T" },
      { name: "ldh"    , unit: "U/L", label: "LDH"        , placeholder: "LDH"        },
      { name: "ck_mb"  , unit: "U/L", label: "CK-MB"      , placeholder: "CK-MB"      },
    ]
  },
  {
    title: "การอักเสบ/การติดเชื้อ",
    items: [
      { name: "influenza_virus_ag_type_b"         , unit: ""     , label: "Influenza virus Ag type B"        , placeholder: "Influenza virus Ag type B"        },
      { name: "esr"                               , unit: "mm/Hr", label: "ESR"                              , placeholder: "ESR"                              },
      { name: "malaria"                           , unit: ""     , label: "Malaria"                          , placeholder: "Malaria"                          },
      { name: "h1n1"                              , unit: ""     , label: "Influenza virus Ag type A (H1N1)" , placeholder: "Influenza virus Ag type A (H1N1)" },
      { name: "influenza_virus_ag_a_b_screening " , unit: ""     , label: "Influenza virus Ag A/B Screening" , placeholder: "Influenza virus Ag A/B Screening" },
      { name: "anti_hiv"                          , unit: ""     , label: "Anti HIV (screening)"             , placeholder: "Anti HIV (screening)"             },
      { name: "hbs_ag_eclia"                      , unit: ""     , label: "HBs Ag - ECLIA"                   , placeholder: "HBs Ag - ECLIA"                   },
      { name: "anti_hbs_eclia"                    , unit: "IU/L" , label: "Anti HBs - ECLIA"                 , placeholder: "Anti HBs - ECLIA"                 },
      { name: "anti_hbc"                          , unit: ""     , label: "Anti-HBc (Total)"                 , placeholder: "Anti-HBc (Total)"                 },
      { name: "anti_hcv_eclia"                    , unit: ""     , label: "Anti HCV - ECLIA"                 , placeholder: "Anti HCV - ECLIA"                 },
      { name: "influenza_virus_ag_type_a"         , unit: ""     , label: "Influenza virus Ag type A"        , placeholder: "Influenza virus Ag type A"        },
      { name: "rsv"                               , unit: ""     , label: "RSV"                              , placeholder: "RSV"                              },
      { name: "dengue_ns1_ag"                     , unit: ""     , label: "Dengue NS1 Ag"                    , placeholder: "Dengue NS1 Ag"                    },
      { name: "dengue_ab_igm"                     , unit: ""     , label: "Dengue Ab IgM"                    , placeholder: "Dengue Ab IgM"                    },
      { name: "dengue_ab_ig_g"                    , unit: ""     , label: "Dengue Ab IgG"                    , placeholder: "Dengue Ab IgG"                    },
    ]
  },
  {
    title: "ความสมบูรณ์ของเม็ดเลือด",
    items: [
      { name: "wbc"                       , unit: "10^3/uL", label: "WBC"                     , placeholder: "WBC"                     },
      { name: "band"                      , unit: "%"      , label: "Band form"               , placeholder: "Band form"               },
      { name: "rbc"                       , unit: "10^6/uL", label: "RBC"                     , placeholder: "RBC"                     },
      { name: "hb"                        , unit: "g/dL"   , label: "Hemoglobin"              , placeholder: "Hemoglobin"              },
      { name: "hct"                       , unit: "%"      , label: "Hematocrit"              , placeholder: "Hematocrit"              },
      { name: "mcv"                       , unit: "fL"     , label: "MCV"                     , placeholder: "MCV"                     },
      { name: "mch"                       , unit: "pg"     , label: "MCH"                     , placeholder: "MCH"                     },
      { name: "mchc"                      , unit: "g/dL"   , label: "MCHC"                    , placeholder: "MCHC"                    },
      { name: "rdw"                       , unit: "%"      , label: "RDW"                     , placeholder: "RDW"                     },
      { name: "platelet_count"            , unit: "10^3/uL", label: "Platelet Count"          , placeholder: "Platelet Count"          },
      { name: "platelet_smear"            , unit: ""       , label: "Platelet Smear"          , placeholder: "Platelet Smear"          },
      { name: "neutrophil"                , unit: "%"      , label: "Neutrophil"              , placeholder: "Neutrophil"              },
      { name: "lymphocyte"                , unit: "%"      , label: "Lymphocyte"              , placeholder: "Lymphocyte"              },
      { name: "monocyte"                  , unit: "%"      , label: "Monocyte"                , placeholder: "Monocyte"                },
      { name: "eosinophil"                , unit: "%"      , label: "Eosinophil"              , placeholder: "Eosinophil"              },
      { name: "basophil"                  , unit: "%"      , label: "Basophil"                , placeholder: "Basophil"                },
      { name: "atypical_lymphocyte"       , unit: "%"      , label: "Atypical Lymphocyte"     , placeholder: "Atypical Lymphocyte"     },
      { name: "metamyelocyte"             , unit: "%"      , label: "Metamyelocyte"           , placeholder: "Metamyelocyte"           },
      { name: "myelocyte"                 , unit: "%"      , label: "Myelocyte"               , placeholder: "Myelocyte"               },
      { name: "myeloblast"                , unit: "%"      , label: "Myeloblast"              , placeholder: "Myeloblast"              },
      { name: "lymphoblast"               , unit: "%"      , label: "Lymphoblast"             , placeholder: "Lymphoblast"             },
      { name: "hypersegmented_neutrophil" , unit: "%"      , label: "Hypersegmented Neutrophil" , placeholder: "Hypersegmented Neutrophil" },
      { name: "normochromic_normocytic"   , unit: ""       , label: "Normochromic Normocytic" , placeholder: "Normochromic Normocytic" },
      { name: "anisocytosis"              , unit: ""       , label: "Anisocytosis"            , placeholder: "Anisocytosis"            },
      { name: "poikilocytosis"            , unit: ""       , label: "Poikilocytosis"          , placeholder: "Poikilocytosis"          },
      { name: "microcyte"                 , unit: ""       , label: "Microcyte"               , placeholder: "Microcyte"               },
      { name: "macrocyte"                 , unit: ""       , label: "Macrocyte"               , placeholder: "Macrocyte"               },
      { name: "hypochromia"               , unit: ""       , label: "Hypochromia"             , placeholder: "Hypochromia"             },
      { name: "polychromasia"             , unit: ""       , label: "Polychromasia"           , placeholder: "Polychromasia"           },
      { name: "target_cell"               , unit: ""       , label: "Target Cell"             , placeholder: "Target Cell"             },
      { name: "elliptocyte"               , unit: ""       , label: "Elliptocyte"             , placeholder: "Elliptocyte"             },
      { name: "schistocyte"               , unit: ""       , label: "Schistocyte"             , placeholder: "Schistocyte"             },
      { name: "spherocyte"                , unit: ""       , label: "Spherocyte"              , placeholder: "Spherocyte"              },
      { name: "tear_drop_cell"            , unit: ""       , label: "Tear Drop Cell"          , placeholder: "Tear Drop Cell"          },
      { name: "acanthocyte"               , unit: ""       , label: "Acanthocyte"             , placeholder: "Acanthocyte"             },
      { name: "echinocyte"                , unit: ""       , label: "Echinocyte"              , placeholder: "Echinocyte"              },
      { name: "stomatocyte"               , unit: ""       , label: "Stomatocyte"             , placeholder: "Stomatocyte"             },
      { name: "basophillic_stippling"     , unit: ""       , label: "Basophillic Stippling"   , placeholder: "Basophillic Stippling"   },
      { name: "hyperchromic_normocytic"   , unit: ""       , label: "Hyperchromic Normocytic" , placeholder: "Hyperchromic Normocytic" },
      { name: "pelger_huet_anomaly"       , unit: "%"      , label: "Pelger Huet Anomaly"     , placeholder: "Pelger Huet Anomaly"     },
      { name: "maccroovallocyte"          , unit: ""       , label: "Maccroovallocyte"        , placeholder: "Maccroovallocyte"        },
      { name: "rbc_morphology"            , unit: ""       , label: "RBC Morphology"          , placeholder: "RBC Morphology"          },
    ]
  },
  {
    title: "การแข็งตัวของเลือด",
    items: [
      { name: "bleeding_time" , unit: "min" , label: "Bleeding Time"             , placeholder: "Bleeding Time"             },
      { name: "vct"           , unit: "min" , label: "VCT (Venous Cloting Time)" , placeholder: "VCT (Venous Cloting Time)" },
      { name: "pt"            , unit: "sec.", label: "PT"                        , placeholder: "PT"                        },
      { name: "inr"           , unit: ""    , label: "INR"                       , placeholder: "INR"                       },
      { name: "ptt"           , unit: "sec" , label: "PTT."                      , placeholder: "PTT."                      },
    ]
  },
  {
    title: "หมู่เลือด",
    items: [
      { name: "abo_grouping" , unit: "", label: "ABO grouping" , placeholder: "ABO grouping", type: 'text' },
      { name: "rh_grouping"  , unit: "", label: "Rh grouping"   , placeholder: "Rh grouping", type: 'text' }
    ]
  },
  {
    title: "สารบ่งชี้มะเร็ง",
    items: [
      { name: "afp", unit: "IU/mL", label: "สารบ่งชี้มะเร็งตับ (AFP)"         , placeholder: "สารบ่งชี้มะเร็งตับ (AFP)"         },
      { name: "cea", unit: "ng/mL", label: "สารบ่งชี้มะเร็งลำไส้ (CEA)"       , placeholder: "สารบ่งชี้มะเร็งลำไส้ (CEA)"       },
      { name: "psa", unit: "ng/mL", label: "สารบ่งชี้มะเร็งต่อมลูกหมาก (PEA)" , placeholder: "สารบ่งชี้มะเร็งต่อมลูกหมาก (PEA)" },
    ]
  },
  {
    title: "องค์ประกอบของร่างกาย",
    items: [
      { name: "weight"              , unit: "kg"   , label: "น้ำหนัก"                                          , placeholder: "น้ำหนัก"                                          },
      { name: "height"              , unit: "cm"   , label: "ส่วนสูง"                                          , placeholder: "ส่วนสูง"                                          },
      { name: "sbp"                 , unit: "mmHg" , label: "ความดันโลหิตขณะหัวใจบีบตัว (SBP)"                     , placeholder: "ความดันโลหิตขณะหัวใจบีบตัว (SBP)"                                              },
      { name: "dbp"                 , unit: "mmHg" , label: "ความดันโลหิตขณะหัวใจคลายตัว (DBP)"                   , placeholder: "ความดันโลหิตขณะหัวใจคลายตัว (DBP)"                                              },
      { name: "bmi"                 , unit: "kg/m2", label: "ดัชนีมวลกาย"                                     , placeholder: "ดัชนีมวลกาย"                                              },
      // { name: "pct_fat"             , unit: ""     , label: "เปอร์เซนต์ไขมัน"                                    , placeholder: "เปอร์เซนต์ไขมัน"                                             },
      // { name: "viceral_fat"         , unit: ""     , label: "ระดับไขมันช่องท้อง"                                      , placeholder: "ระดับไขมันช่องท้อง"                                      },
      { name: "tbw"                 , unit: "L"    , label: "ปริมาณน้ำในร่างกายทั้งหมด"                        , placeholder: "ปริมาณน้ำในร่างกายทั้งหมด"                        },
      { name: "protein"             , unit: "kg"   , label: "โปรตีน (สำหรับการสร้างกล้ามเนื้อ)"                , placeholder: "โปรตีน (สำหรับการสร้างกล้ามเนื้อ)"                },
      { name: "minerals"            , unit: "kg"   , label: "แร่ธาตุ (สำหรับการเสริมสร้างกระดูก)"              , placeholder: "แร่ธาตุ (สำหรับการเสริมสร้างกระดูก)"              },
      { name: "bfm"                 , unit: "kg"   , label: "มวลไขมันในร่างกาย (สำหรับจัดเก็บพลังงานส่วนเกิน)"      , placeholder: "มวลไขมันในร่างกาย (สำหรับจัดเก็บพลังงานส่วนเกิน)" },
      { name: "ffm"                 , unit: "kg"   , label: "มวลกายที่ไม่รวมไขมัน"                             , placeholder: "มวลกายที่ไม่รวมไขมัน"                             },
      { name: "smm"                 , unit: "kg"   , label: "มวลกล้ามเนื้อโครงร่าง"                            , placeholder: "มวลกล้ามเนื้อโครงร่าง"                            },
      { name: "pbf"                 , unit: "%"    , label: "เปอร์เซ็นต์ไขมันในร่างกาย"                        , placeholder: "เปอร์เซ็นต์ไขมันในร่างกาย"                        },
      { name: "whr"                 , unit: ""     , label: "สัดส่วนเอวต่อสะโพก"                               , placeholder: "สัดส่วนเอวต่อสะโพก"                               },
      { name: "vfl"                 , unit: ""     , label: "ระดับไขมันในช่องท้อง"                             , placeholder: "ระดับไขมันในช่องท้อง"                             },
      { name: "bmr"                 , unit: "kcal" , label: "อัตราการเผาผลาญของร่างกายปกติ"                    , placeholder: "อัตราการเผาผลาญของร่างกายปกติ"                    },
      { name: "waist_circumference" , unit: "cm"   , label: "เส้นรอบเอว"                                      , placeholder: "เส้นรอบเอว"                              },
      { name: "hand_grip"           , unit: "kg"   , label: "แรงบีบมือ"                                        , placeholder: "แรงบีบมือ"                                        },
    ]
  },
  // {
  //   title: "ระดับไขมันในเลือด",
  //   items: [
  //     { name: "chol", unit: "mg/dL", label: "คอเลสเตอรอลรวม", placeholder: "คอเลสเตอรอลรวม" }
  //   ]
  // },
]

export default HEALTH_INFO_CONFIG
