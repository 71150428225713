import React, { useState, useEffect } from 'react'
import { models } from 'powerbi-client'
import { PowerBIEmbed } from 'powerbi-client-react'
import { compose, withStores, withHooks } from '@enhancers'
import styled from '@styled'
import { call } from '@common/helper'

const Layout = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  margin-top: -25px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: none !important;

    .pane.sections {
      background-color: red !important;
    }
  }
`

const HideTrialVersionNotification = styled.div`
  width: 100%;
  height: 40px;
  background-color: white;
  z-index: 10000000;
  position: absolute;
  top: 0;
`

const eventHandlers = new Map([
  ['loaded', function () {}],
  ['rendered', function () {}],
  ['error', function (event) {}],
])

const PowerBIReport = (props) => (
  <Layout id="power_bi_dashboard">
    {!props.embedToken ? (
      <div>Loading</div>
    ) : (
      <>
        <HideTrialVersionNotification />
        <PowerBIEmbed
          embedConfig={{
            type: 'report',
            id: props.reportId,
            embedUrl: props.embedUrl,
            accessToken: props.embedToken,
            tokenType: models.TokenType.Embed,
            settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: true,
            },
          }}
          eventHandlers={eventHandlers}
        />
      </>
    )}
  </Layout>
)

const enhancer = compose(
  withStores((stores) => ({
    fetchDashboard: stores.companyStore.fetchDashboard,
  })),
  withHooks((props) => {
    const { companyId, fetchDashboard } = props
    const [embedInfo, setEmbedInfo] = useState({})

    useEffect(() => {
      call(async () => {
        setEmbedInfo({})
        if (companyId) {
          const embedInfo = await fetchDashboard(companyId)
          setEmbedInfo(embedInfo)
        }
      })
    }, [setEmbedInfo, fetchDashboard, companyId])

    const { embedUrl, embedToken, reportId } = embedInfo

    return {
      embedUrl,
      embedToken,
      reportId,
    }
  }),
)

export default enhancer(PowerBIReport)
