import React from 'react'
import { Field, Input, Grid, Button, Checkbox } from '@components'
import { compose, withHooks } from '@enhancers'
import { times } from 'lodash'
import { css } from '@styled'

const checkboxStyle = css`
  height: 36px;
`

const AnswerTemplateChoiceInfo = (props) => (
  <Grid item container direction="column" spacing={2}>
    {times(props.itemsAmount, (index) => (
      <Grid key={index} item>
        <Field
          name={`${props.name.replace('.choices', '.answers')}[${index}]`}
          component={Checkbox}
          disabled={props.disabled}
          className={checkboxStyle}
        />
        <Field
          label="คำตอบ"
          name={`${props.name}[${index}]`}
          component={Input}
          placeholder="คำตอบ"
          suggestMaxLength={50}
          helperText="โปรดระบุคำตอบ"
          disabled={props.disabled}
          required
        />
        {!props.disabled && (
          <Button
            onClick={() => props.removeAnswer(index)}
            icon="trash"
            size="small"
            className="ml-5"
            disabled={props.disabled}
          />
        )}
      </Grid>
    ))}
    {!props.disabled && (
      <Grid item>
        <Button size="small" color="primary" onClick={props.addAnswer}>
          เพิ่มคำตอบ
        </Button>
      </Grid>
    )}
  </Grid>
)

const enhancer = compose(
  withHooks((props) => {
    const { form, name, push, remove, disabled } = props

    const itemsAmount = form.getFieldProps(name).value?.length || 0

    return {
      name,
      itemsAmount,
      addAnswer: push,
      removeAnswer: remove,
      disabled,
    }
  }),
)

export default enhancer(AnswerTemplateChoiceInfo)
