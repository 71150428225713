import React from 'react'
import { useFormikContext } from 'formik'
import { compose, withHooks } from '@enhancers'
import { Form } from '@components'

const FormLayout = (props) => (
  <div className="login-form login-signin">
    <div className="text-center mb-10 mb-lg-20">
      <h3 className="font-size-h1">{props.title}</h3>
      <p className="text-muted">{props.description}</p>
    </div>
    {props.error && (
      <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
        <div className="alert-text">{props.error}</div>
      </div>
    )}
    <Form>{props.children}</Form>
  </div>
)

const enhancer = compose(
  withHooks((props) => {
    const { title, description, children } = props
    const { errors } = useFormikContext()
    return {
      title,
      description,
      children,
      error: errors._error,
    }
  }),
)

export default enhancer(FormLayout)
