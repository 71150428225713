import React, { useState, useImperativeHandle, useCallback } from 'react'
import { compose, withStores, withHooks, withFormik } from '@enhancers'
import { Modal, Notification } from '@components'
import MissionInfo from './MissionInfo'
import RejectMissionModal from './RejectMissionModal'
import { css } from '@styled'
import { isNumber } from 'lodash'

const mainStyle = css`
  .MuiPaper-root.MuiDialog-paper {
    margin: 0;
  }

  .MuiDialogContent-root > .MuiGrid-root {
    background-color: transparent !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .MuiDialogTitle-root {
    padding: 0;
  }

  .MuiDialogActions-root {
    justify-content: space-between;
    border-top: 1px solid #ebedf3;
  }

  .MuiDialogActions-root > button:first-child {
    color: #ffffff;
    background-color: #f64e60;
    border-color: #f64e60;
    width: 70px;
  }
`

const MissionInfoModal = (props) => (
  <Modal
    className={mainStyle}
    isShow={props.isShow}
    onClose={props.close}
    okButtonLabel="Approve"
    onOk={props.approve}
    cancelButtonLabel="Reject"
    onCancel={props.reject}
    hideFooter={props.hideFooter}>
    <MissionInfo row={props.mission} />
  </Modal>
)

const enhancer = compose(
  withStores((stores) => ({
    approveMission: stores.missionStore.approveMission,
  })),
  withFormik(),
  withHooks((props) => {
    const {
      $ref,
      approveMission,
      values,
      setFieldTouched,
      setErrors,
      resetForm,
      onApproveSuccess,
      onRejectSuccess,
    } = props
    const [mission, setMission] = useState({})

    const close = useCallback(() => {
      setMission({})
      resetForm()
    }, [setMission, resetForm])

    useImperativeHandle($ref, () => ({
      open: (missionInfo) => {
        setMission(missionInfo)
      },
      close,
    }))

    const validatePacerAnswer = useCallback(() => {
      const pace =
        mission.answerImageUrl === null
          ? mission.answer.pace || 0
          : values.pacer

      if (
        mission.answerTemplate.type === 'pacer' &&
        (!isNumber(pace) || pace < 0)
      ) {
        console.log('pace', pace)

        setFieldTouched('pacer', true)
        setErrors({ pacer: 'ต้องไม่เว้นว่างเอาไว้ หรือติดลบ' })
        Modal.alert({
          children:
            'กรุณาระบุก้าวเดินที่ผู้ใช้แจ้งมาให้ถูกต้อง เพื่อนำไปคำนวณค่าเฉลี่ยก้าวเดินได้ครบถ้วน',
        })
        return true
      }
      return false
    }, [mission, values, setFieldTouched, setErrors])

    const approve = useCallback(async () => {
      if (mission.canApprove && mission.id) {
        if (validatePacerAnswer()) {
          return
        }

        const pace =
          mission.answerImageUrl === null
            ? mission.answer.pace || 0
            : values.pacer

        await approveMission(mission.id, pace)
        if (onApproveSuccess) {
          await onApproveSuccess(mission, pace)
        }
        Notification.success('Success!', { autoHideDuration: 100 })
        close()
      } else {
        close()
      }
    }, [
      validatePacerAnswer,
      close,
      mission,
      approveMission,
      values.pacer,
      onApproveSuccess,
    ])

    const pace = values.pacer
    const reject = useCallback(() => {
      if (mission.canReject && mission.id) {
        if (validatePacerAnswer()) {
          return
        }

        Modal.confirm({
          title: 'Are you reject this Mission?',
          hideFooter: true,
          children: (
            <RejectMissionModal
              missionIds={[mission.id]}
              pacers={[pace]}
              onComplete={async (remark) => {
                if (onRejectSuccess) {
                  await onRejectSuccess(mission, remark)
                }
                close()
              }}
            />
          ),
        })
      } else {
        close()
      }
    }, [validatePacerAnswer, close, mission, onRejectSuccess, pace])

    const isShow = !!mission.id
    const hideFooter = !mission.canApprove && !mission.canReject

    return {
      isShow,
      mission,
      close,
      approve,
      reject,
      hideFooter,
      values,
    }
  }),
)

export default enhancer(MissionInfoModal)
