import qs from 'qs'
import { last } from 'lodash'
import { safe } from '@common/helper'
import { history } from '@components/BrowserRouter'

const getCurrentCompanyFromPath = (url) =>
  safe(() => url.match(/^\/companies\/([^/]+)/)[1])

let CURRENT_COMPANY_ID = getCurrentCompanyFromPath(window.location.pathname)

export class Path extends String {
  constructor(basePath = '') {
    super(basePath)
    this.makePaths()
  }

  // prettier-ignore
  makePaths() {
    this.homePath                   = this.makePath(() => ``)
    this.notFoundPath               = this.makePath(() => '404')
    this.authPath                   = this.makePath(() => `auth`)
    this.signInPath                 = this.makePath(() => `auth/sign_in`)
    this.registerPath               = this.makePath(() => `auth/register`)
    this.setupPasswordPath          = this.makePath(() => `auth/setup_password`)
    this.forgotPasswordPath         = this.makePath(() => `auth/forgot_password`)
    this.adminsPath                 = this.makePath(() => `admins`)
    this.adminsNewPath              = this.makePath(() => `admins/new`)
    this.companiesPath              = this.makePath(() => `companies`)
    this.companiesNewPath           = this.makePath(id => `companies/new`)
    this.companyPath                = this.makePath(id => `companies/${id}`)
    this.companyEditPath            = this.makePath(id => `companies/${id}/edit`)
    this.coachesPath                = this.makePath(() => `coaches`)
    this.coachesNewPath             = this.makePath(() => `coaches/new`)
    this.coachPath                  = this.makePath(id => `coaches/${id}`)
    this.coachEditPath              = this.makePath(id => `coaches/${id}/edit`)
    this.articleTemplatesPath       = this.makePath(() => `article_templates`)
    this.articleTemplateNewPath     = this.makePath(() => `article_templates/new`)
    this.articleTemplateEditPath    = this.makePath(id => `article_templates/${id}/edit`)
    this.missionTemplatesPath       = this.makePath(() => `mission_templates`)
    this.missionTemplateNewPath     = this.makePath(() => `mission_templates/new`)
    this.missionTemplatePath        = this.makePath(id => `mission_templates/${id}`)
    this.missionTemplateEditPath    = this.makePath(id => `mission_templates/${id}/edit`)
    this.chatsPath                  = this.makePath(() => `chats`)
    this.matchUpPath                = this.makePath(() => `match_up`)
    this.missionsPath               = this.makePath(() => `missions`)
    this.missionNewPath             = this.makePath(() => `missions/new`)
    this.missionPath                = this.makePath(id => `missions/${id}`)
    this.missionEditPath            = this.makePath(id => `missions/${id}/edit`)
    this.articlesPath               = this.makePath(() => `articles`)
    this.articlePath                = this.makePath(id => `articles/${id}`)
    this.happinessScoresPath        = this.makePath(() => `happiness_scores`)
    this.currentCompanyPath         = this.makePath(() => this.companyPath(this.getCurrentCompanyId()))
    this.infoPath                   = this.makePath(() => `info`)
    this.dashboardPath              = this.makePath(() => `dashboard`)
    this.humanResourcesPath         = this.makePath(() => `human_resources`)
    this.humanResourcesNewPath      = this.makePath(() => `human_resources/new`)
    this.humanResourcePath          = this.makePath(id => `human_resources/${id}`)
    this.humanResourceEditPath      = this.makePath(id => `human_resources/${id}/edit`)
    this.employeesPath              = this.makePath(() => `employees`)
    this.employeesNewPath           = this.makePath(() => `employees/new`)
    this.employeePath               = this.makePath(id => `employees/${id}`)
    this.employeeEditPath           = this.makePath(id => `employees/${id}/edit`)
    this.seasonsPath                = this.makePath(() => `seasons`)
    this.seasonsNewPath             = this.makePath(() => `seasons/new`)
    this.seasonPath                 = this.makePath(id => `seasons/${id}`)
    this.seasonEditPath             = this.makePath(id => `seasons/${id}/edit`)
    this.attendeesPath              = this.makePath(() => `attendees`)
    this.attendeeAddSocialCoinPath  = this.makePath(id => `attendees/${id}/add_social_coin`)
    this.socialCoinsPath            = this.makePath(() => `social_coins`)
    this.socialCoinsNewPath         = this.makePath(() => `social_coins/new`)
    this.socialCoinPath             = this.makePath(id => `social_coins/${id}`)
    this.announcementsPath          = this.makePath(() => `announcements`)
    this.announcementsNewPath       = this.makePath(() => `announcements/new`)
    this.announcementPath           = this.makePath(id => `announcements/${id}`)
    this.activitiesPath             = this.makePath(() => `activity`)
    this.chatInfoPath               = this.makePath((compId, empId) => `companies/${compId}/employees/${empId}`)
  }

  newTab() {
    window.open(this.toString(), '_blank')
  }

  push = () => {
    if (history) {
      history.push(this.toString())
    }
  }

  replace = () => {
    if (history) {
      history.replace(this.toString())
    }
  }

  getCurrentCompanyId = () => {
    const authStore = require('../stores/authStore').default
    switch (authStore.state.currentRole) {
      case 'admin':
      case 'coach':
        return (
          getCurrentCompanyFromPath(window.location.pathname) ||
          CURRENT_COMPANY_ID
        )
      case 'human_resource':
        return authStore.$get('state.currentUser.allowedCompanyIds[0]')
      default:
        return null
    }
  }

  makePath = (path) => {
    return (...args) => {
      let pathMethod = typeof path === 'string' ? () => path : path
      let absolutePath = null

      if (last(args) instanceof Object) {
        const params = args.slice(0, args.length - 1)
        const query = last(args)
        const queryString = qs.stringify(query)

        absolutePath = `${this}/${pathMethod(...params)}?${queryString}`
      } else {
        absolutePath = `${this}/${pathMethod(...args)}`
      }

      return new Path(absolutePath.replace(/\/+/, '/'))
    }
  }
}

export default new Path()
