import React, { useCallback, useEffect, useRef } from 'react'
import {
  Modal,
  Notification,
  PageLayout,
  Table,
  ExcelGenerator,
} from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import { filter, get, map } from 'lodash'
import ExampleModal from './ExampleModal'
import { format } from 'date-fns'

const AdminIndexPage = (props) => (
  <PageLayout
    title="ผู้ดูแลระบบ"
    actions={[
      {
        label: 'ออกรายงาน',
        icon: 'download',
        color: 'primary',
        onClick: props.onExport,
      },
      {
        label: 'เพิ่มผู้ดูแลระบบ',
        icon: 'plus',
        color: 'primary',
        onClick: props.onCreate,
      },
    ]}>
    <Table
      $ref={props.$table}
      filters={[
        {
          description: 'กรองด้วยอีเมล',
          dataField: 'q',
          type: 'search',
        },
        {
          description: 'กรองด้วยการยืนยันอีเมล',
          dataField: 'confirmation',
          type: 'select',
          options: props.confirmationFilterOptions,
          size: 3,
        },
      ]}
      groupActions={[
        {
          label: 'ส่งอีเมลยืนยันทั้งหมดใหม่อีกครั้ง',
          icon: 'paper-plane',
          hoverColor: 'primary',
          onClick: props.onResendAll,
        },
        {
          label: 'ลบทั้งหมด',
          icon: 'trash',
          hoverColor: 'danger',
          onClick: props.onDeleteAll,
        },
      ]}
      columns={[
        {
          title: 'อีเมล',
          dataField: 'email',
          sort: true,
          align: 'left',
        },
        {
          title: 'วันที่ยืนยันอีเมล',
          dataField: 'invitationConfirmedAt',
          type: 'datetime',
        },
        {
          title: 'จำนวนวันที่เข้าระบบ',
          dataField: 'activeDayAmount',
        },
        {
          title: 'ใช้ระบบล่าสุด',
          dataField: 'lastActiveAt',
          type: 'date',
        },
        {
          title: 'แพลทฟอร์ม',
          dataField: 'platform',
        },
        {
          title: 'IP',
          dataField: 'ip',
        },
        {
          title: 'ชื่อเบราว์เซอร์',
          dataField: 'browserName',
        },
        {
          title: 'รุ่นเบราว์เซอร์',
          dataField: 'browserVersion',
        },
        {
          title: 'การดำเนินการ',
          type: 'actions',
          actions: [
            {
              label: 'ส่งอีเมลยืนยันอีกครั้ง',
              icon: 'paper-plane',
              hoverColor: 'primary',
              onClick: props.onResend,
              visible: (admin) => admin.canSendInvitation,
            },
            {
              label: 'ลบ',
              icon: 'trash',
              hoverColor: 'danger',
              onClick: props.onDelete,
              visible: (admin) => admin.canDelete,
            },
          ],
        },
      ]}
      entities={props.admins}
      paging={props.paging}
      onQuery={props.onQuery}
      selectable
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    configs: stores.adminStore.configs,
    admins: stores.adminStore.admins,
    paging: stores.adminStore.paging,
    fetchConfigs: stores.adminStore.fetchConfigs,
    fetchAdmins: stores.adminStore.fetchAdmins,
    deleteAllAdmins: stores.adminStore.deleteAllAdmins,
    deleteAdmin: stores.adminStore.deleteAdmin,
    resendAllConfirmations: stores.adminStore.resendAllConfirmations,
    resendConfirmation: stores.adminStore.resendConfirmation,
  })),
  withHooks((props) => {
    const {
      configs,
      admins,
      paging,
      fetchConfigs,
      fetchAdmins,
      deleteAllAdmins,
      deleteAdmin,
      resendAllConfirmations,
      resendConfirmation,
    } = props

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    const confirmationFilterOptions = get(
      configs,
      'filters.confirmation.options',
    )

    const onQuery = fetchAdmins

    const onCreate = useCallback(() => {
      paths.adminsNewPath().push()
    }, [])

    const onDelete = useCallback(
      async (admin) => {
        Modal.confirm({
          children: 'Permanent delete this admin.',
          onOk: async (modal) => {
            await deleteAdmin(admin.id)
            Notification.success()
            modal.close()
          },
        })
      },
      [deleteAdmin],
    )

    const onDeleteAll = useCallback(
      async (admins) => {
        Modal.confirm({
          children: 'Permanent delete all selected admins.',
          onOk: async (modal) => {
            const canDeleteAdmins = filter(admins, { canDelete: true })
            await deleteAllAdmins(map(canDeleteAdmins, 'id'))
            Notification.success()
            modal.close()
          },
        })
      },
      [deleteAllAdmins],
    )

    const onResendAll = useCallback(
      async (admins) => {
        Modal.confirm({
          children: 'Send confirmation email to all unconfirmed admins.',
          onOk: async (modal) => {
            const canResendAdmins = filter(admins, { canSendInvitation: true })
            await resendAllConfirmations(map(canResendAdmins, 'id'))
            Notification.success()
            modal.close()
          },
        })
      },
      [resendAllConfirmations],
    )

    const onResend = useCallback(
      async (admin) => {
        await resendConfirmation(admin.id)
        Notification.success()
      },
      [resendConfirmation],
    )

    const $table = useRef()

    const onClearFilter = useCallback(() => {
      $table.current.setFilter({ confirmation: undefined, q: undefined })
    }, [])

    const onOpenModal = useCallback(() => {
      Modal.open({
        title: 'Example Form',
        children: <ExampleModal />,
        onOk: async (modal) => {
          Notification.success()
          modal.close()
        },
        hideFooter: true,
      })
    }, [])

    const onExport = useCallback(async () => {
      const data = await fetchAdmins(
        {
          ...$table.current.getQueryParams(),
          page: 1,
          perPage: 1000000,
        },
        { preventSetState: true },
      )
      await ExcelGenerator.generate({
        fileName: `admins-${format(new Date(), 'yyyyMMddhhmmss')}`,
        columns: [
          {
            title: 'อีเมล',
            field: 'email',
          },
          {
            title: 'วันที่ยืนยันอีเมล',
            field: 'invitationConfirmedAt',
          },
          {
            title: 'จำนวนวันที่เข้าระบบ',
            field: 'activeDayAmount',
          },
          {
            title: 'ใช้ระบบล่าสุด',
            field: 'lastActiveAt',
          },
          {
            title: 'แพลทฟอร์ม',
            field: 'platform',
          },
          {
            title: 'IP',
            field: 'ip',
          },
          {
            title: 'ชื่อเบราว์เซอร์',
            field: 'browserName',
          },
          {
            title: 'รุ่นเบราว์เซอร์',
            field: 'browserVersion',
          },
        ],
        data,
      })
    }, [fetchAdmins, $table])

    return {
      $table,
      admins,
      paging,
      onQuery,
      onCreate,
      onDelete,
      onDeleteAll,
      onResend,
      onResendAll,
      confirmationFilterOptions,
      onClearFilter,
      onOpenModal,
      onExport,
    }
  }),
)

export default enhancer(AdminIndexPage)
