/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { Avatar } from '@components'
import ImageLightbox from '../../ImageLightbox'

const avatarStyle = {
  width: 30,
  height: 30,
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: '#B5B5C3',
}

const AvatarColumnFormatter = (cellContent, row, rowIndex, props) => (
  <Avatar
    readonly
    style={avatarStyle}
    src={cellContent}
    onClick={() => {
      if (props.onClick) {
        return props.onClick(row)
      } else {
        cellContent && ImageLightbox.open({ image: cellContent })
      }
    }}>
    {!cellContent && props.nameKey && row[props.nameKey]
      ? row[props.nameKey][0]
      : ''}
  </Avatar>
)

export default AvatarColumnFormatter
