import React from 'react'
import { PageLayout } from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import PowerBIReport from './PowerBIReport'

const DashboardIndexPage = (props) => (
  <PageLayout title="ภาพรวม">
    <PowerBIReport companyId={props.companyId} />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    currentCompany: stores.companyStore.currentCompany,
  })),
  withHooks((props) => {
    const { currentCompany } = props

    const companyId = currentCompany?.id

    return {
      companyId,
    }
  }),
)

export default enhancer(DashboardIndexPage)
