import React from 'react'
import { Button } from '@components'
import { compose, withHooks } from '@enhancers'
import { css } from '@styled'

const saveButtonStyle = css`
  position: fixed;
  bottom: 30%;
  right: 20px;
  z-index: 100;
`

const FloatingSaveButton = (props) => (
  <Button
    icon="save"
    color="primary"
    className={saveButtonStyle}
    type="submit"
  />
)

const enhancer = compose(
  withHooks((props) => {
    return {}
  }),
)

export default enhancer(FloatingSaveButton)
