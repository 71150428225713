import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { compose, withHooks } from '@enhancers'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Path } from '@common/paths'
import { useFormikContext } from 'formik'
import Icon from './Icon'
import classnames from 'classnames'
import { safe } from '@common/helper'

const SIZE_MAPPING = {
  small: 'sm',
  large: 'lg',
}

const CustomButton = (props) => (
  <Button
    className={props.className}
    style={props.style}
    type={props.type}
    variant={props.variant}
    size={props.size}
    onClick={props.onClick}
    disabled={props.disabled}>
    {props.loading ? (
      <Icon className={props.iconClassName} name="cog" spin />
    ) : props.icon ? (
      <Icon className={props.iconClassName} name={props.icon} />
    ) : null}
    {props.children}
  </Button>
)

const enhancer = compose(
  withHooks((props) => {
    const {
      className,
      style,
      type,
      size,
      onClick,
      disabled,
      loading,
      to,
      icon,
      children,
      variant,
      color = 'light',
      hoverColor,
      wrap,
    } = props
    const [clickEventLoading, setClickEventLoading] = useState(false)
    const history = useHistory()

    const { formikSubmitting, formikPristine, formikSubmitForm } =
      safe(() => {
        if (type === 'submit') {
          const formikBag = useFormikContext()
          if (formikBag) {
            const { isSubmitting, dirty, submitForm } = formikBag
            return {
              formikSubmitting: isSubmitting,
              formikPristine: !dirty,
              formikSubmitForm: submitForm,
            }
          }
        }
      }) || {}

    const [caching] = useState({ unMount: false })
    useEffect(() => {
      return () => {
        caching.unMount = true
      }
    }, [caching.unMount])

    const customOnClick = useCallback(async () => {
      if (onClick) {
        setClickEventLoading(true)
        try {
          await onClick()
        } catch (e) {}
        if (!caching.unMount) {
          setClickEventLoading(false)
        }
      } else {
        formikSubmitForm && formikSubmitForm()
      }

      if (to) {
        if (to instanceof Path) {
          to.push()
        } else {
          history.push(to)
        }
      }
    }, [
      history,
      to,
      onClick,
      setClickEventLoading,
      caching.unMount,
      formikSubmitForm,
    ])

    const isIconButton = icon && !children
    const customClassName = useMemo(() => {
      return classnames(
        'd-inline-flex justify-content-center align-items-center',
        wrap ? '' : 'text-nowrap',
        variant === 'link' ? 'px-0 py-0' : '',
        {
          'btn-icon': isIconButton,
          [`btn-hover-${hoverColor}`]: hoverColor,
          [className]: className,
        },
      )
    }, [variant, className, hoverColor, isIconButton, wrap])

    const customVariant = useMemo(() => {
      switch (variant) {
        case 'link':
          return 'link'
        default:
          if (variant) {
            return `${variant}-${color}`
          } else {
            return color
          }
      }
    }, [variant, color])

    const iconClassName = useMemo(() => {
      return classnames('pr-0', {
        [size === 'small' ? 'mr-2' : 'mr-3']: !isIconButton,
      })
    }, [isIconButton, size])

    const customLoading = loading || formikSubmitting || clickEventLoading
    const customDisable = disabled || customLoading || formikPristine
    const customStyle = customDisable
      ? { ...style, pointerEvents: 'none' }
      : style

    return {
      className: customClassName,
      iconClassName,
      type,
      variant: customVariant,
      size: SIZE_MAPPING[size],
      onClick: customOnClick,
      loading: customLoading,
      disabled: customDisable,
      icon,
      children,
      style: customStyle,
    }
  }),
)

export default enhancer(CustomButton)
