import React from 'react'
import { compose, withHooks, withStores } from '@enhancers'
import classnames from 'classnames'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '_metronic/_partials/controls'
import Button from './Button'
import { includes } from 'lodash'

const PageLayout = (props) => (
  <Card className={props.className}>
    <CardHeader title={props.title}>
      <CardHeaderToolbar>
        {props.actions.map((action, index) => {
          const { label, Component = Button, authorized, ...restProps } = action
          const hidden = authorized && !includes(authorized, props.currentRole)
          return hidden ? null : (
            <Component
              key={label}
              className={classnames({
                'mr-2': index !== props.actions.length - 1,
              })}
              {...restProps}>
              {label}
            </Component>
          )
        })}
      </CardHeaderToolbar>
    </CardHeader>
    <CardBody>{props.children}</CardBody>
  </Card>
)

const enhancer = compose(
  withStores((stores) => ({
    currentRole: stores.authStore.currentRole,
  })),
  withHooks(({ title, actions, children, className, currentRole }) => {
    return {
      className,
      title,
      actions: actions || [],
      children,
      currentRole,
    }
  }),
)

export default enhancer(PageLayout)
