import { BaseStore } from './index'
import server from '@common/api'
import { clearToken, setToken } from '@common/helper'
import { initFcmToken } from '@common/firebase'
import chatStore from './chatStore'

class AuthStore extends BaseStore {
  constructor() {
    super('authStore', {
      token: null,
      isAuthorized: false,
      currentRole: null,
      currentCompanyId: null,
      chatUid: null,
      currentUser: null,
    })
  }

  setupAuthentication = async (user, chatConfigs) => {
    await chatStore.initChat(chatConfigs)

    if (user.role === 'coach') {
      chatStore.logoutComet()
      chatStore.loginComet(user.chatUid)
    }

    this.setState({
      token: user.authenticationToken,
      currentUser: user,
      currentRole: user.role,
      isAuthorized: true,
      chatUid: user.chatUid,
    })

    setToken(user.authenticationToken)
  }

  setCurrentCompanyId = async (currentCompanyId) => {
    this.setState({ currentCompanyId })
  }

  signIn = async ({ email, password }) => {
    await initFcmToken()

    const response = await server.post('/users/sign_in', { email, password })
    const { user, chatConfigs } = response.data.data

    await this.setupAuthentication(user, chatConfigs)
  }

  signOut = async () => {
    chatStore.logoutComet()
    this.setState({
      token: null,
      isAuthorized: false,
      currentRole: null,
      chatUid: null,
    })
    await server.delete('/users/sign_out')
    clearToken()
  }

  authenticate = async (options) => {
    await initFcmToken()
    const response = await server.post(
      '/users/authenticate',
      undefined,
      options,
    )
    const { user, chatConfigs } = response.data.data

    await this.setupAuthentication(user, chatConfigs)
  }

  register = async ({ email }) => {
    await server.post('/admins', { email })
  }

  forgotPassword = async ({ email }) => {
    await server.post('/users/forgot_password', { email })
  }

  setupPassword = async ({ token, password }) => {
    await server.post('/users/setup_password', { token, password })
  }
}

export default new AuthStore()
