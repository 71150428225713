import React, { useCallback, useEffect } from 'react'
import { Grid, Field, PageLayout, Input, Notification } from '@components'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import * as yup from 'yup'
import { useParams } from 'react-router'

export const HumanResourceEditPage = (props) => (
  <PageLayout
    title={props.title}
    actions={[
      {
        label: 'กลับ',
        icon: 'arrow-left',
        onClick: props.goBack,
      },
      {
        label: 'ล้างค่าที่กรอก',
        icon: 'redo',
        onClick: props.reset,
      },
      {
        label: 'บันทึก',
        icon: 'save',
        color: 'primary',
        onClick: props.save,
        type: 'submit',
      },
    ]}>
    <Grid container spacing={3}>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="อีเมล"
          name="email"
          component={Input}
          placeholder="อีเมล"
          required
          helperText="โปรดใส่อีเมลเฉพาะ"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ชื่อ"
          name="name"
          component={Input}
          placeholder="ชื่อ"
          helperText="โปรดระบุชื่อ"
        />
      </Grid>
    </Grid>
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    updateHumanResource: stores.humanResourceStore.updateHumanResource,
    fetchHumanResources: stores.humanResourceStore.fetchHumanResources,
    humanResources: stores.humanResourceStore.humanResources,
  })),
  withFormik({
    mapPropsToValues: (props) => props.humanResources[0] || {},
    validationSchema: yup.object().shape({
      email: yup.string().email().required(),
    }),
    handleSubmit: async (values, formikBag) => {
      const { id, email, name } = values
      await formikBag.props.updateHumanResource(id, { email, name })
      Notification.success()
      paths.currentCompanyPath().humanResourcesPath().push()
    },
  }),
  withHooks((props) => {
    const { handleReset, handleSubmit, fetchHumanResources } = props
    const { id } = useParams()

    useEffect(() => {
      fetchHumanResources({ ids: [id] })
    }, [fetchHumanResources, id])

    const goBack = useCallback(() => {
      paths.currentCompanyPath().humanResourcesPath().push()
    }, [])

    return {
      goBack,
      reset: handleReset,
      save: handleSubmit,
      title: 'แก้ไขฝ่ายบุคคล',
    }
  }),
)

export default enhancer(HumanResourceEditPage)
