import React, { useCallback, useEffect, useState, useRef } from 'react'
import paths from '@common/paths'
import {
  Modal,
  Notification,
  PageLayout,
  Table,
  ExcelGenerator,
} from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import { format } from 'date-fns'

const CoachIndexPage = (props) => (
  <PageLayout
    title="โค้ช"
    actions={[
      {
        label: 'ออกรายงาน',
        icon: 'download',
        color: 'primary',
        onClick: props.onExport,
      },
      {
        label: 'เพิ่มโค้ช',
        icon: 'plus',
        color: 'primary',
        onClick: props.onCreate,
      },
    ]}>
    <Table
      $ref={props.$table}
      filters={[
        {
          description: 'กรองด้วยอีเมล',
          dataField: 'email',
          type: 'search',
        },
        {
          description: 'กรองด้วยชื่อโค้ช',
          dataField: 'name',
          type: 'search',
        },
      ]}
      columns={[
        {
          title: 'รูปโค้ช',
          dataField: 'avatarUrl',
          type: 'avatar',
          nameKey: 'avatarUrl',
          onClick: props.onEdit,
        },
        {
          title: 'ชื่อโค้ช',
          dataField: 'name',
          sort: true,
          align: 'left',
        },
        {
          title: 'อีเมล',
          dataField: 'email',
          align: 'left',
        },
        {
          title: 'tags',
          dataField: 'tags',
        },
        {
          title: 'ความเชี่ยวชาญ',
          dataField: 'expertise',
        },
        {
          title: 'จำนวนวันที่เข้าระบบ',
          dataField: 'activeDayAmount',
        },
        {
          title: 'ใช้ระบบล่าสุด',
          dataField: 'lastActiveAt',
          type: 'date',
        },
        {
          title: 'แพลทฟอร์ม',
          dataField: 'platform',
        },
        {
          title: 'IP',
          dataField: 'ip',
        },
        {
          title: 'ชื่อเบราว์เซอร์',
          dataField: 'browserName',
        },
        {
          title: 'รุ่นเบราว์เซอร์',
          dataField: 'browserVersion',
        },
        {
          title: 'การดำเนินการ',
          type: 'actions',
          actions: [
            {
              label: 'ส่งใหม่อีกครั้ง',
              icon: 'paper-plane',
              hoverColor: 'primary',
              onClick: props.onResend,
              visible: (coach) => !coach.invitationConfirmedAt,
            },
            {
              label: 'แก้ไข',
              icon: 'pen',
              hoverColor: 'primary',
              onClick: props.onEdit,
            },
            {
              label: 'ลบ',
              icon: 'trash',
              hoverColor: 'danger',
              onClick: props.onDelete,
              visible: (coach) => coach.canDelete,
            },
          ],
        },
      ]}
      entities={props.coaches}
      paging={props.paging}
      onQuery={props.onQuery}
      onEdit={props.onEdit}
      onDelete={props.onDelete}
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    configs: stores.coachStore.configs,
    paging: stores.coachStore.paging,
    coaches: stores.coachStore.coaches,
    fetchConfigs: stores.coachStore.fetchConfigs,
    fetchCoaches: stores.coachStore.fetchCoaches,
    deleteCoach: stores.coachStore.deleteCoach,
    resendConfirmation: stores.coachStore.resendConfirmation,
  })),
  withHooks((props) => {
    const {
      fetchCoaches,
      deleteCoach,
      coaches,
      paging,
      resendConfirmation,
    } = props
    const [customCoaches, setCustomCoaches] = useState([])
    const onCreate = useCallback(() => {
      paths.coachesNewPath().push()
    }, [])

    const onEdit = useCallback((coach) => {
      paths.coachEditPath(coach.id).push()
    }, [])

    const onDelete = useCallback(
      async (coach) => {
        Modal.confirm({
          children: 'Permanent delete this Coach.',
          onOk: async (modal) => {
            await deleteCoach(coach.id)
            await fetchCoaches()
            Notification.success('Delete coach success.')
            modal.close()
          },
        })
      },
      [deleteCoach, fetchCoaches],
    )

    const onResend = useCallback(
      async (coach) => {
        await resendConfirmation(coach.id)
        Notification.success('Resend success.')
        await fetchCoaches()
      },
      [resendConfirmation, fetchCoaches],
    )

    const onQuery = fetchCoaches

    useEffect(() => {
      const customCoaches = coaches.map((coach) => {
        const tags = coach.tags || []
        return { ...coach, tags: tags.join(', ') }
      })
      setCustomCoaches(customCoaches)
    }, [coaches])

    const $table = useRef()

    const onExport = useCallback(async () => {
      const data = await fetchCoaches(
        {
          ...$table.current.getQueryParams(),
          page: 1,
          perPage: 1000000,
        },
        { preventSetState: true },
      )
      await ExcelGenerator.generate({
        fileName: `coaches-${format(new Date(), 'yyyyMMddhhmmss')}`,
        columns: [
          {
            title: 'ชื่อโค้ช',
            field: 'name',
          },
          {
            title: 'อีเมล',
            field: 'email',
          },
          {
            title: 'Tags',
            field: 'tags',
          },
          {
            title: 'ความเชี่ยวชาญ',
            field: 'expertise',
          },
          {
            title: 'จำนวนวันที่เข้าระบบ',
            field: 'activeDayAmount',
          },
          {
            title: 'ใช้ระบบล่าสุด',
            field: 'lastActiveAt',
          },
          {
            title: 'แพลทฟอร์ม',
            field: 'platform',
          },
          {
            title: 'IP',
            field: 'ip',
          },
          {
            title: 'ชื่อเบราว์เซอร์',
            field: 'browserName',
          },
          {
            title: 'รุ่นเบราว์เซอร์',
            field: 'browserVersion',
          },
        ],
        data,
      })
    }, [fetchCoaches, $table])

    return {
      $table,
      coaches: customCoaches,
      paging,
      onQuery,
      onCreate,
      onEdit,
      onDelete,
      onResend,
      onExport,
    }
  }),
)

export default enhancer(CoachIndexPage)
