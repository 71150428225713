import React from 'react'
import * as yup from 'yup'
import paths from '@common/paths'
import { ApiError } from '@common/api'
import { compose, withStores, withFormik, withHooks } from '@enhancers'
import { Field, Input, Link, Button, Grid } from '@components'
import FormLayout from './FormLayout'

const SignInPage = (props) => (
  <FormLayout
    title="Login Account"
    description="Enter your email and password.">
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Field
          label="Email"
          name="email"
          component={Input}
          type="email"
          size="medium"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="Password"
          name="password"
          component={Input}
          type="password"
          size="medium"
        />
      </Grid>
      <Grid item xs={12} container justify="space-between" alignItems="center">
        <Button type="submit" icon="sign-in-alt" size="large" color="primary">
          Sign In
        </Button>
        <Link to={paths.forgotPasswordPath()}>Forgot Password</Link>
      </Grid>
    </Grid>
  </FormLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    signIn: stores.authStore.signIn,
  })),
  withFormik({
    validationSchema: yup.object().shape({
      email: yup.string().email().required(),
      password: yup.string().min(8).required(),
    }),
    handleSubmit: async (values, formikBag) => {
      try {
        await formikBag.props.signIn(values)
      } catch (e) {
        if (e.response.data.code === 'unauthorized') {
          throw new ApiError(e.response.data.message)
        }
        throw e
      }
    },
  }),
  withHooks((props) => {
    const { handleSubmit } = props
    return {
      handleSubmit,
    }
  }),
)

export default enhancer(SignInPage)
