import Lightbox from 'react-awesome-lightbox'
import 'react-awesome-lightbox/build/style.css'
import { compose, withHidden, withHooks, withStores } from '@enhancers'
import { get } from 'lodash'
import appStore from '@stores/appStore'

const enhancer = compose(
  withStores((stores, props) =>
    props.storeName ? get(stores, props.storeName) : {},
  ),

  withHooks((props) => {
    const { storeName, dispatch, isShow, ...rest } = props
    return {
      ...rest,
      hidden: !isShow,
    }
  }),
  withHidden(),
)

const ImageLightbox = enhancer(Lightbox)

ImageLightbox.open = appStore.openImageLightbox
ImageLightbox.close = appStore.closeImageLightbox

export default ImageLightbox
