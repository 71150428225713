import React, { useMemo } from 'react'
// import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { Field as FormikField, FastField as FormikFastField } from 'formik'
// import { keys } from 'lodash'
// import { getContext } from 'recompose'
// import { compose } from '@enhancers'

// const sortPropsValues = (props) =>
//   keys(props)
//     .sort()
//     .map((k) => props[k])

const Field = ({
  fast = true,
  registerFieldName,
  propsChangedTriggerKey,
  ...rest
}) => {
  // useEffect(() => {
  //   if (registerFieldName) {
  //     registerFieldName(rest.name)
  //   }
  // }, [registerFieldName, rest.name])

  const { options, src } = rest
  const key = useMemo(() => {
    // console.log('uuidv4()', uuidv4(), rest)
    return uuidv4()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, src])

  return fast ? (
    <FormikFastField key={key} {...rest} />
  ) : (
    <FormikField {...rest} />
  )
}

// const enhancer = compose(
//   getContext({
//     registerFieldName: PropTypes.func,
//   }),
// )

export default Field
