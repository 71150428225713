import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react'
import { PageLayout, Table } from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import { clone, filter, get, isEqual } from 'lodash'
import { cleanFilter } from '@common/helper'

const SocialCoinIndexPage = (props) => (
  <PageLayout title="สะกิดคอยน์">
    <Table
      $ref={props.$table}
      filters={[
        {
          description: 'กรองด้วยฤดูกาลแข่ง',
          dataField: 'seasonId',
          type: 'select',
          options: props.seasonOptions,
          size: 2,
        },
        {
          description: 'กรองด้วยรอบแข่งที่',
          dataField: 'cycleId',
          type: 'select',
          options: props.cycleOptions,
          size: 2,
        },
        {
          description: 'กรองด้วยทีม',
          dataField: 'teamId',
          type: 'select',
          options: props.teamOptions,
          size: 2,
        },
        {
          description: 'กรองด้วยผู้แข่งขัน',
          dataField: 'attendee',
          type: 'search',
        },
        {
          description: 'กรองด้วยรูปแบบ',
          dataField: 'action',
          type: 'select',
          options: props.actionOptions,
        },
      ]}
      columns={[
        {
          title: 'ผู้แข่งขัน',
          dataField: 'fullName',
          align: 'left',
          sort: true,
        },
        {
          title: 'ฤดูกาลแข่ง',
          dataField: 'season',
          align: 'left',
          sort: true,
        },
        {
          title: 'รอบการแข่ง',
          dataField: 'cycle',
          sort: true,
        },
        {
          title: 'ทีม',
          dataField: 'team',
          sort: true,
        },
        {
          title: 'จำนวนเหรียญ',
          dataField: 'amount',
          sort: true,
        },
        {
          title: 'ผู้ส่ง',
          dataField: 'sender',
        },
        {
          title: 'สร้างเมื่อ',
          dataField: 'createdAt',
          sort: true,
          type: 'date',
        },
        {
          title: 'คำอธิบาย',
          dataField: 'description',
          sort: true,
        },
      ]}
      entities={props.socialCoins}
      paging={props.paging}
      onQuery={props.onQuery}
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    fetchSocialCoins: stores.socialCoinStore.fetchSocialCoins,
    socialCoins: stores.socialCoinStore.socialCoins,
    configs: stores.socialCoinStore.configs,
    paging: stores.socialCoinStore.paging,
    fetchConfigs: stores.socialCoinStore.fetchConfigs,
  })),
  withHooks((props) => {
    const {
      configs,
      socialCoins,
      fetchSocialCoins,
      paging,
      fetchConfigs,
    } = props
    const [queryParams, setQueryParams] = useState({})

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    const seasonOptions = useMemo(() => {
      return get(configs, 'filters.seasonId.options')
    }, [configs])

    const actionOptions = useMemo(() => {
      return get(configs, 'filters.action.options')
    }, [configs])

    const { seasonId, cycleId } = queryParams

    const cycleOptions = useMemo(() => {
      const options = get(configs, 'filters.cycleId.options', [])
      return filter(options, { seasonId })
    }, [configs, seasonId])

    const teamOptions = useMemo(() => {
      const options = get(configs, 'filters.teamId.options', [])
      return filter(options, { cycleId })
    }, [configs, cycleId])

    const $table = useRef()
    const onQuery = useCallback(
      (params) => {
        const filteredParams = clone(params)

        cleanFilter(filteredParams, 'seasonId', seasonOptions)
        cleanFilter(filteredParams, 'cycleId', cycleOptions)
        cleanFilter(filteredParams, 'teamId', teamOptions)

        if (!isEqual(params, filteredParams)) {
          $table.current.setFilter(filteredParams)
        } else if (!isEqual(filteredParams, queryParams)) {
          setQueryParams(filteredParams)
          fetchSocialCoins(filteredParams)
        }
      },
      [fetchSocialCoins, seasonOptions, cycleOptions, teamOptions, queryParams],
    )

    const newSocialCoins = useMemo(() => {
      return socialCoins.map((item) => {
        return {
          ...item,
          fullName: item.attendee,
        }
      })
    }, [socialCoins])

    return {
      $table,
      socialCoins: newSocialCoins,
      onQuery,
      paging,
      seasonOptions,
      cycleOptions,
      teamOptions,
      actionOptions,
    }
  }),
)

export default enhancer(SocialCoinIndexPage)
