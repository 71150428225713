import React, { useCallback } from 'react'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import { Grid, Notification, Field, Select, Button } from '@components'
import { get, isEmpty } from 'lodash'
import DialogActions from '@material-ui/core/DialogActions'

const MatchUpModal = (props) => (
  <>
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Field
          name="coachId"
          options={props.coachOptions || []}
          component={Select}
          label="Coach"
        />
      </Grid>
    </Grid>
    <DialogActions>
      <Button onClick={props.onCancel} color="light" class="ml-5" size="small">
        Cancel
      </Button>
      <Button
        type="submit"
        onClick={props.handleSubmit}
        color="primary"
        size="small">
        SAVE
      </Button>
    </DialogActions>
  </>
)

const enhancer = compose(
  withStores((stores) => ({
    closeConfirmModal: stores.appStore.closeConfirmModal,
    assignCoach: stores.matchUpStore.assignCoach,
    configs: stores.matchUpStore.configs,
    fetchMatchUps: stores.matchUpStore.fetchMatchUps,
  })),
  withFormik({
    handleSubmit: async (values, formikBag) => {
      const { coachId } = values
      const attendeeIds = get(formikBag, 'props.attendeeIds', [])
      const queryParams = get(formikBag, 'props.queryParams', {})
      const onSuccess = get(formikBag, 'props.onSuccess', null)
      if (!isEmpty(attendeeIds)) {
        await formikBag.props.assignCoach({
          attendeeIds,
          coachId,
        })
      }
      if (onSuccess) {
        onSuccess()
      }
      formikBag.props.fetchMatchUps(queryParams)
      formikBag.props.closeConfirmModal()
      Notification.success()
    },
  }),
  withHooks((props) => {
    const { handleSubmit, closeConfirmModal } = props
    const onCancel = useCallback(() => {
      closeConfirmModal()
    }, [closeConfirmModal])
    const coachOptions = get(props, 'configs.attributes.coachId.options')

    return {
      handleSubmit,
      onCancel,
      coachOptions,
    }
  }),
)

export default enhancer(MatchUpModal)
