import { useCallback, useEffect } from 'react'
import { Notification } from '@components'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import * as yup from 'yup'
import { EmployeeEditPage } from '../edit/index'

const enhancer = compose(
  withStores((stores) => ({
    createSeason: stores.seasonStore.createSeason,
    createEmployee: stores.employeeStore.createEmployee,
    configs: stores.seasonStore.configs,
    fetchConfigs: stores.seasonStore.fetchConfigs,
  })),
  withFormik({
    validationSchema: yup.object().shape({
      code: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      phoneNumber: yup.string().required(),
    }),
    handleSubmit: async (values, formikBag) => {
      await formikBag.props.createEmployee(values)
      Notification.success()
      paths.currentCompanyPath().employeesPath().push()
    },
  }),
  withHooks((props) => {
    const { handleReset, handleSubmit, fetchConfigs } = props

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    const goBack = useCallback(() => {
      paths.currentCompanyPath().employeesPath().push()
    }, [])

    return {
      goBack,
      reset: handleReset,
      save: handleSubmit,
      title: 'สร้างพนักงาน',
      currentPage: 'new',
    }
  }),
)

export default enhancer(EmployeeEditPage)
