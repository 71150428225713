import React, { useCallback, useRef, useEffect } from 'react'
import {
  BrowseButton,
  Modal,
  PageLayout,
  Table,
  Notification,
  ExcelGenerator,
} from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import { cloneDeep, get } from 'lodash'
import { sleep } from '@common/helper'
import { format } from 'date-fns'
import paths from '@common/paths'
import { downloadStaticFile } from '@common/api'
import ExportStrainEmployeeModal from './ExportStrainEmployeeModal'

const EmployeeIndexPage = (props) => (
  <PageLayout
    title="พนักงาน"
    actions={[
      {
        label: 'ออกรายงานความเครียด',
        icon: 'download',
        color: 'danger',
        onClick: props.onExportStrain,
        authorized: ['admin'],
      },
      {
        label: 'สร้างพนักงาน',
        icon: 'plus',
        color: 'primary',
        onClick: props.onCreate,
      },
      {
        label: 'ออกรายงาน',
        icon: 'download',
        color: 'primary',
        onClick: props.onExport,
      },
      {
        label: 'นำเข้าข้อมูลพนักงาน',
        icon: 'file-import',
        color: 'primary',
        onBrowse: props.onImport,
        Component: BrowseButton,
      },
      {
        label: 'ตัวอย่างไฟล์ข้อมูลพนักงาน',
        icon: 'file-excel',
        onClick: props.onDownloadExampleImportFile,
      },
    ]}>
    <Table
      $ref={props.$table}
      filters={[
        {
          description: 'กรองด้วยแผนก',
          dataField: 'department',
          type: 'select',
          options: props.departmentOptions,
        },
        {
          description: 'กรองด้วยทีม',
          dataField: 'team',
          type: 'select',
          options: props.teamOptions,
        },
        {
          description: 'กรองด้วยรหัส',
          dataField: 'code',
          type: 'search',
        },
        {
          description: 'กรองด้วยเบอร์โทรศัพท์',
          dataField: 'phoneNumber',
          type: 'search',
        },
        {
          description: 'กรองด้วยอีเมล',
          dataField: 'email',
          type: 'search',
          className: 'pl-lg-4 pl-md-4',
        },
      ]}
      defaultSorted={[{ dataField: 'code', order: 'asc' }]}
      columns={[
        {
          title: 'รหัส',
          dataField: 'code',
          align: 'left',
          sort: true,
        },
        {
          title: 'ชื่อ-นามสกุล',
          dataField: 'fullName',
          align: 'left',
          sort: true,
          type: 'link',
          onClick: props.gotoShowPage,
        },
        {
          title: 'เบอร์โทรศัพท์',
          dataField: 'phoneNumber',
          sort: true,
        },
        {
          title: 'อีเมล',
          dataField: 'email',
        },
        {
          title: 'แผนก',
          dataField: 'department',
          sort: true,
        },
        {
          title: 'ทีม',
          dataField: 'team',
          sort: true,
        },
        {
          title: 'ฤดูกาลปัจจุบัน',
          dataField: 'currentActiveSeason',
        },
        {
          title: 'รอบปัจจุบัน',
          dataField: 'currentActiveCycle',
        },
        {
          title: 'โค้ชปัจจุบัน',
          dataField: 'currentActiveCoachName',
        },
        {
          title: 'จำนวนวันที่เข้าระบบ',
          dataField: 'activeDayAmount',
        },
        {
          title: 'ใช้ระบบล่าสุด',
          dataField: 'lastActiveAt',
          type: 'date',
        },
        {
          title: 'แพลทฟอร์ม',
          dataField: 'platform',
        },
        {
          title: 'IP',
          dataField: 'ip',
        },
        {
          title: 'เครื่องที่ใช้',
          dataField: 'device',
        },
        {
          title: 'เวอร์ชั่น',
          dataField: 'appVersion',
        },
        {
          title: 'เลขบิวต์',
          dataField: 'buildVersion',
        },
        {
          title: 'สถานะ',
          dataField: 'state',
        },
        {
          title: 'การดำเนินการ',
          type: 'actions',
          actions: [
            {
              label: 'Reset Pin & Logout',
              icon: 'sign-out-alt',
              hoverColor: 'primary',
              onClick: props.onClearPinEmployee,
              visible: (employee) => employee.pinCode,
            },
            {
              label: 'แก้ไข',
              icon: 'pen',
              hoverColor: 'primary',
              onClick: props.onEdit,
              visible: (employee) =>
                employee.canDelete || employee.canTerminate,
            },
            {
              label: 'ลบ',
              icon: 'trash',
              hoverColor: 'danger',
              onClick: props.onDelete,
              hidden: (employee) => !employee.canDelete,
            },
            {
              label: 'ระงับการใช้งาน',
              icon: 'ban',
              hoverColor: 'danger',
              onClick: props.onTerminate,
              hidden: (employee) =>
                employee.canDelete || !employee.canTerminate,
            },
            {
              label: 'กลับเข้าใช้งาน',
              icon: 'undo',
              hoverColor: 'primary',
              onClick: props.onUnterminate,
              hidden: (employee) => !employee.canUnterminate,
            },
          ],
        },
      ]}
      entities={props.employees}
      paging={props.paging}
      onQuery={props.onQuery}
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    employees: stores.employeeStore.employees,
    fetchEmployees: stores.employeeStore.fetchEmployees,
    fetchConfigs: stores.employeeStore.fetchConfigs,
    clearPinEmployee: stores.employeeStore.clearPinEmployee,
    configs: stores.employeeStore.configs,
    importEmployees: stores.employeeStore.importEmployees,
    deleteEmployee: stores.employeeStore.deleteEmployee,
    terminateEmployee: stores.employeeStore.terminateEmployee,
    unterminateEmployee: stores.employeeStore.unterminateEmployee,
    paging: stores.employeeStore.paging,
  })),
  withHooks((props) => {
    const {
      employees,
      fetchEmployees,
      clearPinEmployee,
      importEmployees,
      paging,
      deleteEmployee,
      terminateEmployee,
      unterminateEmployee,
      fetchConfigs,
      configs,
    } = props

    const teamOptions = get(configs, 'filters.team.options')
    const departmentOptions = get(configs, 'filters.department.options')

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    const transformEmployees = useCallback((employees) => {
      employees.forEach((employee) => {
        const { activeAttendee } = employee
        const cycle = get(activeAttendee, 'cycle')
        employee.currentActiveCycle = get(cycle, 'round')
        employee.currentActiveSeason = get(cycle, 'season.title')
        employee.currentActiveCoachName = activeAttendee?.coachName
        employee.fullName = `${employee.firstName || ''} ${
          employee.lastName || ''
        }`
      })
    }, [])

    const onQuery = fetchEmployees
    const customEmployees = cloneDeep(employees)

    transformEmployees(customEmployees)

    const onImport = useCallback(
      async (file) => {
        await importEmployees(file)
        await sleep(2000)
        await fetchEmployees()
        Notification.success('Import employee success.')
      },
      [importEmployees, fetchEmployees],
    )

    const onDelete = useCallback(
      async (employee) => {
        if (employee.canDelete) {
          Modal.confirm({
            children: `ยืนยันการลบ พนักงาน ${employee.firstName} ${employee.lastName}`,
            onOk: async (modal) => {
              await deleteEmployee(employee.id)
              Notification.success('Delete success.')
              modal.close()
            },
          })
        }
      },
      [deleteEmployee],
    )

    const onTerminate = useCallback(
      async (employee) => {
        if (employee.canTerminate) {
          Modal.confirm({
            children: `ยืนยันการระงับการใช้งาน พนักงาน ${employee.firstName} ${employee.lastName} การดำเนินการนี้จะทำให้การเข้าร่วมในฤดูการทั้งหมดจบลงและไม่สามารถย้อนกลับได้`,
            onOk: async (modal) => {
              await terminateEmployee(employee.id)
              await fetchEmployees()
              Notification.success('Terminate success.')
              modal.close()
            },
          })
        }
      },
      [terminateEmployee, fetchEmployees],
    )

    const onUnterminate = useCallback(
      async (employee) => {
        if (employee.canUnterminate) {
          Modal.confirm({
            children: `ยืนยันการกลับเข้าใช้งาน พนักงาน ${employee.firstName} ${employee.lastName}`,
            onOk: async (modal) => {
              await unterminateEmployee(employee.id)
              await fetchEmployees()
              Notification.success('Unterminate success.')
              modal.close()
            },
          })
        }
      },
      [unterminateEmployee, fetchEmployees],
    )

    const onClearPinEmployee = useCallback(
      async (employee) => {
        Modal.confirm({
          children:
            'หลังจากดำเนินการนี้สำเร็จ โปรดแจ้งผู้ใช้ให้ปิดและเปิดแอพเพื่อทำการเข้าสู่ระบบอีกครั้ง',
          onOk: async (modal) => {
            await clearPinEmployee([employee.id])
            await fetchEmployees()
            Notification.success(
              'สำเร็จ โปรดแจ้งผู้ใช้ให้ปิดและเปิดแอพเพื่อทำการเข้าสู่ระบบอีกครั้ง',
            )
            modal.close()
          },
        })
      },
      [clearPinEmployee, fetchEmployees],
    )

    const onCreate = useCallback(() => {
      paths.currentCompanyPath().employeesNewPath().push()
    }, [])

    const onEdit = useCallback((employee) => {
      paths.currentCompanyPath().employeeEditPath(employee.id).push()
    }, [])

    const gotoShowPage = useCallback((employee) => {
      paths.currentCompanyPath().employeePath(employee.id).push()
    }, [])

    const $table = useRef()

    const onExport = useCallback(async () => {
      const data = await fetchEmployees(
        {
          ...$table.current.getQueryParams(),
          page: 1,
          perPage: 1000000,
        },
        { preventSetState: true },
      )

      transformEmployees(data)

      await ExcelGenerator.generate({
        fileName: `employees-${format(new Date(), 'yyyyMMddhhmmss')}`,
        columns: [
          {
            title: 'รหัส',
            field: 'code',
          },
          {
            title: 'ชื่อ',
            field: 'firstName',
          },
          {
            title: 'นามสกุล',
            field: 'lastName',
          },
          {
            title: 'เบอร์',
            field: 'phoneNumber',
          },
          {
            title: 'แผนก',
            field: 'department',
          },
          {
            title: 'ทีม',
            field: 'team',
          },
          {
            title: 'ฤดูกาลปัจจุบัน',
            field: 'currentActiveSeason',
          },
          {
            title: 'รอบปัจจุบัน',
            field: 'currentActiveCycle',
          },
          {
            title: 'โค้ชปัจจุบัน',
            field: 'currentActiveCoachName',
          },
          {
            title: 'จำนวนวันที่เข้าระบบ',
            field: 'activeDayAmount',
          },
          {
            title: 'ใช้ระบบล่าสุด',
            field: 'lastActiveAt',
          },
          {
            title: 'แพลทฟอร์ม',
            field: 'platform',
          },
          {
            title: 'IP',
            field: 'ip',
          },
          {
            title: 'เครื่องที่ใช้',
            field: 'device',
          },
          {
            title: 'เวอร์ชั่น',
            field: 'appVersion',
          },
          {
            title: 'เลขบิวต์',
            field: 'buildVersion',
          },
        ],
        data,
      })
    }, [fetchEmployees, $table, transformEmployees])

    const onDownloadExampleImportFile = useCallback(async () => {
      await downloadStaticFile('/download_employee_excel_template')
    }, [])

    const onExportStrain = useCallback(async () => {
      Modal.open({
        title: 'ระบุช่วงเวลาที่ค้องการค้นหาพนักงานที่มีความเครียด',
        children: (
          <ExportStrainEmployeeModal
            onSubmit={async ({ strainStartDate, strainEndDate }) => {
              const data = await fetchEmployees(
                {
                  ...$table.current.getQueryParams(),
                  page: 1,
                  perPage: 1000000,
                  type: 'strain',
                  strainStartDate,
                  strainEndDate,
                },
                { preventSetState: true },
              )
              await ExcelGenerator.generate({
                fileName: `strain-employees-${format(
                  strainStartDate,
                  'yyyyMMddhhmmss',
                )}`,
                columns: [
                  {
                    title: 'รหัส',
                    field: 'code',
                  },
                  {
                    title: 'ชื่อ',
                    field: 'firstName',
                  },
                  {
                    title: 'นามสกุล',
                    field: 'lastName',
                  },
                  {
                    title: 'เบอร์',
                    field: 'phoneNumber',
                  },
                  {
                    title: 'แผนก',
                    field: 'department',
                  },
                  {
                    title: 'ทีม',
                    field: 'team',
                  },
                ],
                data,
              })
              Modal.close()
            }}
          />
        ),
        hideFooter: true,
      })
    }, [fetchEmployees, $table])

    return {
      $table,
      employees: customEmployees,
      onQuery,
      onDelete,
      onTerminate,
      onUnterminate,
      onImport,
      paging,
      onClearPinEmployee,
      onCreate,
      onExport,
      onExportStrain,
      onDownloadExampleImportFile,
      onEdit,
      gotoShowPage,
      teamOptions,
      departmentOptions,
    }
  }),
)

export default enhancer(EmployeeIndexPage)
