/* eslint-disable no-unused-vars */
import React from 'react'
import { PaginationTotalStandalone } from 'react-bootstrap-table2-paginator'
import Select from '@components/Select'
import styled from '@styled'

const SizePerPageSelector = styled(Select)`
  .MuiInputBase-root {
    background-color: #f3f6f9;
    height: calc(1.35em + 1.1rem + 2px);
    padding: 0.55rem 0.75rem;
    font-size: 0.925rem;
    line-height: 1.35;
    border-radius: 0.28rem;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }
`

export function PaginationToolbar(props) {
  const { isLoading, paginationProps } = props
  const {
    sizePerPageList,
    sizePerPage,
    onSizePerPageChange = [
      { text: '3', value: 3 },
      { text: '5', value: 5 },
      { text: '10', value: 10 },
    ],
  } = paginationProps

  return (
    <div className="d-flex align-items-center py-3">
      {isLoading && (
        <div className="d-flex align-items-center">
          <div className="mr-2 text-muted">Loading...</div>
          <div className="spinner spinner-primary mr-10"></div>
        </div>
      )}
      <SizePerPageSelector
        className="w-75px mr-1"
        value={sizePerPage}
        onChange={onSizePerPageChange}
        options={sizePerPageList}
        fullWidth={false}
        disableClearable
        disableAutoComplete
      />
      <PaginationTotalStandalone className="text-muted" {...paginationProps} />
    </div>
  )
}
