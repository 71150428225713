import { useState, useEffect } from 'react'

const useEffectWithDelay = (callback, delay, dependencies = []) => {
  const [CACHE] = useState({})

  useEffect(() => {
    if (CACHE.task) {
      clearTimeout(CACHE.task)
    }
    CACHE.task = setTimeout(callback, delay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

export default useEffectWithDelay