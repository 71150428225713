import * as yup from 'yup'

export const missionTemplateSchema = yup.object().shape({
  title: yup.string().required(),
  category: yup.string().required(),
  description: yup.string().required(),
  rules: yup.string().required(),
  reward: yup.string().required(),
  condition: yup.string().required(),
  // dailyMissionTemplates: yup.array().of(
  //   yup.object().shape({
  //     checked: yup.boolean(),
  //     // date: yup.date().when('checked', { is: true, then: s => s.required() }),
  //     healthScoreReward: yup.string().when('checked', { is: true, then: s => s.required() }),
  //     answerTemplate: yup.object().when('checked', { is: true, then: s => s.shape({
  //       type: yup.string().required(),
  //       question: yup.string().when('type', { is: 'choices', then: s => s.required() }),
  //       choices: yup.array().when('type', { is: 'choices', then: s => s.min(2).of(
  //         yup.string().required()
  //       )}),
  //     }) }),
  //     missionArticleTemplates: yup.array().of(
  //       yup.string().when('checked', { is: true, then: s => s.required() }),
  //     )
  //   })
  // )
})
