import ActiveStore from './ActiveStore'

class CoachStore extends ActiveStore {
  constructor() {
    super('coach')
  }

  resendConfirmation = async id => {
    await this.server.post(`/${id}/resend`)
  }
}

export default new CoachStore()
