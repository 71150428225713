import React from 'react'
import { compose, withHooks } from '@enhancers'
import {
  Grid,
  Field,
  FieldArray,
  Input,
  Select,
  Typography,
  FormSpy,
} from '@components'
import AnswerTemplateChoiceInfo from './AnswerTemplateChoiceInfo'

const AnswerTemplateInfo = (props) => (
  <Grid item container direction="column" spacing={2} sm={6} xs={12}>
    <Grid item>
      <Typography>รูปแบบการตอบภารกิจ</Typography>
    </Grid>
    <Grid item>
      <Field
        label="ประเภทคำตอบ"
        name={`${props.name}.type`}
        options={props.answerTypeOptions}
        component={Select}
        placeholder="ประเภทคำตอบ"
        helperText="โปรดเลือกประเภทคำตอบ"
        required
        disabled={props.disabled}
      />
    </Grid>
    <FormSpy
      subscription={`${props.name}.type`}
      hidden={([type]) => type !== 'choices'}>
      <Grid item>
        <Field
          label="คำถาม"
          name={`${props.name}.question`}
          component={Input}
          placeholder="คำถาม"
          helperText="โปรดระบุคำถาม"
          required
          disabled={props.disabled}
        />
      </Grid>
      <FieldArray name={`${props.name}.choices`}>
        {(formikBag) => (
          <AnswerTemplateChoiceInfo {...formikBag} disabled={props.disabled} />
        )}
      </FieldArray>
    </FormSpy>
  </Grid>
)

const enhancer = compose(
  withHooks((props) => {
    const { name, answerTypeOptions, disabled } = props

    return {
      name,
      answerTypeOptions,
      disabled,
    }
  }),
)

export default enhancer(AnswerTemplateInfo)
