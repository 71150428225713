import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PageLayout, Notification } from '@components'
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  defaultProps,
} from '@enhancers'
import paths from '@common/paths'
import { times, filter, reject, forEach, cloneDeep, isEmpty, map } from 'lodash'
import MissionTemplateInfo from './MissionTemplateInfo'
import DailyMissionTemplateCollapse from './DailyMissionTemplateCollapse'
import { missionTemplateSchema } from './missionTemplateValidator'
import FloatingSaveButton from './FloatingSaveButton'

export const MissionTemplatePage = (props) => (
  <PageLayout
    title={props.title}
    actions={[
      {
        label: 'กลับ',
        icon: 'arrow-left',
        onClick: props.onBack,
      },
      {
        label: 'ล้างค่าที่กรอก',
        icon: 'redo',
        onClick: props.onReset,
      },
      {
        label: 'บันทึก',
        icon: 'save',
        color: 'primary',
        onClick: props.onSave,
        type: 'submit',
      },
    ]}>
    <MissionTemplateInfo
      className="mb-5"
      coverImageUrl={props.coverImageUrl}
      tagOptions={props.tagOptions}
      categoryOptions={props.categoryOptions}
    />
    {times(props.dayAmount, (index) => (
      <DailyMissionTemplateCollapse
        key={index}
        day={index + 1}
        tagOptions={props.tagOptions}
        answerTypeOptions={props.answerTypeOptions}
        articleTemplateOptions={props.articleTemplateOptions}
      />
    ))}
    <FloatingSaveButton />
  </PageLayout>
)

export const mapValuesToParams = (values) => {
  const { id, dailyMissionTemplates, ...params } = cloneDeep(values)

  // Select used day
  params.usedDailyMissionTemplates = filter(dailyMissionTemplates, {
    checked: true,
  })

  // Select unused  day
  params.unusedDailyMissionTemplates = reject(dailyMissionTemplates, {
    checked: true,
  })

  forEach(params.usedDailyMissionTemplates, (dailyMissionTemplate) => {
    if (dailyMissionTemplate.answerTemplate) {
      switch (dailyMissionTemplate.answerTemplate.type) {
        case 'choices':
          // full fill daily choice answers
          dailyMissionTemplate.answerTemplate.answers = map(
            dailyMissionTemplate.answerTemplate.choices,
            (_choice, index) => {
              return dailyMissionTemplate.answerTemplate.answers[index] || false
            },
          )
          break
        default:
          // clear answer template for each type
          dailyMissionTemplate.answerTemplate = {
            type: dailyMissionTemplate.answerTemplate.type,
          }
          break
      }
    }

    // remove non selected article
    dailyMissionTemplate.missionArticleTemplates = filter(
      dailyMissionTemplate.missionArticleTemplates,
      (articleTemplate) => {
        return articleTemplate?.articleTemplateId
      },
    )

    // remove empty article for update with image (multipart)
    if (isEmpty(dailyMissionTemplate.missionArticleTemplates)) {
      dailyMissionTemplate.missionArticleTemplates = undefined
    }
  })

  forEach(params.unusedDailyMissionTemplates, (dailyMissionTemplate) => {
    // remove empty article for update with image (multipart)
    if (isEmpty(dailyMissionTemplate.missionArticleTemplates)) {
      dailyMissionTemplate.missionArticleTemplates = undefined
    }
  })

  return { id, ...params }
}

export const transformErrorsWithParams = (e, params) => {
  if (e.name === 'ApiValidationError') {
    const errorDayNoList = []

    e.data.dailyMissionTemplates = []

    forEach(e.data.usedDailyMissionTemplates, (error, index) => {
      if (error) {
        const dayNo = params.usedDailyMissionTemplates[index].dayNo
        errorDayNoList.push(dayNo)
        e.data.dailyMissionTemplates[dayNo - 1] = error
      }
    })

    forEach(e.data.unusedDailyMissionTemplates, (error, index) => {
      if (error) {
        const dayNo = params.unusedDailyMissionTemplates[index].dayNo
        errorDayNoList.push(dayNo)
        e.data.dailyMissionTemplates[dayNo - 1] = error
      }
    })

    const errorDaysMessage = map(
      errorDayNoList,
      (dayNo) => `ข้อมูลวันที่ ${dayNo} ไม่ถูกต้อง`,
    ).join(', ')

    if (errorDaysMessage) {
      Notification.error(errorDaysMessage)
    }
  }
  return e
}

const enhancer = compose(
  defaultProps({
    dayAmount: 28,
  }),
  withStores((stores) => ({
    configs: stores.missionTemplateStore.configs,
    currentMissionTemplate: stores.missionTemplateStore.currentMissionTemplate,
    findMissionTemplate: stores.missionTemplateStore.findMissionTemplate,
    updateMissionTemplate: stores.missionTemplateStore.updateMissionTemplate,
    fetchConfigs: stores.missionTemplateStore.fetchConfigs,
  })),
  withFormik({
    mapPropsToValues: (props) => {
      const initialValues = {
        title: undefined,
        category: undefined,
        description: undefined,
        condition: undefined,
        reward: undefined,
        rules: undefined,
        ...props.currentMissionTemplate,
      }

      if (initialValues.dailyMissionTemplates) {
        const fullDailyMissionTemplates = []

        forEach(initialValues.dailyMissionTemplates, (dailyMissionTemplate) => {
          fullDailyMissionTemplates[
            dailyMissionTemplate.dayNo - 1
          ] = dailyMissionTemplate
        })

        times(props.dayAmount).forEach((index) => {
          if (fullDailyMissionTemplates[index]) {
            const { used, ...otherProps } = fullDailyMissionTemplates[index]
            fullDailyMissionTemplates[index] = {
              ...otherProps,
              checked: used,
            }
          } else {
            fullDailyMissionTemplates[index] = { dayNo: index + 1 }
          }
        })

        initialValues.dailyMissionTemplates = fullDailyMissionTemplates
      }

      return initialValues
    },
    validate: missionTemplateSchema,
    handleSubmit: async (values, formikBag) => {
      const { id, ...params } = mapValuesToParams(values)

      try {
        await formikBag.props.updateMissionTemplate(id, params)
        Notification.success()

        formikBag.resetForm({ values })
      } catch (e) {
        throw transformErrorsWithParams(e, params)
      }
    },
  }),
  withHooks((props) => {
    const {
      handleReset,
      handleSubmit,
      fetchConfigs,
      configs,
      findMissionTemplate,
      currentMissionTemplate,
      dayAmount,
    } = props
    const { missionTemplateId } = useParams()

    useEffect(() => {
      fetchConfigs()
    }, [fetchConfigs])

    useEffect(() => {
      findMissionTemplate(missionTemplateId)
    }, [findMissionTemplate, missionTemplateId])

    const onBack = useCallback(() => {
      paths.missionTemplatesPath().push()
    }, [])

    const title = `แก้ไขต้นแบบภารกิจ '${currentMissionTemplate?.title || ''}'`
    const coverImageUrl = currentMissionTemplate?.coverImageUrl
    const tagOptions = configs?.attributes?.tag?.options || []
    const categoryOptions =
      configs?.attributes?.missionTemplateCategory?.options || []
    const answerTypeOptions = configs?.attributes?.category?.options || []
    const articleTemplateOptions =
      configs?.attributes?.articleTemplate?.options || []

    return {
      onBack,
      onReset: handleReset,
      onSave: handleSubmit,
      dayAmount,
      title,
      coverImageUrl,
      tagOptions,
      categoryOptions,
      answerTypeOptions,
      articleTemplateOptions,
    }
  }),
)

export default enhancer(MissionTemplatePage)
