import React, { useMemo, useEffect } from 'react'
import {
  Field,
  Grid,
  PageLayout,
  Notification,
  DatePicker,
  Image,
  Select,
} from '@components'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import { useParams } from 'react-router-dom'
import { filter, omit } from 'lodash'
import DailyMissionTemplateInfo from '../../mission_templates/show/DailyMissionTemplateInfo'

export const MissionEditPage = (props) => (
  <PageLayout title={props.title} actions={props.pageActions}>
    <Grid container spacing={2}>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="องค์กร"
          name="mission.companyId"
          component={Select}
          options={props.companyOptions}
          placeholder="องค์กร"
          helperText="โปรดเลือกองค์กร"
          disabled={props.disabled || !props.canEditDate}
          required
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ฤดูกาลแข่ง"
          name="mission.seasonId"
          component={Select}
          options={props.seasonOptions}
          placeholder="ฤดูกาลแข่ง"
          helperText="โปรดเลือกฤดูกาลแข่ง"
          disabled={props.disabled || !props.canEditDate}
          required
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="รอบแข่ง"
          name="mission.cycleId"
          component={Select}
          options={props.cycleOptions}
          placeholder="รอบแข่ง"
          helperText="โปรดเลือกรอบแข่ง"
          disabled={props.disabled || !props.canEditDate}
          required
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ทีม"
          name="mission.teamId"
          component={Select}
          options={props.teamOptions}
          placeholder="ทีม"
          helperText="โปรดเลือกทีม"
          disabled={props.disabled || !props.canEditDate}
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ผู้แข่งขัน"
          name="mission.attendeeId"
          component={Select}
          options={props.attendeeOptions}
          placeholder="ผู้แข่งขัน"
          helperText="โปรดเลือกผู้แข่งขัน"
          disabled={props.disabled || !props.canEditDate}
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="วันที่"
          name="mission.date"
          component={DatePicker}
          placeholder="วันที่"
          helperText="โปรดเลือกวันที่"
          required
          disabled={props.disabled || !props.canEditDate}
        />
      </Grid>
    </Grid>
    <Grid container className="mt-10 mb-10" spacing={1} justify="center">
      <Field
        name="mission.coverImage"
        src={props.coverImageUrl}
        component={Image}
        variant="rounded"
        size={400}
        ratio="16:9"
        disabled={props.disabled}
      />
    </Grid>
    <DailyMissionTemplateInfo
      name="mission"
      tagOptions={props.tagOptions}
      answerTypeOptions={props.answerTypeOptions}
      articleTemplateOptions={props.articleTemplateOptions}
      disabled={props.disabled}
    />
  </PageLayout>
)
const enhancer = compose(
  withStores((stores) => ({
    configs: stores.missionStore.configs,
    findMission: stores.missionStore.findMission,
    updateMission: stores.missionStore.updateMission,
    fetchConfigs: stores.missionStore.fetchConfigs,
    currentMission: stores.missionStore.currentMission,
  })),
  withFormik({
    mapPropsToValues: (props) => {
      return { mission: props.currentMission || {} }
    },
    namespace: 'mission',
    handleSubmit: async (values, formikBag) => {
      let { id, ...params } = values.mission || {}

      // remove non selected article
      params.missionArticleTemplates = filter(
        params.missionArticleTemplates,
        (articleTemplate) => {
          return articleTemplate?.articleTemplateId
        },
      )

      if (params.missionArticleTemplates.length === 0) {
        params = omit(params, ['missionArticleTemplates'])
      }

      await formikBag.props.updateMission(id, params)
      Notification.success()
      paths.missionsPath().push()
    },
  }),
  withHooks((props) => {
    const {
      handleReset,
      handleSubmit,
      fetchConfigs,
      configs,
      currentMission,
      findMission,
      disabled,
      pageActions,
      currentPage,
    } = props
    const { missionId } = useParams()

    useEffect(() => {
      if (currentPage === 'show') {
        fetchConfigs()
      } else {
        fetchConfigs({ cycleActive: true })
      }
    }, [fetchConfigs, currentPage])

    useEffect(() => {
      findMission(missionId)
    }, [findMission, missionId])

    const title = `ภารกิจ '${currentMission?.title || ''}'`
    const companyOptions = configs?.filters?.companyId?.options || []
    const seasonOptions = configs?.filters?.seasonId?.options || []
    const cycleOptions = configs?.filters?.cycleId?.options || []
    const teamOptions = configs?.filters?.teamId?.options || []
    const attendeeOptions = configs?.filters?.attendeeId?.options || []
    const coverImageUrl = currentMission?.coverImageUrl
    const tagOptions = configs?.filters?.tag?.options || []
    const answerTypeOptions = configs?.filters?.answerType?.options || []
    const articleTemplateOptions =
      configs?.filters?.articleTemplate?.options || []
    const canEditDate = currentMission?.canEditDate

    const customPageActions = useMemo(
      () =>
        pageActions || [
          {
            label: 'กลับ',
            icon: 'arrow-left',
            onClick: paths.missionsPath().push,
          },
          {
            label: 'Reset',
            icon: 'redo',
            onClick: handleReset,
          },
          {
            label: 'บันทึก',
            icon: 'save',
            color: 'primary',
            onClick: handleSubmit,
            type: 'submit',
          },
        ],
      [pageActions, handleReset, handleSubmit],
    )

    return {
      pageActions: customPageActions,
      title,
      coverImageUrl,
      canEditDate,
      companyOptions,
      seasonOptions,
      cycleOptions,
      teamOptions,
      attendeeOptions,
      tagOptions,
      answerTypeOptions,
      articleTemplateOptions,
      disabled,
    }
  }),
)

export default enhancer(MissionEditPage)
