import React from 'react'
import { compose, withHooks } from '@enhancers'
import { Grid, Field, FieldArray, Input, Select, Divider } from '@components'
import AnswerTemplateInfo from './AnswerTemplateInfo'
import ArticleTemplateInfo from './ArticleTemplateInfo'

const DailyMissionTemplate = (props) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Field
        name={`${props.name}.title`}
        component={Input}
        label="ชื่อภารกิจ"
        placeholder="ชื่อภารกิจ"
        suggestMaxLength={70}
        helperText={
          props.isTemplate
            ? 'เว้นว่างเอาไว้หากต้องการใช้ชื่อภารกิจต้นแบบ'
            : 'โปรดระบุชื่อภารกิจ'
        }
        required={!props.isTemplate}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item sm={6} xs={12}>
      <Field
        label="แท็ก"
        name={`${props.name}.tags`}
        component={Select}
        multiple
        options={props.tagOptions}
        placeholder="แท็ก"
        helperText={
          props.isTemplate
            ? 'เว้นว่างเอาไว้หากต้องการใช้แท็กต้นแบบ'
            : 'โปรดเลือกแท็ก'
        }
        disabled={props.disabled}
      />
    </Grid>
    <Grid item sm={6} xs={12}>
      <Field
        label="รางวัล (Health Score)"
        name={`${props.name}.healthScoreReward`}
        required
        component={Input}
        placeholder="รางวัล (Health Score)"
        helperText="โปรดระบุรางวัล (Health Score)"
        type="number"
        inputProps={{ min: 0 }}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item sm={6} xs={12}>
      <Field
        label="คำอธิบาย"
        name={`${props.name}.description`}
        component={Input}
        placeholder="คำอธิบาย"
        type="text"
        multiline
        rows={4}
        suggestMaxLength={140}
        helperText={
          props.isTemplate
            ? 'เว้นว่างเอาไว้หากต้องการใช้คำอธิบายต้นแบบ'
            : 'โปรดระบุคำอธิบาย'
        }
        required={!props.isTemplate}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item sm={6} xs={12}>
      <Field
        label="กฏ"
        name={`${props.name}.rules`}
        component={Input}
        placeholder="กฏ"
        type="text"
        rows={4}
        multiline
        suggestMaxLength={140}
        helperText={
          props.isTemplate
            ? 'เว้นว่างเอาไว้หากต้องการใช้กฏต้นแบบ'
            : 'โปรดระบุกฏ'
        }
        required={!props.isTemplate}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item sm={6} xs={12}>
      <Field
        label="เงื่อนไข"
        name={`${props.name}.condition`}
        component={Input}
        placeholder="เงื่อนไข"
        type="text"
        multiline
        rows={4}
        suggestMaxLength={140}
        helperText={
          props.isTemplate
            ? 'เว้นว่างเอาไว้หากต้องการใช้เงื่อนไขต้นแบบ'
            : 'โปรดระบุเงื่อนไข'
        }
        required={!props.isTemplate}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item sm={6} xs={12}>
      <Field
        label="รางวัล"
        name={`${props.name}.reward`}
        component={Input}
        placeholder="รางวัล"
        multiline
        rows={4}
        suggestMaxLength={140}
        helperText={
          props.isTemplate
            ? 'เว้นว่างเอาไว้หากต้องการใช้รางวัลต้นแบบ'
            : 'โปรดระบุรางวัล'
        }
        required={!props.isTemplate}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item container xs={12}>
      <Divider className="w-100" />
    </Grid>
    <Grid item container xs={12}>
      <AnswerTemplateInfo
        name={`${props.name}.answerTemplate`}
        answerTypeOptions={props.answerTypeOptions}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item container xs={12}>
      <Divider className="w-100" />
    </Grid>
    <Grid item container xs={12}>
      <FieldArray name={`${props.name}.missionArticleTemplates`}>
        {(formikBag) => (
          <ArticleTemplateInfo
            {...formikBag}
            articleTemplateOptions={props.articleTemplateOptions}
            disabled={props.disabled}
          />
        )}
      </FieldArray>
    </Grid>
  </Grid>
)

const enhancer = compose(
  withHooks((props) => {
    const {
      name,
      tagOptions,
      answerTypeOptions,
      articleTemplateOptions,
      disabled,
      isTemplate,
    } = props
    return {
      name,
      tagOptions,
      answerTypeOptions,
      articleTemplateOptions,
      disabled,
      isTemplate,
    }
  }),
)

export default enhancer(DailyMissionTemplate)
