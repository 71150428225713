import React from 'react'
import { PageLayout, Table } from '@components'
import { compose, withHooks, withStores } from '@enhancers'
import { css } from '@styled'

const columnClass = css`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ActivityIndex = (props) => (
  <PageLayout title="รายการกิจกรรม">
    <Table
      filters={[
        {
          description: 'กรองด้วยชื่อ',
          dataField: 'employee',
          type: 'search',
        },
        {
          description: 'กรองด้วยวันที่',
          dataField: 'date',
          type: 'date',
        },
      ]}
      columns={[
        {
          title: 'ชื่อพนักงาน',
          dataField: 'employeeName',
          align: 'left',
          className: columnClass,
        },
        {
          title: 'ก้าวเดิน',
          dataField: 'pace',
        },
        {
          title: 'ระยะทาง (เมตร)',
          dataField: 'distance',
        },
        {
          title: 'แคลอรี่ (กิโลแคลอรี่)',
          dataField: 'calories',
        },
        {
          title: 'อัตราการเต้นหัวใจ (bpm เฉลี่ย)',
          dataField: 'heartRate',
        },
        {
          title: 'วันที่',
          dataField: 'date',
          type: 'date',
        },
      ]}
      entities={props.activities}
      paging={props.paging}
      onQuery={props.onQuery}
    />
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    activities: stores.activityStore.activities,
    fetchActivities: stores.activityStore.fetchActivities,
    paging: stores.activityStore.paging,
  })),
  withHooks((props) => {
    const { activities, fetchActivities, paging } = props

    const onQuery = fetchActivities

    return {
      activities,
      onQuery,
      paging,
    }
  }),
)

export default enhancer(ActivityIndex)
