import ActiveStore from './ActiveStore'
class MissionTemplateStore extends ActiveStore {
  constructor() {
    super('missionTemplate')
  }

  setPublished = (id, published) => {
    const nextMissionTemplates = this.state.missionTemplates.map((m) => {
      if (m.id === id) {
        return {
          ...m,
          canPublish: !published,
          canUnpublish: published,
          published: published,
          publishedAt: published ? new Date() : null,
        }
      } else {
        return m
      }
    })

    this.setState({ missionTemplates: nextMissionTemplates })
  }

  publishMissionTemplate = async (id) => {
    await this.server.post('/publish', { mission_template_ids: [id] })
    this.setPublished(id, true)
  }

  unpublishedMissionTemplate = async (id) => {
    await this.server.post('/unpublish', { mission_template_ids: [id] })
    this.setPublished(id, false)
  }
}

export default new MissionTemplateStore()
