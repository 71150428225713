import firebase from 'firebase/app'
import 'firebase/messaging'

import ENV from '@env'

firebase.initializeApp(ENV.FIREBASE_CONFIG)

const getFcmToken = async () => {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging()
    try {
      await messaging.requestPermission()
      const fcmToken = await messaging.getToken()
      return fcmToken
    } catch (e) {
      console.log('User not allow permission', e)
      return null
    }
  }
}

export const initFcmToken = async () => {
  window.FIREBASE_TOKEN = await getFcmToken()
}

initFcmToken()
