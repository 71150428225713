import { env } from '@env'

export function removeCSSClass(ele, cls) {
  const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
  ele.className = ele.className.replace(reg, ' ')
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls)
}

export const toAbsoluteUrl = (pathname) => {
  if (env !== 'production' && pathname === '/media/ewc/ewc-logo.png') {
    pathname = '/media/ewc/ewc-logo.develop.png'
  }
  return process.env.PUBLIC_URL + pathname
}
