import ActiveStore from './ActiveStore'
import { first, filter, find } from 'lodash'

class MissionStore extends ActiveStore {
  constructor() {
    super('mission', {
      configs: {},
    })
  }

  updateSpecialAllowedSendingDateToMissions = async ({ missionIds, date }) => {
    const response = await this.server.put(`/update_special_allowed_date`, {
      missionIds,
      date,
    })
    const { missions: newerMissions } = response.data.data
    const updatedMissions = this.state.missions.map((mission) => {
      const newerMission = find(newerMissions, { id: mission.id })
      return newerMission || mission
    })

    this.setState({ missions: updatedMissions })
  }

  findMission = async (id) => {
    const response = await this.server.get(``, { ids: [id], state: 'all' })
    const missions = response.data.data.missions
    this.setState({ currentMission: first(missions) })
  }

  deleteMission = async (missionId) => {
    await this.server.delete(``, { missionIds: [missionId] })
    this.setState({
      missions: filter(
        this.state.missions,
        ({ id }) => ![missionId].includes(id),
      ),
    })
  }

  approveMission = async (missionId, pacer) => {
    await this.server.put(`/approve`, {
      missionIds: [missionId],
      pacers: [pacer],
    })
    this.setState({
      missions: filter(
        this.state.missions,
        ({ id }) => ![missionId].includes(id),
      ),
    })
  }

  rejectMission = async (params) => {
    await this.server.put(`/reject`, params)
    this.setState({
      missions: filter(
        this.state.missions,
        ({ id }) => !params.missionIds.includes(id),
      ),
    })
  }

  fetchConfigs = async (config) => {
    const response = await this.server.get(`/configs`, config)
    const configs = response.data.data.configs
    this.setState({
      configs,
    })
  }
}

export default new MissionStore()
