import { useEffect, useMemo } from 'react'
import { Notification } from '@components'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
import { filter, omit } from 'lodash'
import { MissionEditPage } from '../edit'

const enhancer = compose(
  withStores((stores) => ({
    configs: stores.missionStore.configs,
    createMission: stores.missionStore.createMission,
    fetchConfigs: stores.missionStore.fetchConfigs,
  })),
  withFormik({
    mapPropsToValues: () => {
      return { mission: {} }
    },
    handleSubmit: async (values, formikBag) => {
      let { ...params } = values.mission || {}

      params.missionArticleTemplates = filter(
        params.missionArticleTemplates,
        (articleTemplate) => {
          return articleTemplate?.articleTemplateId
        },
      )

      if (params.missionArticleTemplates.length === 0) {
        params = omit(params, ['missionArticleTemplates'])
      }

      await formikBag.props.createMission(params)
      Notification.success()
      paths.missionsPath().push()
    },
  }),
  withHooks((props) => {
    const {
      handleReset,
      handleSubmit,
      fetchConfigs,
      configs,
      disabled,
      values,
      setFieldValue,
    } = props

    useEffect(() => {
      fetchConfigs({ cycleActive: true })
    }, [fetchConfigs])

    const title = 'เพิ่มภารกิจ'
    const companyOptions = configs?.filters?.companyId?.options || []
    const seasonOptions = configs?.filters?.seasonId?.options || []
    const cycleOptions = configs?.filters?.cycleId?.options || []
    const teamOptions = configs?.filters?.teamId?.options || []
    const attendeeOptions = configs?.filters?.attendeeId?.options || []
    const tagOptions = configs?.filters?.tag?.options || []
    const answerTypeOptions = configs?.filters?.answerType?.options || []
    const articleTemplateOptions =
      configs?.filters?.articleTemplate?.options || []

    const pageActions = useMemo(
      () => [
        {
          label: 'กลับ',
          icon: 'arrow-left',
          onClick: paths.missionsPath().push,
        },
        {
          label: 'Reset',
          icon: 'redo',
          onClick: handleReset,
        },
        {
          label: 'บันทึก',
          icon: 'save',
          color: 'primary',
          onClick: handleSubmit,
          type: 'submit',
        },
      ],
      [handleReset, handleSubmit],
    )

    const { companyId, seasonId, cycleId, teamId } = values.mission || {}

    useEffect(() => {
      setFieldValue('mission.seasonId', null)
    }, [setFieldValue, companyId])

    useEffect(() => {
      setFieldValue('mission.cycleId', null)
    }, [setFieldValue, seasonId])

    useEffect(() => {
      setFieldValue('mission.teamId', null)
    }, [setFieldValue, cycleId])

    useEffect(() => {
      setFieldValue('mission.attendeeId', null)
    }, [setFieldValue, teamId])

    const customSeasonOptions = useMemo(() => {
      return filter(seasonOptions, { companyId })
    }, [companyId, seasonOptions])

    const customCycleOptions = useMemo(() => {
      return filter(cycleOptions, { seasonId })
    }, [seasonId, cycleOptions])

    const customTeamOptions = useMemo(() => {
      return filter(teamOptions, { cycleId })
    }, [cycleId, teamOptions])

    const customAttendeeOptions = useMemo(() => {
      return filter(attendeeOptions, { teamId })
    }, [teamId, attendeeOptions])

    return {
      pageActions,
      title,
      companyOptions,
      seasonOptions: customSeasonOptions,
      cycleOptions: customCycleOptions,
      teamOptions: customTeamOptions,
      attendeeOptions: customAttendeeOptions,
      tagOptions,
      answerTypeOptions,
      articleTemplateOptions,
      disabled,
      canEditDate: true,
    }
  }),
)

export default enhancer(MissionEditPage)
