import React from 'react'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import {
  PageLayout,
  Grid,
  Field,
  Input,
  DatePicker,
  Notification,
} from '@components'
import * as yup from 'yup'

const CompanyInfoPage = (props) => (
  <PageLayout
    title="ข้อมูลบริษัท"
    actions={[
      {
        label: 'ล้างค่าที่กรอก',
        icon: 'redo',
        onClick: props.onReset,
      },
      {
        label: 'บันทึก',
        icon: 'save',
        color: 'primary',
        onClick: props.onSave,
        type: 'submit',
      },
    ]}>
    <Grid container spacing={3}>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ชื่อองค์กร"
          name="name"
          component={Input}
          placeholder="ชื่อองค์กร"
          required
          helperText="โปรดใส่ชื่อเฉพาะ"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="เริ่มใช้งานระบบ"
          name="subscriptionStartedAt"
          component={DatePicker}
          placeholder="เริ่มใช้งานระบบ"
          helperText="โปรดระบุวันเริ่มใช้งานระบบ"
          required
          disabled
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ใช้งานระบบได้ถึง"
          name="subscriptionEndedAt"
          component={DatePicker}
          placeholder="ใช้งานระบบได้ถึง"
          helperText="โปรดระบุวันสิ้นสุดการใช้งานระบบ"
          required
          disabled
        />
      </Grid>
    </Grid>
  </PageLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    currentCompany: stores.companyStore.currentCompany,
    updateCompany: stores.companyStore.updateCompany,
    findCompany: stores.companyStore.findCompany,
  })),
  withFormik({
    mapPropsToValues: (props) => props.currentCompany || {},
    validationSchema: yup.object().shape({
      name: yup.string().required(),
    }),
    handleSubmit: async (values, formikBag) => {
      const { id, name } = values
      await formikBag.props.updateCompany(id, { name })
      await formikBag.props.findCompany(id)
      Notification.success()
    },
  }),
  withHooks((props) => {
    const { handleReset, handleSubmit } = props

    return {
      onReset: handleReset,
      onSave: handleSubmit,
    }
  }),
)

export default enhancer(CompanyInfoPage)
