import React, { useMemo } from 'react'
import { compose, withHooks } from '@enhancers'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import { Pagination } from '_metronic/_partials/controls'
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from '_metronic/_helpers'
import { css } from '@emotion/css'
import classnames from 'classnames'

const tableStyle = css`
  th {
    white-space: nowrap;
  }

  th.selection-cell-header,
  td.selection-cell {
    width: 36px;
  }
`

const disableSelectTableStyle = css`
  th.selection-cell-header,
  td.selection-cell {
    display: none;
  }
`

const Table = (props) => (
  <PaginationProvider pagination={props.paginationStore}>
    {({ paginationProps, paginationTableProps }) => (
      <Pagination isLoading={props.loading} paginationProps={paginationProps}>
        <BootstrapTable
          wrapperClasses="table-responsive"
          classes={props.className}
          bootstrap4
          bordered={false}
          remote
          keyField="id"
          data={props.entities}
          columns={props.columns}
          defaultSorted={props.defaultSorted}
          onTableChange={getHandlerTableChange(props.setQueryParams)}
          selectRow={getSelectRow({
            entities: props.entities,
            ids: props.selectedIds,
            setIds: props.setSelectedIds,
          })}
          {...paginationTableProps}>
          <PleaseWaitMessage entities={props.entities} />
          <NoRecordsFoundMessage entities={props.entities} />
        </BootstrapTable>
      </Pagination>
    )}
  </PaginationProvider>
)

const enhancer = compose(
  withHooks(
    ({
      page,
      sizePerPage,
      sizePerPageList,
      totalSize,
      selectable,
      setQueryParams,
      selectedIds,
      setSelectedIds,
      loading,
      columns,
      entities,
      defaultSorted,
    }) => {
      const className = useMemo(() => {
        return classnames(
          'table table-head-custom table-vertical-center overflow-hidden',
          tableStyle,
          { [disableSelectTableStyle]: !selectable },
        )
      }, [selectable])

      const paginationStore = useMemo(() => {
        return paginationFactory({
          custom: true,
          page,
          sizePerPage,
          sizePerPageList,
          totalSize,
        })
      }, [page, sizePerPage, sizePerPageList, totalSize])

      return {
        paginationStore,
        loading,
        className,
        entities: entities || [],
        columns,
        defaultSorted,
        setQueryParams,
        selectedIds,
        setSelectedIds,
      }
    },
  ),
)

export default enhancer(Table)
