import React, { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  Grid,
  Field,
  PageLayout,
  Input,
  Notification,
  DatePicker,
  Select,
  Typography,
  FormHelperText,
} from '@components'
import { makeStyles } from '@material-ui/core/styles'
import { compose, withFormik, withHooks, withStores } from '@enhancers'
import paths from '@common/paths'
// import * as yup from 'yup'
import { get, isNull, times } from 'lodash'
import { addDays } from 'date-fns'
import { css } from '@styled'

const secondaryTitleText = css`
  padding: 20px 0 10px 0;
`

const useStyles = makeStyles((theme) => ({
  cyclePanel: {
    paddingTop: 10,
  },
}))
export const SeasonEditPage = (props) => (
  <PageLayout
    title={props.title}
    actions={[
      {
        label: 'กลับ',
        icon: 'arrow-left',
        onClick: props.goBack,
      },
      {
        label: 'ล้างค่าที่กรอก',
        icon: 'redo',
        onClick: props.reset,
      },
      {
        label: 'บันทึก',
        icon: 'save',
        color: 'primary',
        onClick: props.save,
        type: 'submit',
      },
    ]}>
    <Grid container spacing={3}>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="ชื่อฤดูกาลแข่ง"
          name="title"
          component={Input}
          placeholder="ชื่อฤดูกาลแข่ง"
          required
          helperText="โปรดใส่ชื่อฤดูกาลแข่งเฉพาะ"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="จำนวนรอบการแข่ง"
          name="cycleAmountLimit"
          component={Input}
          placeholder="จำนวนรอบการแข่ง"
          required
          type="number"
          helperText="โปรดระบุจำนวนรอบการแข่ง"
          inputProps={{ min: 0 }}
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="วันเริ่มต้น"
          name="startedAt"
          component={DatePicker}
          placeholder="วันเริ่มต้น"
          required
          helperText="โปรดระบุวันเริ่มต้น"
        />
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        <Field
          label="วันสิ้นสุด"
          name="endedAt"
          component={DatePicker}
          placeholder="วันสิ้นสุด"
          disabled
          helperText="โปรดระบุวันสิ้นสุด"
        />
      </Grid>
    </Grid>
    <Grid container className={secondaryTitleText}>
      <Typography>ข้อมูลผู้เข้าร่วม</Typography>
      <FormHelperText style={{ marginLeft: 5 }}>
        (หากต้องการให้โครงการนี้สามารถเปิดใช้ได้ทั้งองกร
        ไม่ต้องระบุจำนวนผู้แข่งขันพนักงาน ทีม หรือแผนก /
        หากต้องระบุผู้เข้าแข่งขัน ให้ระบุ จำนวนผู้แข่งขัน พนักงาน ทีม หรือแผนก)
      </FormHelperText>
    </Grid>
    <Grid container spacing={3}>
      <Grid item md={3} sm={6} xs={12}>
        <Field
          label="จำนวนผู้เข้าขัน"
          name="attendeeAmountLimit"
          component={Input}
          placeholder="จำนวนผู้เข้าขัน"
          type="number"
          helperText="เว้นว่างไว้หากต้องการให้เข้าได้ทั้งหมด"
          inputProps={{ min: 0 }}
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Field
          label="พนักงานที่ให้ร่วมฤดูกาลแข่ง"
          name="allowedEmployeeCodes"
          component={Select}
          options={props.codeOptions || []}
          multiple
          placeholder="พนักงานที่ให้ร่วมฤดูกาลแข่ง"
          freeSolo
          helperText="เว้นว่างไว้หากต้องการให้เข้าได้ทั้งหมด"
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Field
          label="แผนกที่ให้ร่วมฤดูกาลแข่ง"
          name="allowedEmployeeDepartments"
          component={Select}
          options={props.departmentOptions || []}
          multiple
          placeholder="แผนกที่ให้ร่วมฤดูกาลแข่ง"
          freeSolo
          helperText="เว้นว่างไว้หากต้องการให้เข้าได้ทั้งหมด"
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Field
          label="ทีมที่ให้ร่วมฤดูกาลแข่ง"
          name="allowedEmployeeTeams"
          component={Select}
          options={props.teamOptions || []}
          multiple
          placeholder="ทีมที่ให้ร่วมฤดูกาลแข่ง"
          freeSolo
          helperText="เว้นว่างไว้หากต้องการให้เข้าได้ทั้งหมด"
        />
      </Grid>
    </Grid>
    {times(props.cycleAmountLimit, (index) => {
      const fieldName = `cycles[${index}]`
      return (
        <div key={`add-${fieldName}`}>
          <Grid container className={props.classes.cyclePanel}>
            <Grid item>รอบการแข่งที่ {index + 1}</Grid>
          </Grid>
          <Grid container spacing={3} className={props.classes.cyclePanel}>
            <Grid item lg={4} md={5} sm={6} xs={12}>
              <Field
                label="ภารกิจบังคับ"
                name={`${fieldName}.forceMissionId`}
                component={Select}
                options={props.missionOptions || []}
                placeholder="ภารกิจบังคับ"
              />
            </Grid>
            <Grid item lg={4} md={5} sm={6} xs={12}>
              <Field
                label="จำนวนวัน"
                name={`${fieldName}.dayAmount`}
                component={Input}
                placeholder="จำนวนวัน"
                type="number"
                inputProps={{ min: 0 }}
              />
            </Grid>
          </Grid>
        </div>
      )
    })}
  </PageLayout>
)

const DEFAULT_DAY_AMOUNT = 28

const enhancer = compose(
  withStores((stores) => ({
    updateSeason: stores.seasonStore.updateSeason,
    findSeason: stores.seasonStore.findSeason,
    currentSeason: stores.seasonStore.currentSeason,
    configs: stores.seasonStore.configs,
    fetchConfigs: stores.seasonStore.fetchConfigs,
  })),
  withFormik({
    mapPropsToValues: (props) => props.currentSeason || {},
    handleSubmit: async (values, formikBag) => {
      const { id, ...params } = values
      await formikBag.props.updateSeason(id, params)
      Notification.success()
      paths.currentCompanyPath().seasonsPath().push()
    },
  }),
  withHooks((props) => {
    const {
      handleReset,
      handleSubmit,
      findSeason,
      fetchConfigs,
      setFieldValue,
      configs,
      currentSeason,
      values,
    } = props
    const { seasonId } = useParams()
    const classes = useStyles()

    useEffect(() => {
      findSeason(seasonId)
      fetchConfigs()
    }, [findSeason, seasonId, fetchConfigs])

    const goBack = useCallback(() => {
      paths.currentCompanyPath().seasonsPath().push()
    }, [])
    const missionOptions = get(configs, 'attributes.mission.options', [
      { label: '', value: 0 },
    ])
    const cycles = get(currentSeason, 'cycles', [])

    const sumDayAmount = useMemo(() => {
      let dayAmount = 0
      if (values.cycles) {
        values.cycles.forEach((cycle, index) => {
          if (index < values.cycleAmountLimit) {
            dayAmount += isNull(cycle.dayAmount)
              ? DEFAULT_DAY_AMOUNT
              : cycle.dayAmount
          }
        })
      }
      return dayAmount
    }, [values.cycles, values.cycleAmountLimit])

    useEffect(() => {
      if (values.startedAt && values.cycleAmountLimit) {
        const date = values.startedAt
        const endedAt = addDays(date, sumDayAmount - 1)
        setFieldValue('endedAt', endedAt)
      } else {
        setFieldValue('endedAt', null)
      }
    }, [values.startedAt, setFieldValue, values.cycleAmountLimit, sumDayAmount])

    useEffect(() => {
      if (values.cycleAmountLimit) {
        const tempCycles = []
        times(values.cycleAmountLimit, (index) => {
          const cycle = cycles[index] || {
            forceMissionId: null,
            dayAmount: DEFAULT_DAY_AMOUNT,
            round: index + 1,
          }
          tempCycles.push(cycle)
        })
        setFieldValue('cycles', tempCycles)
      }
    }, [values.cycleAmountLimit, setFieldValue, cycles])
    const codeOptions = get(
      configs,
      'attributes.code.options',
      [],
    ).map(({ label, value }) => ({ label: `${label} (${value})`, value }))
    const departmentOptions = get(configs, 'attributes.department.options', [
      { label: '', value: 0 },
    ])
    const teamOptions = get(configs, 'attributes.team.options', [
      { label: '', value: 0 },
    ])
    const seasonName = currentSeason ? currentSeason.title : ''
    const title = useMemo(() => {
      return `แก้ไขฤดูกาลแข่ง '${seasonName}'`
    }, [seasonName])

    return {
      goBack,
      reset: handleReset,
      save: handleSubmit,
      title,
      missionOptions,
      cycleAmountLimit: values.cycleAmountLimit,
      classes,
      codeOptions,
      teamOptions,
      departmentOptions,
    }
  }),
)

export default enhancer(SeasonEditPage)
