import React, { useCallback, useEffect, useState } from 'react'
import {
  Grid,
  Field,
  Input,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpandMoreIcon,
  Select,
  Button,
  Modal,
  Notification,
} from '@components'
import { compose, withHooks, withStores, withFormik } from '@enhancers'
import styled, { css } from '@styled'
import AddDateModal from './AddDateModal'
import { useParams } from 'react-router-dom'
import humps from 'humps'
import PreventForgotSaveModal from './PreventForgotSaveModal'
import HEALTH_INFO_CONFIG from './health_info_config'

const layout = css`
  background-color: #f8f7f7;
  padding: 24px;
  border-radius: 5px;
  border: 1px solid #bab5c5;
`
const HistoryHeader = styled.div`
  display: flex;

  .left-panel {
    display: flex;
    width: 100%;
    align-items: center;

    div {
      background-color: #ffffff;
    }
  }

  .right-panel {
    display: ${(props) => (props.hiddenButton ? 'none' : 'flex')};
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .add-button {
      margin-left: 12px;

      @media screen and (max-width: 600px) {
        margin-left: 0;
      }
    }

    div {
      align-items: center;
    }
  }
`
const DateSelector = styled(Select)`
  .MuiAutocomplete-endAdornment {
    button:first-of-type {
      display: none;
    }
  }
`
const historyLayout = css`
  .MuiExpansionPanelSummary-content {
    border-bottom: 1px solid #bab5c5;
    margin: 0;
  }

  .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiPaper-root {
    background-color: #f8f7f7;
  }

  .MuiPaper-elevation1 {
    box-shadow: unset !important;
  }

  .MuiExpansionPanel-root {
    .Mui-expanded {
      margin-top: 0;
      min-height: unset;
    }
    :before {
      display: none;
    }
  }

  .MuiButtonBase-root {
    padding: 0;
    margin-bottom: 10px;
  }

  .MuiExpansionPanelSummary-root {
    min-height: unset;

    .Mui-expanded {
      margin-top: 0px;
      min-height: unset;
    }
  }

  .MuiExpansionPanelDetails-root {
    padding: 12px 0 20px 0;
  }

  .MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 8px 12px;
  }

  .MuiCollapse-container.MuiCollapse-entered {
    margin-top: 10px;
  }

  .MuiExpansionPanel-root.Mui-expanded {
    margin: 0;
  }

  .MuiIconButton-edgeEnd {
    position: absolute;
    right: 8px;
    top: 0;
  }
`

const DeleteButton = styled(Button)`
  padding: 8px 16px;
  background-color: #ffffff !important;
  border: 1px solid #f36255 !important;
  color: #f36255 !important;

  i {
    padding-right: 6px !important;
    color: #f36255 !important;
    font-size: 12px !important;
  }
`

const SaveButton = styled(Button)`
  padding: 8px 16px;
  margin-left: 8px;
`

const History = (props) => (
  <>
    <div style={{ marginTop: 29, marginBottom: 21 }}>ข้อมูลสุขภาพ</div>
    <div className={layout}>
      <HistoryHeader hiddenButton={props.disabled}>
        <Grid container>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div className="left-panel">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <DateSelector
                  label="วันที่เก็บข้อมูล"
                  placeholder="วันที่เก็บข้อมูล"
                  options={props.dateHistoriesOptions}
                  value={props.currentDate}
                  onChange={props.handleCurrentDateChange}
                />
              </Grid>
            </div>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div className="right-panel">
              <Grid
                container
                item
                justify="space-between"
                lg={12}
                md={12}
                sm={12}
                xs={12}>
                <Button
                  className="add-button"
                  size="small"
                  color="primary"
                  onClick={props.onAdd}
                  style={{ width: 32, height: 32 }}>
                  <i style={{ padding: 9 }} className="fas fa-plus" />
                </Button>

                <div>
                  <DeleteButton
                    size="small"
                    color="secondary"
                    onClick={props.onDelete}
                    disabled={!props.isShowHistory}>
                    <i style={{}} className="fas fa-trash" />
                    ลบ
                  </DeleteButton>

                  <SaveButton size="small" color="primary" type="submit">
                    <i className="fas fa-save" />
                    บันทึก
                  </SaveButton>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </HistoryHeader>

      {props.isShowHistory && (
        <div className={`${historyLayout} mt-8`}>
          {props.healthInfoConfig.map((config) => (
            <ExpansionPanel key={config.title} defaultExpanded className="mt-2">
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{config.title}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Grid container spacing={3}>
                    {config.items.map((item, index) => (
                      <Grid
                        key={item.name}
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}>
                        <Field
                          label={item.label}
                          name={humps.camelize(item.name)}
                          component={Input}
                          placeholder={item.placeholder}
                          disabled={props.disabled}
                          unit={item.unit}
                          className="bg-white"
                          type={item.type || 'number'}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </div>
      )}
    </div>
  </>
)

const enhancer = compose(
  withStores((stores) => ({
    findEmployee: stores.employeeStore.findEmployee,
    fetchEmployeeHistory: stores.employeeStore.fetchEmployeeHistory,
    currentEmployeeHistory: stores.employeeStore.currentEmployeeHistory,
    deleteEmployeeHistory: stores.employeeStore.deleteEmployeeHistory,
    updateEmployeeHistory: stores.employeeStore.updateEmployeeHistory,
    clearEmployeeHistory: stores.employeeStore.clearEmployeeHistory,
  })),
  withFormik({
    mapPropsToValues: (props) => props.currentEmployeeHistory,
    handleSubmit: async (values, formikBag) => {
      const { id, ...params } = values
      await formikBag.props.updateEmployeeHistory(id, params)
      Notification.success()
    },
  }),
  withHooks((props) => {
    const {
      dateHistoriesOptions,
      fetchEmployeeHistory,
      deleteEmployeeHistory,
      updateEmployeeHistory,
      findEmployee,
      handleSubmit,
      values,
      disabled,
      dirty,
      clearEmployeeHistory,
    } = props

    const { id } = useParams()

    const [currentDate, setCurrentDate] = useState()

    const onAdd = useCallback(() => {
      Modal.confirm({
        title: 'เพิ่มวันที่เก็บข้อมูล',
        hideFooter: true,
        children: (
          <AddDateModal
            employeeId={id}
            onSuccess={(date) => {
              setCurrentDate(date)
            }}
          />
        ),
      })
    }, [id, setCurrentDate])

    const onDelete = useCallback(async () => {
      Modal.confirm({
        title: 'ต้องการลบข้อมูลสุขภาพ',
        children: 'ยืนยันการลบ',
        onOk: async (modal) => {
          await deleteEmployeeHistory(id, { date: values.date })
          await findEmployee(id)
          setCurrentDate(null)
          Notification.success()
          modal.close()
        },
      })
    }, [deleteEmployeeHistory, findEmployee, id, values.date])

    const handleCurrentDateChange = useCallback(
      (date) => {
        if (dirty) {
          Modal.confirm({
            title: 'ยังไม่ได้บันทึกข้อมูล',
            hideFooter: true,
            disableBackdropClick: true,
            children: (
              <PreventForgotSaveModal
                onContinueWithSave={async () => {
                  await updateEmployeeHistory(id, values)
                  setCurrentDate(date)
                }}
                onContinueWithoutSave={() => {
                  setCurrentDate(date)
                }}
              />
            ),
          })
        } else {
          setCurrentDate(date)
        }
      },
      [setCurrentDate, dirty, updateEmployeeHistory, id, values],
    )

    useEffect(() => {
      if (currentDate) {
        fetchEmployeeHistory(id, { date: currentDate })
      }
    }, [fetchEmployeeHistory, id, currentDate])

    const lastDate =
      dateHistoriesOptions &&
      dateHistoriesOptions[Object.keys(dateHistoriesOptions)[0]]?.value
    useEffect(() => {
      if (!currentDate && lastDate) {
        setCurrentDate(lastDate)
      }
    }, [setCurrentDate, lastDate, currentDate])

    useEffect(() => {
      return () => {
        clearEmployeeHistory()
      }
    }, [clearEmployeeHistory])

    return {
      onAdd,
      onDelete,
      disabled,
      dateHistoriesOptions,
      handleSubmit,
      id,
      isShowHistory: !!currentDate,
      healthInfoConfig: HEALTH_INFO_CONFIG,
      currentDate,
      handleCurrentDateChange,
    }
  }),
)

export default enhancer(History)
